
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CoreServiceRegistry } from 'src/app/core/core-service-registry';
import { SharedServiceRegistry } from 'src/app/shared/shared-service-registry';
import { SearchableEntityActionService } from '../searchable-entity-action.service';
import { DetailedEntityDataService } from './detailed-entity-data.service';
import { DetailedEntityButtonService } from './detailed-entity-button.service';
import { DetailedEntityDataEditorService } from './detailed-entity-data-editor.service';
import { DetailedEntityBackendService } from './detailed-entity-backend.service';
import { Observable } from 'rxjs';
import { ValidatorService } from '../../validator.service';
@Injectable()
export class DetailedEntityActionService extends SearchableEntityActionService {

  public dataService: DetailedEntityDataService;
  public buttonService: DetailedEntityButtonService;
  public backendService: DetailedEntityBackendService;
  public dataEditor: DetailedEntityDataEditorService;
  public validatorService: ValidatorService;


  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
  
  }

  // Method used to edit the Entity detail page
  public editPage() {

    if (this.dataService.displayMode === 'V') {
      this.dataService.setDisplayMode('E');
      this.buttonService.initializeButtons(this.dataService.primaryDataMap);

    } else if (this.dataService.displayMode === 'E') {
      this.dataService.setDisplayMode('V');
      this.buttonService.initializeButtons(this.dataService.primaryDataMap);
      this.clearEditedFlags();
    }
  }
  // Method used to save an entity
  public savePage(): Observable<any> {
    let entityDetails: Array<any>;
    let lineItems: Array<any>;
    let requestLineItems: Array<any>; // stores the line items to be sent for saving
    let requestEntityItems: Array<any>; // stores the entity details for saving
    if (this.dataService.displayMode === 'C') {
      // set the entity properties
      entityDetails = [];
      const entityData = Object.keys(this.dataService.primaryDataMap);
      entityData.forEach((property: string) => {
        entityDetails.push({ cName: property, cVal: this.dataService.primaryDataMap[property].cVal });
      });
      // Validation of primary id of entity details starts
      if (entityDetails.length !== 0) {
        for (let i = 0; i < entityDetails.length; i++) {
          if ((entityDetails[i].cName === this.dataService.entityConfig.getPrimarySearchParams().idProperty) && !entityDetails[i].cVal) {
            const propertyKey = entityDetails[i].cName;
            const property = this.resourceService.get(this.dataService.primaryMetaDataMap[propertyKey].elementId);
            this.alertService.error(this.dataService.createAlertMessage(this.resourceService.get('err_msg_val_prop'), property));
            return;
          }
        }
      }
      let invalidErrorMsg: any;
      invalidErrorMsg = this.validateEntityDetails(entityDetails);
      if (invalidErrorMsg) {
        this.errorMsg = invalidErrorMsg;
        this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
        return;
      }
      // Validation of primary id of entity details ends

      entityDetails.push({ cName: 'isNewItem', cVal: true });
      // If there are line items in details page
      if (this.dataService.entityConfig.getShowDetailLineItems()) {
        // set the line item properties
        lineItems = this.dataService.getAllLineItems(this.dataService.primaryDataMap);
      }
    } else if (this.dataService.displayMode === 'E') {
      // set the entity properties
      entityDetails = this.dataService.getModifiedPrimaryProperties(this.dataService.primaryDataMap);
      // Validation of entity details starts
      if (entityDetails.length !== 0) {
        const invalidErrorMsg = this.validateEntityDetails(entityDetails);
        if (invalidErrorMsg) {
          this.errorMsg = invalidErrorMsg;
          this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
          return;
        }
      }
      // Validation of entity details ends


      // If there are line items in details page
      if (this.dataService.entityConfig.getShowDetailLineItems()) {
        // set the line item properties
        lineItems = this.dataService.getAllLineItems(this.dataService.primaryDataMap);
        // adding the warning so as to avoid saving when no modifications are done to the entity and trying to save.
        if (entityDetails.length === 0 && lineItems.length === 0) {
          this.errorMsg = 'msg_no_mod';
          this.alertService.clearAll().warn(this.resourceService.get(this.errorMsg));
          this.dataService.getDetailsSearchService().clearMarkerFlags();
          this.clearEditedFlags();
          this.navService.preventNavigation = false;
          this.dataService.setDisplayMode('V');
          this.buttonService.initializeButtons(this.dataService.primaryDataMap);
          return;
        }
      } else {
        if (entityDetails.length === 0) {
          this.errorMsg = 'msg_no_mod';
          this.alertService.clearAll().warn(this.resourceService.get(this.errorMsg));
          this.clearEditedFlags();
          this.navService.preventNavigation = false;
          this.dataService.setDisplayMode('V');
          this.buttonService.initializeButtons(this.dataService.primaryDataMap);
          return;
        }
      }
    }
    // If there are line items in details page
    if (this.dataService.entityConfig.getShowDetailLineItems()) {
      const errorIfAny = this.validateLineItems(lineItems);
      if (errorIfAny) {
        this.errorMsg = errorIfAny;
        this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
        return;
      }
    }
    requestEntityItems = this.getEntityValuesList();
    if (this.dataService.entityConfig.getShowDetailLineItems()) {
      requestLineItems = this.getLineItemsList(lineItems);
    }
    // added to validate the data entered by the user

    if (this.validateUserEnteredData()) {
      if (this.dataService.displayMode === 'C') {
      return this.backendService.saveEntityforAdd(this.dataService.getPrimaryDataIdValue(), requestEntityItems, requestLineItems).pipe(
        map((response) => {
          return response;
        }));
    } else if (this.dataService.displayMode === 'E') {
      return this.backendService.saveEntityforUpdate(this.dataService.getPrimaryDataIdValue(), requestEntityItems, requestLineItems).pipe(
        map((response) => {
          return response;
        }));
      }

  }
}

  /*this method forms the request for saving line items. If the line item is an existing one while sending it for saving,
  the request should contain the original data as well as the edited data.
  The original data will be prefixed with _original.
  If it is a new line item the original prefix need not be appended
 */
  public getLineItemsList(modifiedLineItems) {
    const requestList: Array<any> = []; // stores the formatted line items
    let selectedLineItem;
    modifiedLineItems.forEach((row: any, index: any) => {
      selectedLineItem = [];
      let isNewItem = false;
      (row.cells).forEach((cell: any) => {
        if (cell.cName === 'isNewItem' && cell.cVal) {
          isNewItem = true;
        }
      });
      // const isNewItem: boolean = (row.cells['isNewItem'] && row.cells['isNewItem'].cVal) ? true : false;
      let typeName = '';
      if (!isNewItem) {
        typeName = 'original_';
        (row.cells).forEach((cell: any) => {
          if (cell.cName !== 'isNewItem') {
            if ((cell.cValOrig !== undefined) && (cell.cValOrig !== cell.cVal)) { // for fields which are edited
              selectedLineItem.push({ cName: typeName + cell.cName, cVal: cell.cValOrig });
              selectedLineItem.push({ cName: cell.cName, cVal: cell.cVal });
            } else { // for fields which are not edited
              selectedLineItem.push({ cName: typeName + cell.cName, cVal: cell.cVal });
              selectedLineItem.push({ cName: cell.cName, cVal: cell.cVal });
            }
          } else { // for new line items
            selectedLineItem.push({ cName: cell.cName, cVal: cell.cVal });
          }
        });
      } else {
        (row.cells).forEach((cell: any) => {
          selectedLineItem.push({ cName: cell.cName, cVal: cell.cVal });
        });
      }
      requestList.push({ rIdx: index, cells: selectedLineItem });
    });
    return requestList;
  }

  /*This method forms the request for saving the entity details. While editing an existing entity,
  the original data should also be sent prefixed with _original, along with the edited data
  If it is a new entity the prefix can be avoided*/
  public getEntityValuesList() {
    let data;
    const accordionDetails = [];
    const typeName = 'original_';
    Object.keys(this.dataService.primaryDataMap).forEach((property: any) => {
      data = this.dataService.primaryDataMap[property];
      if (this.dataService.displayMode === 'E') {
        if (data.isEdited) {// for fields which are edited
          /*LFWM-1733 this.dataService.originalDataMap[property].cVal will give the original value
          of that particular field, cValPrev will give the previosly modified value and
          not the original value.The original value should also be send along with the req */
          // LFWM-1761 : Code fix
          accordionDetails.push({ cName: typeName + data.cName, cVal: data.cValPrev});
          accordionDetails.push({ cName: data.cName, cVal: data.cVal });
        } else {
          if (!(this.dataService.primaryMetaDataMap[data.cName] &&
             (this.dataService.primaryMetaDataMap[data.cName].displayType === 'H') && (data.cVal === null || data.cVal === undefined))) {
            accordionDetails.push({ cName: typeName + data.cName, cVal: data.cVal });
            accordionDetails.push({ cName: data.cName, cVal: data.cVal });
          }
        }
      } else { // for new entity
        accordionDetails.push({ cName: data.cName, cVal: data.cVal });
      }
    });
    if (this.dataService.displayMode === 'C') {
      accordionDetails.push({ cName: 'isNewItem', cVal: true });
    }
    return accordionDetails;
  }
  // Method used to delete the selected Entity
  public delete() {
    const isNewItem = this.dataService.getPrimarySearchService().getDisplayMode() === 'C'
      && this.dataService.getDetailsSearchService().getDisplayMode() === 'C';
    if (isNewItem) {
      this.alertService.clearAll().success(this.resourceService.get(this.successMsg));
      this.navService.retainAlerts().navigateByUrl(this.dataService.getEntityConfiguration().getSearchPageRoute());
    } else {
      this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            const itemNumber = this.dataService.getPrimaryDataIdValue();
            this.backendService.deleteEntity(itemNumber)
              .subscribe((resp: any) => {
                if (resp['statusCode'] && resp['statusMessage']) {
                  if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
                    // reload the search page
                    this.alertService.clearAll();
                    this.alertService.success(this.resourceService.get(this.successMsg));
                    this.navService.preventNavigation = false;
                    this.navService.removeLastBreadCrumbItem();
                    this.navService.retainAlerts().navigateByUrl(this.dataService.getEntityConfiguration().getSearchPageRoute());
                  } else if (resp['statusCode'] === 500) {
                    this.alertService.clearAll();
                    this.alertService.error(this.resourceService.translateServerResponse(resp['statusMessage']));
                  }
                } else {
                  if (resp.error) {
                    this.alertService.clearAll();
                    this.alertService.error(this.resourceService.translateServerResponse(resp.error));
                  }
                }
              }, (error) => {
                this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
              });
          }
        });
    }
  }

 

  // Method used to delete the selected Line Items
  public deleteLineItem() {
    const noOfLineItems = this.dataService.getDetailsSearchService().getPagedData().pageDefinition.recordCount;
    const selectedRows = this.dataService.getDetailsSearchService().getSelectedRows();
    if (noOfLineItems === 0) {
      this.errorMsg = 'msg_no_detail';
      this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
      return;
    }
    const lineItemProp = this.dataService.detailsSearchParams.idProperty;
    if (selectedRows.length > 0) {
      this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            const itemNumber = this.dataService.getPrimaryDataIdValue();
            const selectedRow = this.dataService.getDetailsSearchService().getSelectedRows();
            const existingLineItemNumbers: Array<any> = [];
            const newLineItemNumbers: Array<string> = [];
            selectedRow.forEach((row: any) => {
              if (row.cells['isNewItem'] && row.cells['isNewItem'].cVal === true) {
                newLineItemNumbers.push(row.cells[lineItemProp].cVal);
              } else {
                existingLineItemNumbers.push({
                  'value': row.cells[lineItemProp].cVal,
                  'index': row.rIdx
                });
              }
            });
            if (newLineItemNumbers.length > 0) {
              this.dataService.getDetailsSearchService().deleteRowsFromModel(lineItemProp, newLineItemNumbers);
              this.buttonService.initializeButtons(this.dataService.primaryDataMap);
            }
            if (existingLineItemNumbers.length > 0) {
              this.backendService.deleteSelectedLineItemFromEntity(itemNumber, existingLineItemNumbers)
                .subscribe((resp: any) => {
                  if (resp['statusCode'] !== undefined && resp['statusCode'] !== null) {
                    if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
                      // code to reload the  detail grid
                      this.alertService.clearAll();
                      this.alertService.success(this.resourceService.get(this.successMsg));
                      this.dataService.reloadDetailTable();
                    } else {
                      this.alertService.clearAll();
                      this.alertService.error(this.resourceService.translateServerResponse(resp.statusMessage));
                    }
                  } else {
                    if (resp.error) {
                      this.alertService.clearAll();
                      this.alertService.error(this.resourceService.translateServerResponse(resp.statusMessage));
                    }
                  }
                }, (error) => {
                  this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
                });
            }
          }
        });
    } else {
      this.alertService.clearAll();
      this.alertService.error(this.resourceService.get(this.errorMsg));
    }
  }

  // Method used to delete all the Line Items
  public deleteAllLineItems() {
    const noOfLineItems = this.dataService.getDetailsSearchService().getPagedData().pageDefinition.recordCount;
    const selectedRow = this.dataService.getDetailsSearchService().getPagedData().pageRows;
    if (noOfLineItems > 0) {
      this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            const lineItemProp = this.dataService.detailsSearchParams.idProperty;
            const newLineItemNumbers: Array<string> = [];
            let hasExistingLineItems = false;
            selectedRow.forEach((row: any) => {
              if (row.cells['isNewItem'] && row.cells['isNewItem'].cVal === true) {
                newLineItemNumbers.push(row.cells[lineItemProp].cVal);
              } else {
                hasExistingLineItems = true;
              }
            });

            if (newLineItemNumbers.length > 0) {
              this.dataService.getDetailsSearchService().deleteRowsFromModel(lineItemProp, newLineItemNumbers);
              this.buttonService.initializeButtons(this.dataService.primaryDataMap);
            }

            if (hasExistingLineItems) {
              const entityNumber = this.dataService.getPrimaryDataIdValue();
              this.backendService.deleteAllLineItemsFromEntity(entityNumber)
                .subscribe((resp: any) => {
                  if (resp['statusCode'] !== undefined && resp['statusCode'] !== null) {
                    if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
                      // code to reload the detail grid
                      this.alertService.clearAll();
                      this.alertService.success(this.resourceService.get(this.successMsg));
                      this.dataService.reloadDetailTable();
                    } else {
                      this.alertService.clearAll();
                      this.alertService.error(this.resourceService.translateServerResponse(resp.statusMessage));
                    }
                  } else {
                    if (resp.error) {
                      this.alertService.clearAll();
                      this.alertService.error(this.resourceService.translateServerResponse(resp.error));
                    }
                  }
                }, (error) => {
                  this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
                });
            }
          }
        });
    } else {
      this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
    }
  }

  // method used to clear all the edited flags
  /* LFWM-960 Removing the previous skip first clearing code */
  public clearEditedFlags() {
    Object.keys(this.dataService.primaryDataMap).forEach((key: any) => {
      this.dataService.primaryDataMap[key].isEdited = false;
    });
  }

  // method used to navigate to the details page
  public showDetailsPage() {
    this.dataService.setDisplayMode('C');
    this.navService.retainAlerts().navigateByUrl(this.dataService.getEntityConfiguration().getDetailPageRoute());
  }

  // method used to validate the line items
  public validateLineItems(lineItems) {
    return null;
  }

  // method used to validate the entity details
  public validateEntityDetails(entityDetails) {
    return null;
  }
  public validateUserEnteredData() { // while over writing this method these two validations should madatorily be performed
    // to validate accordion details
    const validatePrimaryDetailsErr = this.validatorService.validateRow(this.dataService.getPrimarySearchService().
      getMetadata(), this.dataService.primaryDataMap);
    // added to validate the fields of line items if they are present
    if (this.dataService.entityConfig.getShowDetailLineItems()) {
      const validateLineItemsErr = this.validatorService.validateMultipleRows(this.dataService.getDetailsSearchService().
        getMetadata(), this.dataService.getDetailsSearchService().getPagedData());
      if (validateLineItemsErr || validatePrimaryDetailsErr) {
        return false;
      } else {
        return true;
      }
    } else { // for screens without line items
      if (validatePrimaryDetailsErr) {
        return false;
      } else {
        return true;
      }
    }
  }

  // [LFWM-2125] - to get dynamic buttons based on module name and storerKey
  public getDynamicButtons(): Observable<any> {
    const storerKey = this.dataService.primaryDataMap['storerKey'].cVal;
    const moduleName = this.dataService.tableIdToModuleNameMapping[this.dataService.primarySearchParams.tableId];
    return this.backendService.getDynamicButtons(storerKey, moduleName).pipe(map((resp: any) => {
      return resp;
  }));
}

  // [LFWM-2125] - method to handle dynamic button actions
  public handlingDynamicButtonAction(action: string) {
    const currentButtonInfo = this.buttonService.dynamicButtons.filter(button =>
                                button.staticButtonName.toUpperCase() === action.toUpperCase());
    const confirmationMsg = currentButtonInfo[0]['confirmationMsg'] ? currentButtonInfo[0]['confirmationMsg'] :
      this.resourceService.get('genric_confirm_msg');
    this.confirmDialogService.confirmDialog(confirmationMsg)
      .subscribe((isConfirmed: any) => {
        if (isConfirmed) {
        const storerKey = this.dataService.primaryDataMap['storerKey'].cVal;
        const documentKey = this.dataService.getPrimaryDataIdValue();
        const moduleName = this.dataService.tableIdToModuleNameMapping[this.dataService.primarySearchParams.tableId];
        const actionCode = currentButtonInfo[0]['code'];
        this.backendService.handlingDynamicButtonAction(storerKey, documentKey, moduleName, actionCode).subscribe((resp: any) => {
          if (resp.statusCode === 200 && resp.statusMessage === 'SUCCESS') {
            this.alertService.clearAll();
            this.alertService.success(this.dataService.createAlertMessage(this.resourceService.get('msg_activity_suc'), currentButtonInfo[0]['buttonName']));
          } else {
            if (resp.errMsg) {
              this.alertService.clearAll().error(this.resourceService.translateServerResponse(resp.errMsg));
            }
          }
        }, (error) => {
          this.alertService.clearAll().error(this.resourceService.get(error));
        });
      }
      });
  }
}
