import { DetailedEntityActionService } from '../../shared/services/searchable-entity/detailed-entity/detailed-entity-action.service';
import { CoreServiceRegistry } from 'src/app/core/core-service-registry';
import { SharedServiceRegistry } from 'src/app/shared/shared-service-registry';
import { Injectable } from '@angular/core';
import { FleetMonitoringBackendService } from './fleet-monitoring-backend.service';
import { SearchParams } from '../../shared/search/models/search-params.model';
import { SearchCriteria, Clause, Condition } from '../../shared/search/models/search-filter-model';
//import { PopulatePopupComponent } from '../../../../shared/lookup/populate-item-detail/populate-popup.component';
import { Observable } from 'rxjs';
import { SearchEvent } from '../../shared/search/models/search-event';
import { DELETE_ROW_EVENT, REFRESH_SEARCH_EVENT } from '../../shared/search/search-constants';
import { FleetMonitoringDataService } from './fleet-monitoring-data.service';
import { FleetMonitoringDataEditorService } from './fleet-monitoring-data-editor.service';
import { map } from 'rxjs/operators';
import { FleetMonitoringSearchService } from './fleet-monitoring-search.service';
import { LocalPagedSearchService } from 'src/app/shared/search/localpaged-search.service';


@Injectable()
export class FleetMonitoringActionService extends DetailedEntityActionService {


  public viewBalTitle: string;
  public backendService: FleetMonitoringBackendService;
  public dataService: FleetMonitoringDataService;
  public dataEditor: FleetMonitoringDataEditorService;
  selectedData: any[];


  constructor(public coreServiceRegistry: CoreServiceRegistry, public searchService: FleetMonitoringSearchService,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
  }

  // Method used to edit the Entity detail page
  public editPage() {

    // if (this.dataService.displayMode === 'V') {
    //   // this.dataService.setDisplayMode('E');
    //   // this.buttonService.initializeButtons(this.dataService.primaryDataMap);
    //   // this.dataEditor.populateDrpdwnOnFromStorer();
    // }

    if (this.dataService.displayMode === 'V') {
      this.dataService.setDisplayMode('E');
      this.buttonService.initializeSearchPageButtons(this.dataService.primaryDataMap);
    }
  }

  // Method used to edit the Entity detail page
  public editPageDtl() {

    // if (this.dataService.displayMode === 'V') {
    //   // this.dataService.setDisplayMode('E');
    //   // this.buttonService.initializeButtons(this.dataService.primaryDataMap);
    //   // this.dataEditor.populateDrpdwnOnFromStorer();
    // }

    if (this.dataService.displayMode === 'V') {
      this.dataService.setDisplayMode('E');
      this.buttonService.initializeDetailPageButtons(this.dataService.primaryDataMap);
    }
  }


  // Returns modified line items from the ASN details table
  public getModifiedLineItems(): any[] {
    let modifiedRows = [];
    if (this.searchService.searchParams.paginateLocally) {
      modifiedRows = this.searchService.getPagedData().pageRows.filter((row: any) => {
        return row.isNewRow;
      });
    } else {
      modifiedRows = this.searchService.getPagedData().pageRows.filter((row: any) => {
        return row.isEdited;
      });
    }
    return modifiedRows;
  }

  public getAllLineItems() {
    let allRows = [];
    if (this.searchService.searchParams.paginateLocally) {
      allRows = this.searchService.fullData;
    } else {
      allRows = this.searchService.getPagedData().pageRows;
    }
    if (allRows.length === 0) {
      return allRows;
    }
    const allLineItems = [];
    allRows.forEach((lineItemRow: any, index: number) => {
      const lineItem = [];
      const lineItemCells = lineItemRow.cells;
      const lineItemProperties = Object.keys(lineItemCells);
      lineItemProperties.forEach((lineItemProperty: any) => {
        lineItem.push({ cName: lineItemProperty, cVal: lineItemCells[lineItemProperty].cVal, cValOrig: lineItemCells[lineItemProperty].cValOrig });
      });
      allLineItems.push({ rIdx: index, cells: lineItem });
    });
    return allLineItems;
  }

  public cancelDtl() {
    if (this.dataService.displayMode !== 'E') {
      // this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
      // .subscribe((isConfirmed: any) => { 
      // if(isConfirmed){
        //this.navService.resetBreadcrumb('home');
        this.navService.navigateByMenuId('notification-rg');
        //this.navService.navigateByUrl('/home/notification/notification-recipient/search');
      //  }
      // });
    } else {
      this.dataService.setDisplayMode('V');
      this.buttonService.initializeDetailPageButtons(this.dataService.primarySearchService.metadata);
    }

  }


  // Method to delete
  public delete() {
    // if locally paginated we need to use client side local paged search service
    if (this.searchService.searchParams.paginateLocally) {
      const rowIndexArray: any[] = [];
      this.searchService.getPagedData().pageRows.forEach((row: any, index: any) => {
        if (row.isSelected === true) {
          rowIndexArray.push(index);
        }
      });
      // check wether the user selects atleast one record
      if (rowIndexArray.length > 0) {
        //const searchService = <LocalPagedSearchService>this.dataService.searchService;
        this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
          .subscribe((isConfirmed: any) => {
            try {
              for (let index = rowIndexArray.length - 1; index >= 0; index--) {
                this.searchService.deleteRowsFromModel(rowIndexArray[index], '');
              }
              // this.searchService.performSearch();
              const evt = new SearchEvent(DELETE_ROW_EVENT);
              this.searchService.publishEvent(evt);
              this.alertService.clearAll().success(this.resourceService.get(this.successMsg));
            } catch (e) {
              this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
            }
          });
      } else {
        // const select_one_row = 'Please Select Atleast One Row To Delete';
        this.alertService.clearAll().error(this.resourceService.get('select_one_row'));
      }
    } else {
      const selectedLineItems = [];
      this.selectedData = this.searchService.getPagedData().pageRows.filter((row: any) => {
        return row.isSelected;
      });
      const selectedLineItemSearch = [];
      let selectedLineItemDetail = '';
      let selectedUserIdLineItemDetail = [];
      let request = {}
      if (this.dataService.displayMode === 'E' && (window.location.href.includes('notification-recipient/details'))) {
        this.selectedData.forEach((row: any, index: any) => {
          Object.keys(row.cells).forEach((property: any) => {
            const cell = row.cells[property];
            if (property === 'USER_ID') {
              selectedUserIdLineItemDetail.push(cell.cVal);
            }
            if (property === 'GROUP_ID') {
              selectedLineItemDetail = cell.cVal;
            }
          });
        });
        this.dataService.primarySearchService.setSearchCriteria({
          conditions: [{
            mandatory: true,
            clauses: [{
              column: this.dataService.primarySearchService.searchParams.idProperty,
              operation: '=',
              value: this.dataService.getPrimarySelectedDocument(),
              logicalOperation: '',
              typeOfField: "TEXTFIELD"
            }],
            logicalOperation: 'AND'
          }]
        });
        request =
        {
          "userIds": selectedUserIdLineItemDetail,
          "groupId": selectedLineItemDetail,
          "url": 'api/recipient/recipients'
        };
      } else {
        this.selectedData.forEach((row: any, index: any) => {
          Object.keys(row.cells).forEach((property: any) => {
            const cell = row.cells[property];
            if (property === 'GROUP_ID') {
              selectedLineItemSearch.push(cell.cVal);
            }
          });

        });
        request = {
          "groupIds": selectedLineItemSearch,
          "url": 'api/recipient/recipgroup'
        };
      }

      if (selectedLineItemSearch.length === 0 && selectedLineItemDetail.length === 0) {
        this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
        return;
      }
      this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            this.backendService.delete(request).subscribe((resp: any) => {
              if (resp['statusCode'] && resp['statusMessage']) {
                if (resp['statusCode'] === 200) {
                 // let newAddedRows = (JSON.parse(JSON.stringify(this.searchService.getPagedData().pageRows)));
                  this.searchService.performSearch();
                  // this.alertService.success(resp['statusMessage']);
                  // console.debug(newAddedRows, 'new');
                  // newAddedRows.forEach((row: any) => {
                  //   if (row.cells['isNewItem'] && row.cells['isNewItem'].cVal === true) {
                  //     this.searchService.getPagedData().pageRows.push(row);
                  //   }          
                  // });
                  // console.debug(this.searchService.getPagedData().pageRows, 'pagerows');

                  this.alertService.success(this.resourceService.get(this.successMsg));
                }
              } else {
                if (resp.error) {
                  this.alertService.clearAll();
                  this.alertService.error(this.resourceService.get(resp.error));
                }
              }
            }, (error: any) => {
              this.alertService.clearAll().error(this.resourceService.get(error));
            });
          }
        });
    }
  }


  // Method to save area page
  public savePageForTable(): Observable<any> | undefined {
    let lineItems: Array<any>;
    if (this.searchService.searchParams.paginateLocally) {
      lineItems = this.dataService.searchService.fullData;
    } else if (this.dataService.displayMode === 'E') {     // set the line item properties
      lineItems = this.getModifiedLineItems();
    } else {
      lineItems = this.getAllLineItems();
    }

    let requestItems: Array<any>;
    // adding the warning so as to avoid saving when no modifications are done to the entity and trying to save.
    if (lineItems.length === 0) {
      this.errorMsg = 'msg_no_mod';
      this.alertService.clearAll().warn(this.resourceService.get(this.errorMsg));
      this.dataService.searchService.clearMarkerFlags();
      // this.clearEditedFlags();
      this.navService.preventNavigation = false;
      this.dataService.setDisplayMode('V');
      this.buttonService.initializeSearchPageButtons(this.dataService.primaryDataMap);
      return;
    }
    // format the modified line items in the required request format
    requestItems = this.getCellValuesList(lineItems);
    // added to validate mandatory fields
    const validationErrorMsg = this.validateLineItemsForNotification(requestItems);
    if (validationErrorMsg) {
      this.errorMsg = validationErrorMsg;
      this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
      return;
    }
    if (this.validateUserEnteredData()) {
      // {
      //   "recipiGrpList":
      //   [
      //     {
      //       "groupId": 3,
      //       "description": "For Billing Singapore from SCE"
      //     },
      //     {
      //       "groupId": 4,
      //       "description": "For Billing Malaysia from SCE"
      //     }
      //   ]

      // }
      
      const requestObj = { "recipiGrpList" : []};
      requestItems.forEach(items => {
        const requestSave = {};
        items['moduleProperties'].forEach(element => {
          if (element.cName.includes('GROUP_ID')) {
            requestSave['groupId'] = element.cVal;
          }
          if (element.cName === 'DESCRIPTION') {
            requestSave['description'] = element.cVal;
          }
        });
        requestObj.recipiGrpList.push(requestSave);
      });



      return this.backendService.saveEntity(requestObj).pipe(
        map((response) => {
          return response;
        }));
    }
  }


  public getCellValuesList(modifiedLineItems) {
    // const primaryIdProperty = this.dataService.searchParams.idProperty;
    const requestList: Array<any> = [];
    let selectedLineItem;
    if (this.searchService.searchParams.paginateLocally) {
      modifiedLineItems.forEach((row: any, index: any) => {
        selectedLineItem = [];
        Object.keys(row.cells).forEach((property: any) => {
          const cell = row.cells[property];
          selectedLineItem.push({ cName: property, cVal: cell.cVal });
        });
        selectedLineItem.push({
          'cName': 'isNewItem',
          'cVal': true
        });
        const request = {
          'moduleProperties': selectedLineItem,
          'screenType': this.dataService.searchParams.tableId
        };
        requestList.push(request);
      });
    } else {
      modifiedLineItems.forEach((row: any, index: any) => {
        selectedLineItem = [];
        const isNewItem: boolean = (row.cells['isNewItem'] && row.cells['isNewItem'].cVal) ? true : false;
        let typeName = '';
        if (!isNewItem) {
          typeName = 'original_';
        }
        Object.keys(row.cells).forEach((property: any) => {
          const cell = row.cells[property];
          if ((cell.cValOrig !== undefined) && (cell.cValOrig !== cell.cVal)) {
            selectedLineItem.push({ cName: typeName + property, cVal: cell.cValOrig });
            selectedLineItem.push({ cName: property, cVal: cell.cVal });
          } else {
            selectedLineItem.push({ cName: typeName + property, cVal: cell.cVal });
          }
        });
        const request = {
          'moduleProperties': selectedLineItem,
          'screenType': 'searchtd'
        };
        requestList.push(request);
      });
    }

    return requestList;
  }

  // method to validate entity details starts
  public validateEntityDetails(entityDetails) {
    if (entityDetails.length !== 0) {
      let fromFacilityFlag = false;
      let toFacilityFlag = false;
      let fromStorerFlag = false;
      let toStorerFlag = false;
      entityDetails.forEach((detail: any, index: any) => {
        if (detail.cName === 'fromFacility' && (!detail.cVal)) {
          fromFacilityFlag = true;
        } else if (detail.cName === 'toFacility' && (!detail.cVal)) {
          toFacilityFlag = true;
        } else if (detail.cName === 'fromStorerKey' && (!detail.cVal)) {
          fromStorerFlag = true;
        } else if (detail.cName === 'toStorer' && (!detail.cVal)) {
          toStorerFlag = true;
        }
      });
      if (fromFacilityFlag) {
        return this.resourceService.get('err_msg_val_fromFac');
      } else if (toFacilityFlag) {
        return this.resourceService.get('err_msg_val_toFac');
      } else if (fromStorerFlag) {
        return this.resourceService.get('err_msg_val_fromStr');
      } else if (toStorerFlag) {
        return this.resourceService.get('err_msg_val_toStr');
      }
    }
    return null;
  }

  // method used to validate the line items
  public validateLineItemsForNotification(lineItems) {
    return null;
  }

  // method used to validate the line items
  public validateLineItems(lineItems) {
    let fromPackFlag = false;
    let toPackFlag = false;
    let fromLocFlag = false;
    let toLocFlag = false;
    let fromQtyFlag = false;
    let toQtyFlag = false;
    /* removing lottable validation for LFWM-1932
     let tolottable02Flag = false;
     let tolottable03Flag = false;
     let tolottable04Flag = false;
     let tolottable05Flag = false;*/
    if (lineItems.length !== 0) {
      lineItems.forEach((detail: any, index: any) => {
        detail.cells.forEach(property => {
          if (property.cName === 'fromPack' && (!property.cVal)) {
            fromPackFlag = true;
          } else if (property.cName === 'toPack' && (!property.cVal)) {
            toPackFlag = true;
          } else if (property.cName === 'fromLoc') {
            if (!property.cVal) {
              fromLocFlag = true;
            }
          } else if (property.cName === 'toLoc') {
            if (!property.cVal) {
              toLocFlag = true;
            }
          } else if (property.cName === 'fromQty' && (!property.cVal)) {
            fromQtyFlag = true;
          } else if (property.cName === 'toQty' && (!property.cVal)) {
            toQtyFlag = true;
          }
          /* removing lottable validation for LFWM-1932
           else if (property.cName === 'tolottable02' && (!property.cVal)) {
            tolottable02Flag = true;
          } else if (property.cName === 'tolottable03' && (!property.cVal)) {
            tolottable03Flag = true;
          } else if (property.cName === 'tolottable04' && (!property.cVal)) {
            tolottable04Flag = true;
          } else if (property.cName === 'tolottable05' && (!property.cVal)) {
            tolottable05Flag = true;
          } */
        });
      });
    }
    if (fromPackFlag) {
      return this.resourceService.get('err_msg_val_fromPac');
    } else if (toPackFlag) {
      return this.resourceService.get('err_msg_val_toPac');
    } else if (fromLocFlag) {
      return this.resourceService.get('err_msg_val_fromLoc');
    } else if (toLocFlag) {
      return this.resourceService.get('err_msg_val_toLoc');
    } else if (fromQtyFlag) {
      return this.resourceService.get('err_msg_val_fromQty');
    } else if (toQtyFlag) {
      return this.resourceService.get('err_msg_val_toQty');
    }/* removing lottable validation for LFWM-1932
     else if (tolottable02Flag) {
      return this.resourceService.get('err_msg_val_toLot2');
    } else if (tolottable03Flag) {
      return this.resourceService.get('err_msg_val_toLot3');
    } else if (tolottable04Flag) {
      return this.resourceService.get('err_msg_val_toLot4');
    } else if (tolottable05Flag) {
      return this.resourceService.get('err_msg_val_toLot5');
    } */

    return null;
  }
  // used to validate loction based on the facility value
  public validateLocation(property, facility) {
    const req = {
      loc: property.cVal,
      facility: facility
    };
    this.backendService.validateLocation(req).subscribe((resp: any) => {
      if (resp['statusCode'] && resp['statusMessage']) {
        if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
          return true;
        } else if (resp['statusCode'] === 400) {
          return this.dataService.createAlertMessage(resp['statusMessage'], property.cVal);
        }
      } else {
        if (resp.error) {
          this.alertService.clearAll();
          this.alertService.error(this.resourceService.translateServerResponse(resp.error));
        }
      }
    }, (error) => {
      this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
    });
  }

  // used to finalize a transfer ticket
  public finalizeTransfer() {
    this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
      .subscribe((isConfirmed: any) => {
        if (isConfirmed) {
          const transferKey = this.dataService.primaryDataMap['transferKey'].cVal;
          const proceedWithWarning = 'N';
          this.backendService.finalizeTransfer(transferKey, proceedWithWarning)
            .subscribe((response: any) => {
              this.dataService.primaryDataMap['status'].cVal = '9';
              this.dataService.getDetailsSearchService().clearMarkerFlags();
              this.clearEditedFlags();
              this.navService.preventNavigation = false;
              this.dataService.setDisplayMode('V');
              this.buttonService.initializeButtons(this.dataService.primaryDataMap);
              this.alertService.clearAll().success(this.resourceService.get(this.successMsg));
              return response;
            }, (error) => {
              this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
            });
        }
      });
  }

  // method used to allocate a transfer
  public allocateTransfer() {
    if (this.dataService.primaryDataMap['status'].cVal === '9') {
      this.errorMsg = 'err_msg_allocate';
      this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
      return;
    } else {
      this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            const proceedWithWarning = 'N';
            const transferKey = this.dataService.getPrimarySelectedDocument();
            this.backendService.allocate(transferKey, proceedWithWarning)
              .subscribe((resp: any) => {
                if (resp['statusCode'] && resp['statusMessage']) {
                  if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
                    this.alertService.clearAll();
                    this.alertService.success(this.resourceService.get(this.successMsg));
                    this.dataService.reloadDetailTable();
                    this.dataService.getDetailsSearchService().clearMarkerFlags();
                    this.clearEditedFlags();
                    this.navService.preventNavigation = false;
                    this.dataService.setDisplayMode('V');
                    this.buttonService.initializeButtons(this.dataService.primaryDataMap);
                  } else {
                    this.alertService.clearAll();
                    this.alertService.error(this.resourceService.translateServerResponse(resp['statusMessage']));
                  }
                } else {
                  if (resp.error) {
                    this.alertService.clearAll();
                    this.alertService.error(this.resourceService.translateServerResponse(resp.error));
                  }
                }
              }, (error) => {
                this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
              });
          }
        });
    }
  }
  // method used to cancel a transfer ticket
  public cancelTransfer() {
    const transferId = this.dataService.getPrimarySelectedDocument();
    this.backendService.cancelTransfer(transferId).subscribe((resp: any) => {
      if (resp['statusCode'] && resp['statusMessage']) {
        if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
          this.alertService.clearAll();
          this.alertService.success(this.resourceService.get(this.successMsg));
          this.dataService.primaryDataMap['status'].cVal = 'CANC';
          this.dataService.getDetailsSearchService().clearMarkerFlags();
          this.clearEditedFlags();
          this.navService.preventNavigation = false;
          this.dataService.setDisplayMode('V');
          this.buttonService.initializeButtons(this.dataService.primaryDataMap);
          this.alertService.clearAll().success(this.resourceService.get(this.successMsg));
          return resp;
        } else if (resp['statusCode'] === 400) {
          this.alertService.clearAll();
          this.alertService.error(this.resourceService.translateServerResponse(resp['statusMessage']));
        }
      } else {
        if (resp.error) {
          this.alertService.clearAll();
          this.alertService.error(this.resourceService.translateServerResponse(resp.error));
        }
      }
    }, (error) => {
      this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
    });
  }

  // method used to populate transfer
  public populateTransfer(type: any) {

  }

  public populateTransferByUcc() {
    // first we need to populate pack
  }

  public populateCandidateStockOnHand() {
    this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
      .subscribe((isConfirmed: any) => {
        if (isConfirmed) {
          const transferKey = this.dataService.primaryDataMap['transferKey'].cVal;
          const proceedWithWarning = 'N';
          this.backendService.populateCandidateStockOnHand(transferKey, proceedWithWarning).subscribe((resp: any) => {
            if (resp['statusCode'] && resp['statusMessage']) {
              if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
                this.alertService.clearAll();
                this.alertService.success(this.resourceService.get(this.successMsg));
                this.dataService.getDetailsSearchService().clearMarkerFlags();
                this.clearEditedFlags();
                this.dataService.reloadDetailTable();
                this.navService.preventNavigation = false;
                this.dataService.setDisplayMode('V');
                this.buttonService.initializeButtons(this.dataService.primaryDataMap);
                this.alertService.clearAll().success(this.resourceService.get(this.successMsg));
                return resp;
              } else if (resp['statusCode'] === 400) {
                this.alertService.clearAll();
                this.alertService.error(this.resourceService.translateServerResponse(resp['statusMessage']));
              }
            } else {
              if (resp.error) {
                this.alertService.clearAll();
                this.alertService.error(this.resourceService.translateServerResponse(resp.error));
              }
            }
          }, (error) => {
            this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
          });
        }
      });
  }
  public createLookupParamsForColumn(tableId, idProperty) {
    const lookupParams = new SearchParams();
    lookupParams.isFullFetch = true;
    lookupParams.columnCount = 10;
    lookupParams.defaultSearchColumn = '';
    lookupParams.tableId = tableId;
    lookupParams.idProperty = idProperty;
    return lookupParams;
  }
  public createDefaultSearchCriteria() {
    const defaultSearchCriteria = new SearchCriteria();
    defaultSearchCriteria.conditions = [];
    defaultSearchCriteria.loadDefaultCriteria = false;

    const defaultCondition1 = this.createDefaultSearchCondition(1);
    defaultCondition1.isReadOnly = true;
    defaultCondition1.isMandatory = true;

    defaultCondition1.operator = 'AND';
    const defaultClause1 = new Clause();
    defaultClause1.column = 'storerKey';
    defaultClause1.value = this.dataService.primaryDataMap['fromStorerKey'].cVal;
    defaultClause1.operation = '=';
    defaultClause1.logicalOperation = '';
    defaultCondition1.clauses = [defaultClause1];
    defaultSearchCriteria.conditions.push(defaultCondition1);
    return defaultSearchCriteria;
  }
  public createDefaultSearchCondition(id) {
    const defaultCondition = new Condition();
    defaultCondition.id = id;
    defaultCondition.displayMode = 'VIEW';
    return defaultCondition;
  }
  // public getAllTransactionData(request): Observable<InventoryTransactionData> {
  //  //return this.backendService.getAllTransactionData(request);
  // }
  // public getAllLineTransactionData(request): Observable<InventoryTransactionData> {
  //   return this.backendService.getAllLineTransactionData(request);
  // }
  public validateUserEnteredData() { // while over writing this method these two validations should madatorily be performed
    // to validate accordion details
    const validatePrimaryDetailsErr = this.validatorService.validateRow(this.dataService.getPrimarySearchService().
      getMetadata(), this.dataService.primaryDataMap);
    // added to validate the fields of line items if they are present
    if (this.dataService.entityConfig.getShowDetailLineItems()) {
      const validateLineItemsErr = this.validatorService.validateMultipleRows(this.dataService.getDetailsSearchService().
        getMetadata(), this.dataService.getDetailsSearchService().getPagedData());
      if (validateLineItemsErr || validatePrimaryDetailsErr) {
        return false;
      } else {
        return true;
      }
    } else { // for screens without line items
      if (validatePrimaryDetailsErr) {
        return false;
      } else {
        return true;
      }
    }
    // return true;
  }
  // Method used to delete the selected Line Items
  public deleteLineItem() {
    const noOfLineItems = this.dataService.getDetailsSearchService().getPagedData().pageDefinition.recordCount;
    const selectedRows = this.dataService.getDetailsSearchService().getSelectedRows();
    if (noOfLineItems === 0) {
      this.errorMsg = 'msg_no_detail';
      this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
      return;
    }
    const lineItemProp = this.dataService.detailsSearchParams.idProperty;
    if (selectedRows.length > 0) {
      this.confirmDialogService.confirmDialog(this.resourceService.get(this.dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            const itemNumber = this.dataService.getPrimaryDataIdValue();
            const selectedRow = this.dataService.getDetailsSearchService().getSelectedRows();
            const existingLineItemNumbers: Array<any> = [];
            const newLineItemNumbers: Array<string> = [];
            selectedRow.forEach((row: any) => {
              if (row.cells['isNewItem'] && row.cells['isNewItem'].cVal === true) {
                newLineItemNumbers.push(row.cells[lineItemProp].cVal);
              } else {
                existingLineItemNumbers.push({
                  'value': row.cells['wo_line_num'].cVal, // id property is modified as wo_line_num(element id) in seach service
                  'index': row.rIdx
                });
              }
            });
            if (newLineItemNumbers.length > 0) {
              this.dataService.getDetailsSearchService().deleteRowsFromModel(lineItemProp, newLineItemNumbers);
              this.buttonService.initializeButtons(this.dataService.primaryDataMap);
            }
            if (existingLineItemNumbers.length > 0) {
              this.backendService.deleteSelectedLineItemFromEntity(itemNumber, existingLineItemNumbers)
                .subscribe((resp: any) => {
                  if (resp['statusCode'] !== undefined && resp['statusCode'] !== null) {
                    if (resp['statusCode'] === 200 && resp['statusMessage'] === 'SUCCESS') {
                      // code to reload the  detail grid
                      this.alertService.clearAll();
                      this.alertService.success(this.resourceService.get(this.successMsg));
                      this.dataService.reloadDetailTable();
                    } else {
                      this.alertService.clearAll();
                      this.alertService.error(this.resourceService.translateServerResponse(resp.statusMessage));
                    }
                  } else {
                    if (resp.error) {
                      this.alertService.clearAll();
                      this.alertService.error(this.resourceService.translateServerResponse(resp.statusMessage));
                    }
                  }
                }, (error) => {
                  this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
                });
            }
          }
        });
    } else {
      this.alertService.clearAll();
      this.alertService.error(this.resourceService.get(this.errorMsg));
    }
  }
  public showTransactions(isLineItem?: boolean) {
    let sourceKey: string = '';
    if (!isLineItem) {
      sourceKey = this.dataService.getPrimaryDataIdValue();
    } else {
      if (this.dataService.getDetailsSearchService()
        .getPagedData().pageRows.length === 0) {
        this.alertService.clearAll()
          .error(this.resourceService.get('msg_no_detail'));
        return;
      } else if (this.dataService.getDetailsSearchService()
        .getSelectedRows().length === 0) {
        this.alertService.clearAll()
          .error(this.resourceService.get('msg_sel_detail'));
        return;
      } else if (this.dataService.getDetailsSearchService()
        .getSelectedRows().length > 1) {
        this.alertService.clearAll()
          .error(this.resourceService.get('msg_multiple_entries'));
        return;
      } else {
        sourceKey = this.dataService.getPrimaryDataIdValue()
          + this.dataService.getDetailsSearchService()
            .getSelectedRows()[0].cells['wo_line_num'].cVal;
      }
    }
    const searchCriteria: any = {
      conditions: [
        {
          mandatory: false,
          clauses: [
            {
              column: 'sourceKey',
              operation: !isLineItem ? 'like' : '=',
              value: sourceKey,
              logicalOperation: null
            }],
          logicalOperation: null
        }
      ]
    };
    this.alertService.clearAll();
    // const modalRef: any = this.sharedServiceRegistry.sceModalService
    //   .showModal(ViewTransactionsPopupComponent,
    //     {
    //       overlayHeader: this.resourceService.get(isLineItem ?
    //         'btn_view_line_transaction' : 'btn_view_transaction'),
    //       showHeader: true,
    //       overlayHeight: 480,
    //       overlayWidth: 1024
    //     });
    // modalRef.content
    //   .initialize(this.dataService.getPrimarySearchService()
    //     .searchParams.tableId, searchCriteria);
  }

  // Overiding copy value to all rows method
  public copyValueToAllRows() {
    const noOfLineItems = this.dataService.getDetailsSearchService().getPagedData().pageDefinition.recordCount;
    // Here meaning of cName is whether any column is selected to copy or not
    const isColumnSelectedToCopy = this.dataService.getDetailsSearchService().getFocusedRow().cName;
    let selectedColumnToCopy = '';
    let valueToCopy = '';
    if (noOfLineItems === 0) {
      this.alertService.clearAll().error(this.resourceService.get(this.errorMsg));
      return;
    }
    // As per exceed from column values are not allowed to copy
    if (isColumnSelectedToCopy === 'isSelected') {
      this.alertService.clearAll().error(this.resourceService.get('msg_cpy_invalid_col'));
      return;
    }
    if (this.dataService.getDetailsSearchService().getPagedData().getFocusedRow().row['type'] === 'from') {
      selectedColumnToCopy = this.dataService.getDetailsSearchService().getFocusedRow().cName;
      this.alertService.clearAll().error(this.dataService.createAlertMessage(this.resourceService.get('msg_from_not_allow_copy'),
        this.resourceService.get(selectedColumnToCopy)));
      return;
    } else {
      if (this.dataService.getDetailsSearchService().getPagedData().getFocusedRow().cName) {
        selectedColumnToCopy = this.dataService.getDetailsSearchService().getPagedData().getFocusedRow().cName;
      }
      if (this.dataService.getDetailsSearchService().getFocusedRow().row) {
        valueToCopy = this.dataService.getDetailsSearchService().getFocusedRow().row.cells[selectedColumnToCopy].cVal;
      }
      this.dialogMsg = this.resourceService.get('msg_copy_colmn') + this.resourceService.get(selectedColumnToCopy)
        + this.resourceService.get('msg_to_all_rows') + this.resourceService.get(selectedColumnToCopy)
        + this.resourceService.get('msg_overwrt');
      this.confirmDialogService.okcancelDialog(this.resourceService.get(this.dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            // code to copy the selcted row value to all rows in to column
            this.dataService.getDetailsSearchService().getPagedData().pageRows.forEach(pageRow => {
              if (pageRow.type === 'to') {
                const lineItemCells = pageRow.cells;
                lineItemCells[selectedColumnToCopy].cVal = valueToCopy;
                pageRow.isEdited = true;
              }
            });
            this.dataService.getDetailsSearchService().publishEvent(new SearchEvent(REFRESH_SEARCH_EVENT));
          }
        });
    }
  }

  // [LFWM-2159] - to get dynamic buttons based on module name and storerKey
  // public getDynamicButtons(): Observable<any> {
  //   const storerKey = this.dataService.primaryDataMap['fromStorerKey'].cVal;
  //   const moduleName = this.dataService.tableIdToModuleNameMapping[this.dataService.primarySearchParams.tableId];
  //   return this.backendService.getDynamicButtons(storerKey, moduleName).pipe(map((resp: any) => {
  //     return resp;
  //   }));
  // }

  // [LFWM-2159] - method to handle dynamic button actions
  public handlingDynamicButtonAction(action: string) {
    const currentButtonInfo = this.buttonService.dynamicButtons.filter(button =>
      button.staticButtonName.toUpperCase() === action.toUpperCase());
    const confirmationMsg = currentButtonInfo[0]['confirmationMsg'] ? currentButtonInfo[0]['confirmationMsg'] :
      this.resourceService.get('genric_confirm_msg');
    this.confirmDialogService.confirmDialog(confirmationMsg)
      .subscribe((isConfirmed: any) => {
        if (isConfirmed) {
          const storerKey = this.dataService.primaryDataMap['fromStorerKey'].cVal;
          const documentKey = this.dataService.getPrimaryDataIdValue();
          const moduleName = this.dataService.tableIdToModuleNameMapping[this.dataService.primarySearchParams.tableId];
          const actionCode = currentButtonInfo[0]['code'];
          this.backendService.handlingDynamicButtonAction(storerKey, documentKey, moduleName, actionCode).subscribe((resp: any) => {
            if (resp.statusCode === 200 && resp.statusMessage === 'SUCCESS') {
              this.alertService.clearAll();
              this.alertService.success(this.dataService.createAlertMessage(this.resourceService.get('msg_activity_suc'), currentButtonInfo[0]['buttonName']));
            } else {
              if (resp.errMsg) {
                this.alertService.clearAll().error(this.resourceService.translateServerResponse(resp.errMsg));
              }
            }
          }, (error) => {
            this.alertService.clearAll().error(this.resourceService.get(error));
          });
        }
      });
  }
}
