import { Component } from '@angular/core';
import { IAfterGuiAttachedParams, IHeaderParams } from '@ag-grid-community/all-modules';
import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { SearchableEntityDataEditorService } from '../../../../../services/searchable-entity/searchable-entity-data-editor.service';
import { GridViewKeyEventHandlerService } from '../../../../../services/grid-view-key-event-handler.service';
import { tmsConfig } from 'src/app/core/tms-config';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { SearchService } from 'src/app/shared/search/search.service';
import { DashboardCrrSearchService } from 'src/app/features/reserve/dashboard/dashboard-crr-home/services/dashboard-crr-search.service';
import { DashboardCrrDataService } from 'src/app/features/reserve/dashboard/dashboard-crr-home/services/dashboard-crr-data.service';

@Component({
  templateUrl: './basic-cell-header.component.html',
  styleUrls: ['./basic-cell-header.component.css']
})
export class BasicCellHeaderComponent implements IHeaderAngularComp {

  refresh(params: IHeaderParams): boolean {
    return false;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
  }

  public params: any;
  public templateId: number = 0;
  public text: string = '';
  public dataEditor: SearchableEntityDataEditorService;
  public keyEventHandler: GridViewKeyEventHandlerService;
  public showTypeAhead: boolean;
  public filterdFromTypeAheadSuggestion: boolean = false;
  constructor(public http$: HttpClient,
    public searchService: SearchService,
    public dashboardCrrSearchService: DashboardCrrSearchService,
     public dataService: DashboardCrrDataService,){
      this.dashboardCrrSearchService = this.dataService.searchService;
  }

  public agInit(params: IHeaderParams): void {
        this.params = params;
    if (this.params.column.colId === 'isSelected') {
      this.templateId = 1;
    } else {
      this.templateId = 0;
    }
    const filterModel = this.params.context.componentParent.filterModel;
    this.keyEventHandler = this.params.context.componentParent.keyEventHandler;
    this.dataEditor = this.params.context.componentParent.tableEditor;
    if (filterModel && filterModel[this.params.column.colId]) {
      this.text = filterModel[this.params.column.colId];
    }
    this.showTypeAhead = this.params.context.componentParent
      .tableConfig.showTypeAheadInHeader;
  }

  public onFilterFocus(colIndex?: number) {
    // if (colIndex) {
    //   if (colIndex !== (this.params.context
    //       .componentParent.unpinnedColumnsList.length - 1)) {
    //     this.params.api.ensureColumnVisible(this.params
    //       .context.componentParent.unpinnedColumnsList[colIndex + 1]);
    //   }
    // } else {
    this.params.api.ensureColumnVisible(this.params.column.colId);
    // }
  }

  public onFilterTab() {
    // const colIndex: number
    //   = this.params.context.componentParent.unpinnedColumnsList
    //       .findIndex((colId: string) => {
    //         return (colId === this.params.column.colId);
    //       });
    // this.onFilterFocus(colIndex);
    return false;
  }

  public isFilterActive() {
    return true;
  }

  public doesFilterPass(params) {
    // This is a server side filter. We do not need any filtering by the ag grid filtering framework
    return true;
  }

  public onChange(): void {
    const searchedValue = [];

    if (this.params.column.colDef.cellRendererParams.cellMetaData.type === 'DATE') {
      // if (this.text) {
      //   this.text = this.dateConfigService.getFormattedDisplayDate(this.text);
      // } else {
      //   this.text = '';
      // }
    } else if (this.params.column.colDef.cellRendererParams.cellMetaData.type === 'DROPDOWN' ||
             this.params.column.colDef.cellRendererParams.cellMetaData.type === 'EDITABLEDROPDOWN') {
      if (this.text) {
        /*All keys whose values correspond to the user entered values
        will be pushed to an array inorder to form search criteria */
        this.params.column.colDef.cellRendererParams.cellMetaData.values
          .map((keyValueObject: any) => {
            const pattern: any = new RegExp('^' + this.text, 'i');
            if (pattern.test(keyValueObject.value)) {
              searchedValue.push(keyValueObject.key);
            }
          });
      } else {
        this.text = '';
      }
    }
    if (searchedValue.length > 0) {
      this.params.context.componentParent
        .fetchSearchResults(this.params.column.colDef.colId, searchedValue,
          this.params.column.colDef.cellRendererParams.cellMetaData.type);
    } else {
      if(this.params.context.componentParent.metaData.moduleId === 'dashboardsetuptd') {
        this.params.context.componentParent.pageDefinition.pageNumber = 1;
      }
      this.params.context.componentParent.fetchSearchResults(this.params.column.colDef.colId, this.text,
        this.params.column.colDef.cellRendererParams.cellMetaData.type);
    }
    var conditions = this.params.context.componentParent.filterModel


    this.queryData(conditions).subscribe((newdata)=>{
      console.debug('newdata',conditions,  newdata);
      
      this.params.context.componentParent.rowData = newdata.content;
      this.params.context.componentParent.pageDefinition.pageNumber = newdata.number + 1;
      this.params.context.componentParent.pageDefinition.pageSize = newdata.size

    })
  }

  public setModel(model: any): void {
    if (model) {
      this.text = model.filter;
    }
  }

  public getModel(): any {
    return { filter: this.text };
  }

  public queryData(value:any){
    
    const payload = this.dashboardCrrSearchService.getPayload();
    console.debug('=================', payload);

    value.businessUnitGid =payload.businessUnitGid;
    value.shipmentType = 'P';
    value.shipmentPlanStatus = payload.shipmentPlanStatus;
    var permission =  JSON.parse(localStorage.getItem('tms_button_permission'))
    var carrierId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Carrier')[0]?.restrictionValue || null
    if(carrierId){
      value.carrier = carrierId   
    } 
    var matchFlag = (window.location.pathname == '/home/reserve/shipment-match/search')

    var tenantId = permission['userRestrictions'][0]?.restrictions.filter(item=>item.restrictionTypeName == 'Tenant ID')[0]?.restrictionValue || 0
    var permissionAddUrl = `&tenantID=${tenantId}`
    const resultData: any = this.http$.post((`${matchFlag?tmsConfig.tms.newshipmentQueryUri:tmsConfig.tms.shipmentQueryUri}?page=0&size=10${permissionAddUrl}`), value);
    return resultData.pipe(map((response) => {
      return response
    }),
    catchError((error: any) => {
          if (error) {
              return error;
        }
   }))

  }

  public handleSuggestionSelected(event: any) {
    this.dataEditor.getAutoFilter(event.propertyName, event.selectedValue,
      this.params.context.componentParent.searchService,
      this.params.column.colDef.cellRendererParams.cellMetaData.type);
      this.filterdFromTypeAheadSuggestion = false;
  }

  public onValueChange(event: any) {
    if (event.keyCode === 13) {
      if (!this.filterdFromTypeAheadSuggestion) {
        if(this.params.context.componentParent.metaData.moduleId === 'dashboardsetuptd') {
          this.params.context.componentParent.pageDefinition.pageNumber = 1;
        }
        this.params.context.componentParent.fetchSearchResults(this.params.column.colDef.colId,
          event.target.value, this.params.column.colDef.cellRendererParams.cellMetaData.type);
      } else {
        this.filterdFromTypeAheadSuggestion = false;
      }
    }
  }
}
