// Angular dependencies
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng-lts/table';
import { DialogModule } from 'primeng-lts/dialog';
import { DropdownModule } from 'primeng-lts/dropdown';
import { CalendarModule } from 'primeng-lts/calendar';
import { TabViewModule } from 'primeng-lts/tabview';
import { SliderModule } from 'primeng-lts/slider';
import { InputMaskModule } from 'primeng-lts/inputmask';
import { TooltipModule } from 'primeng-lts/tooltip';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { ToastModule } from 'primeng/toast';
import { MultiSelectModule } from 'primeng-lts/multiselect';
import { GalleriaModule } from 'primeng-lts/galleria';
import { EditorModule } from 'primeng-lts/editor';
import { RadioButtonModule } from 'primeng-lts/radiobutton';
import { CheckboxModule } from 'primeng-lts/checkbox';
import { ConfirmDialogModule } from 'primeng-lts/confirmdialog';

// SEarch Related Imports
import { SavedSearchService } from './search/saved-search.service';
import { TableCustomizerComponent } from './search/results/result-data-table/table-customizer/table-customizer.component';
import { DataTableEditorService } from './search/results/data-table-editor.service';
import { BasicCellHeaderComponent } from './search/results/result-data-table/renderers/basic-cell-header/basic-cell-header.component';
import { BasicCellRendererComponent } from './search/results/result-data-table/renderers/basic-cell-renderer/basic-cell-renderer.component';
import { PinnedCellRendererComponent } from './search/results/result-data-table/renderers/pinned-cell-renderer/pinned-cell-renderer.component';
import { BasicCellEditorComponent } from './search/results/result-data-table/editors/basic-cell-editor/basic-cell-editor.component';
import { ResultDataTableComponent } from './search/results/result-data-table/result-data-table.component';
import { ResultDataMapperComponent } from './search/results/result-data-mapper/result-data-mapper.component';
import { SearchFilterComponent } from './search/filter/search-filter.component';
import { SearchFilterClauseComponent } from './search/filter/search-filter-clause/search-filter-clause.component';
import { SearchFilterConditionComponent } from './search/filter/search-filter-condition/search-filter-condition.component';
import { SearchService } from './search/search.service';
import { LocalPagedSearchService } from './search/localpaged-search.service';
import { SearchParams } from './search/models/search-params.model';
import { SearchStatus } from './search/models/search-status.model';
import { ManageSavedSearchComponent } from './search/filter/manage-saved-search/manage-saved-search.component';
import { ResultDynamicDataTableComponent } from './search/results/result-dynamic-data-table/result-dynamic-data-table.component';
// Widget
import { ColumnLayoutPrimaryWidgetComponent } from './components/widget/column-layout-primary-widget/column-layout-primary-widget.component';
import { ColumnLayoutSecWidgetComponent } from './components/widget/column-layout-sec-widget/column-layout-sec-widget.component';
import { DynamicWidgetHolderComponent } from './components/widget/dynamic-widget-holder/dynamic-widget-holder.component';
import { AbstractFormWidgetComponent } from './components/widget/abstract-form-widget/abstract-form-widget.component';

// Services
import { DocumentUtilService } from './services/document-util.service';
import { PrinterService } from './services/printer.service';
import { FileImportService } from './services/file-import.service';
import { ValidatorService } from './services/validator.service';
import { CrossFunctionalityNavigationService } from './services/cross-functionality-navigation.service';
import { FileUtilityService } from './services/file-utility.service';
import { DefaultMapService } from './search/filter/maps/service/default-map.service';
import { JqueryService } from './search/jquery.service';

// Buttons
import { ButtonPanelComponent } from './button/button-panel.component';
import { ButtonFactoryService } from './button/button-factory.service';
import { ButtonPanelHeaderComponent } from './button/button-panel-header/button-panel-header.component';

// Components
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SearchableEntitySearchComponent } from './components/searchable-entity/search-page/search-page.component';
import { SearchableEntityDetailsComponent } from './components/searchable-entity/details-page/details-page.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { AccordionLayoutComponent } from './components/platform/lf-accordion/lf-accordion.component';
import {
  LfTabbedpaneComponent,
  LfTabbedpanesComponent,
} from './components/platform/lf-tabbedpane/lf-tabbedpane.component';
import { SearchableEntityDynamicDetailsComponent } from './components/searchable-entity/dynamic-details-page/dynamic-details-page.component';
import { ImportComponent } from './components/import/import.component';
import { DefaultAccordionLayoutComponent } from './components/layout/default-accordion-layout/default-accordion-layout.component';
import { MultitabSearchableEntitySearchComponent } from './components/searchable-entity/multitab-search-page/multitab-search-page.component';
import { SimpleWidgetLayoutComponent } from './components/layout/simple-widget-layout/simple-widget-layout.component';
import { SearchableEntityStaticFormComponent } from './components/searchable-entity/static-form-page/static-form-page.component';
import { GridDatepickerComponent } from './components/datepicker-grid/datepicker-grid.component';
import { LookupInputComponent } from './components/lookup-input/lookup-input.component';
import { LookupFilterInputComponent } from './components/lookup-filter-input/lookup-filter-input.component';
import { ShowTaskPopupComponent } from './components/common/show-task-popup/show-task-popup.component';
import { TimeMaskComponent } from './components/time-mask/time-mask.component';
import { ViewTransactionsPopupComponent } from './components/common/view-transactions-popup/view-transactions-popup.component';
import { ViewBalancesPopupComponent } from './components/common/view-balances-popup/view-balances-popup.component';
import { ViewBalancesCellRendererComponent } from './components/common/view-balances-popup/view-balances-cell-renderer/view-balances-cell-renderer.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { shipmentColor } from '../features/shipment/shipment-primeng-table/shipment-color-selector';
// Directives
import { ResetScrollDirective } from '../shared/directives/reset-scroll.directive';
import { FocusDirective } from '../shared/directives/focus.directive';
import { OverflowTooltipDirective } from './directives/overflow-tooltip.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ScrollBottomDirective } from './directives/scroll-bottom.directive';

// Common
import { DatepickerComponent, SceCoreModule } from 'sce-core';
import { SharedServiceRegistry } from './shared-service-registry';

// Features
import { LocalPagedDataManager } from './search/local-paged-data-manager.service';
import { DynamicSearchableEntitySearchComponent } from './components/searchable-entity/dynamic-search-page/dynamic-search-page.component';
import { DynamicEntityDataService } from './services/searchable-entity/dynamic-entity/dynamic-entity-data.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AuthInterceptor,
  ResponseErrorInterceptor,
  TimingLogInterceptor,
} from '../http.interceptor';
import { SearchButtonGroupComponent } from './search/filter/search-button-group/search-button-group.component';
import { LfRelativeSidebarComponent } from './components/platform/lf-relative-sidebar/lf-relative-sidebar.component';
import { ShipmentPrimengTableComponent } from '../features/shipment/shipment-primeng-table/shipment-primeng-table.component';
import { ResultOrderEventDataTableComponent } from '../features/order/order-event/orderevent-table/result-data-table/result-data-table.component';
import { ResultOrderProcessingDataTableComponent } from '../features/order/order-processing/order-processing-table/result-data-table/result-data-table.component';
import { ResultAllOrderDataTableComponent } from '../features/order/all-order/all-order-table/result-data-table/result-data-table.component';
import { ResultSafetyReportDataTableComponent } from '../features/control-tower/safety-report/safety-report-table/result-data-table/result-data-table.component';
import { ResultInboundDataTableComponent } from '../features/report/inbound/inbound-table/result-data-table/result-data-table.component';
import { ResultOutboundDataTableComponent } from '../features/report/outbound/outbound-table/result-data-table/result-data-table.component';
import { ResultLocationVarianceReportDataTableComponent } from '../features/report/locationVarianceReport/locationVarianceReport-table/result-data-table/result-data-table.component';
import { ResultHandoverDataTableComponent } from '../features/report/handover/handover-table/result-data-table/result-data-table.component';
import { ResultReasonCodeDataTableComponent } from '../features/settings/reason-code/reason-code-table/result-data-table/result-data-table.component';
import { ResultCommodityDataTableComponent } from '../features/settings/commodity/commodity-table/result-data-table/result-data-table.component';
import { ResultSafetyCheckDataTableComponent } from '../features/settings/safety-check/safety-check-table/result-data-table/result-data-table.component';
import { ResultTransportHandlingDataTableComponent } from '../features/settings/transport-handling/transport-handling-table/result-data-table/result-data-table.component';
import { ResultServerConfigurationDataTableComponent } from '../features/notification/server-configuration/server-configuration-table/result-data-table/result-data-table.component';
import { ResultViewMessagesDataTableComponent } from '../features/notification/view-messages/view-messages-table/result-data-table/result-data-table.component';
import { ResultManageEventDataTableComponent } from '../features/notification/manage-event/manage-event-table/result-data-table/result-data-table.component';
import { ShipmentPrimengDragTableComponent } from '../features/shipment/shipment-primeng-table/shipment-primeng-drag-table.component';
import { MapComponent } from '../features/shipment/maps/components/map-component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ButtonModule } from 'primeng-lts/button';
import { ResultOrderVisibilityDataTableComponent } from '../features/control-tower/order-visibility/order-visibility-table/result-data-table/result-data-table.component';
import { ResultMassUploadDataTableComponent } from '../features/order/mass-upload/orderevent-table/result-data-table/result-data-table.component';
import { HeadingPrimaryComponent } from './components/heading-primary/heading-primary.component';
import { DataTableCellRendererComponent } from './components/datatable-cell-randerer/datatable-cell-renderer.component';
import { TmsDropdownComponent } from './components/tms-dropdown/tms-dropdown.component';
import { ProgressBarModule } from 'primeng-lts/progressbar';
import { TOWMapComponent } from '../features/control-tower/components/map-component';
import { SplitButtonModule } from 'primeng-lts/splitbutton';
import { DialogService } from 'primeng/dynamicdialog';
import { TmsLookupInputComponent } from './components/tms-lookup-input/tms-lookup-input.component';
import { ResultChildTemplateDataTableComponent } from '../features/notification/child-template/orderevent-table/result-data-table/result-data-table.component';
import { ResultMasterTemplateDataTableComponent } from '../features/notification/master-template/orderevent-table/result-data-table/result-data-table.component';
import { ResultThirdDataTableComponent } from '../features/report/thirdPartyReport/third-table/result-data-table/result-data-table.component';
import { StopSwellingDataTableComponent } from '../features/report/stop-dwelling/stop-dwelling-table/result-data-table/result-data-table.component';
import { ButtonPermissionComponent } from './components/button-permission/button-permission.component';
import { DriverDetailComponent } from '../features/reserve/dashboard/dialog/driver-detail.component';
import { CustomBasicCellRendererComponent } from './search/results/result-data-table/renderers/custom-basic-cell-renderer/custom-basic-cell-renderer.component';
// import { TmsLookupInputComponent } from './components/tms-lookup-input/tms-lookup-input.component';
import { ResultManageWorkflowDataTableComponent } from '../features/notification/manage-workflow/manage-workflow-table/result-data-table/result-data-table.component';
import { ManageWorkflowExportComponent } from '../features/notification/manage-workflow/manage-workflow-export/manage-workflow-export.component';
import { ResultParamCfgtDataTableComponent } from '../features/black-card/param-cfg/param-cfg-table/result-data-table/result-data-table.component';
import { ResultBindingDataTableComponent } from '../features/black-card/black-binding/black-bingding-table/result-data-table/result-data-table.component';
import { ServerConfigurationDetailComponent } from '../features/notification/server-configuration/server-configuration-detail/server-configuration-detail.component';
import { InputSwitchModule } from 'primeng-lts/inputswitch';
import { InputNumberModule } from 'primeng-lts/inputnumber';
import { InputTextModule } from 'primeng-lts/inputtext';
import { ResultDashboardDataTableComponent } from '../features/black-card/black-dashboard/black-dashboard-table/result-data-table/result-data-table.component';
import { AddEventComponent } from '../features/black-card/black-dashboard/add-event/add-event.component';
import { ResultManagementDataTableComponent } from '../features/black-card/black-management/black-management-table/result-data-table/result-data-table.component';
import { OperateDialogComponent } from '../features/black-card/black-management/operate-dialog/operate-dialog.component';
import { DashboardDetailComponent } from '../features/notification/dashboard/dashboard-detail/dashboard-detail.component';
import { ManageWorkflowDetailComponent } from '../features/notification/manage-workflow/manage-workflow-detail/manage-workflow-detail.component';
import { InputTextareaModule } from 'primeng-lts/inputtextarea';
import { ChangeTruckComponent } from './search/filter/change-truck/change-truck.component';
import { SplitShipmentTableComponent } from './search/filter/Split-Shipment-table/Split-Shipment-table.component';
import { OverlayPanelModule } from 'primeng-lts/overlaypanel';
import { ResultServiceReportTableComponent } from '../features/control-tower/service-report/service-report-table/result-data-table/result-data-table.component';
import { KPITableComponent } from '../features/control-tower/KPI-Dashboard/KPI-Table/KPI-Table.component';
import { KPIShipmentTableComponent } from '../features/control-tower/KPI-Dashboard/KPI-Shipment-Table/KPI-Shipment-Table.component';
import { ResultFleetMonitoringDataTableComponent } from '../features/control-tower/fleet-monitoring/fleet-monitoring-table/result-data-table/result-data-table.component';
import { ShipmentScanningStatusComponent } from '../features/shipment/shipment-primeng-table/shipment-scanning-status/shipment-scanning-status';
import { BreadcrumbAllOrderComponent } from '../features/order/all-order/breadcrumb/breadcrumb.component';
import { ResultPullReportDataTableComponent } from '../features/report/pull-report/pull-report-table/result-data-table/result-data-table.component';
@NgModule({
  imports: [
    ConfirmDialogModule,
    CheckboxModule,
    RadioButtonModule,
    EditorModule,
    PaginatorModule,
    CommonModule,
    FormsModule,
    SplitButtonModule,
    ReactiveFormsModule,
    CalendarModule,
    TableModule,
    DialogModule,
    TabViewModule,
    SliderModule,
    InputMaskModule,
    TooltipModule,
    RouterModule,
    DropdownModule,
    AngularEditorModule,
    PanelModule,
    ToastModule,
    DragDropModule,
    ButtonModule,
    MultiSelectModule,
    GalleriaModule,
    ProgressBarModule,
    InputSwitchModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    OverlayPanelModule,
    AgGridModule.withComponents([
      BasicCellHeaderComponent,
      BasicCellRendererComponent,
      BasicCellEditorComponent,
      CustomBasicCellRendererComponent,
      PinnedCellRendererComponent,
    ]),
    SceCoreModule,
  ],
  declarations: [
    CustomBasicCellRendererComponent,
    ButtonPermissionComponent,
    ResultThirdDataTableComponent,
    StopSwellingDataTableComponent,
    ResultMasterTemplateDataTableComponent,
    ResultChildTemplateDataTableComponent,
    ServerConfigurationDetailComponent,
    ResultMassUploadDataTableComponent,
    MapComponent,
    TOWMapComponent,
    SearchFilterComponent,
    ResultDataTableComponent,
    ResultDataMapperComponent,
    SearchFilterClauseComponent,
    SearchFilterConditionComponent,
    ButtonPanelComponent,
    ResetScrollDirective,
    ClickOutsideDirective,
    ScrollBottomDirective,
    DropdownComponent,
    TimeMaskComponent,
    GridDatepickerComponent,
    PaginatorComponent,
    FocusDirective,
    SearchableEntitySearchComponent,
    SearchableEntityDetailsComponent,
    AccordionComponent,
    ButtonPanelHeaderComponent,
    ManageSavedSearchComponent,
    TooltipComponent,
    AccordionLayoutComponent,
    LfTabbedpanesComponent,
    LfTabbedpaneComponent,
    BasicCellRendererComponent,
    BasicCellEditorComponent,
    BasicCellHeaderComponent,
    PinnedCellRendererComponent,
    OverflowTooltipDirective,
    TableCustomizerComponent,
    ImportComponent,
    SearchableEntityDynamicDetailsComponent,
    DefaultAccordionLayoutComponent,
    MultitabSearchableEntitySearchComponent,
    ColumnLayoutPrimaryWidgetComponent,
    ColumnLayoutSecWidgetComponent,
    DynamicWidgetHolderComponent,
    AbstractFormWidgetComponent,
    SimpleWidgetLayoutComponent,
    SearchableEntityStaticFormComponent,
    LookupInputComponent,
    LookupFilterInputComponent,
    ViewTransactionsPopupComponent,
    ShowTaskPopupComponent,
    ViewBalancesPopupComponent,
    ViewBalancesCellRendererComponent,
    CheckboxComponent,
    DynamicSearchableEntitySearchComponent,
    ResultDynamicDataTableComponent,
    SearchButtonGroupComponent,
    LfRelativeSidebarComponent,
    ShipmentPrimengTableComponent,
    ResultOrderEventDataTableComponent,
    ResultOrderProcessingDataTableComponent,
    ResultAllOrderDataTableComponent,
    ResultSafetyReportDataTableComponent,
    ResultOrderVisibilityDataTableComponent,
    ResultInboundDataTableComponent,
    ResultOutboundDataTableComponent,
    ResultLocationVarianceReportDataTableComponent,
    ResultHandoverDataTableComponent,
    ResultReasonCodeDataTableComponent,
    ResultCommodityDataTableComponent,
    ResultSafetyCheckDataTableComponent,
    ResultTransportHandlingDataTableComponent,
    StopSwellingDataTableComponent,
    ResultServerConfigurationDataTableComponent,
    ResultViewMessagesDataTableComponent,
    ResultManageEventDataTableComponent,
    ShipmentPrimengDragTableComponent,
    shipmentColor,
    HeadingPrimaryComponent,
    DataTableCellRendererComponent,
    TmsDropdownComponent,
    TmsLookupInputComponent,
    ResultManageWorkflowDataTableComponent,
    ManageWorkflowExportComponent,
    ResultParamCfgtDataTableComponent,
    ResultBindingDataTableComponent,
    ResultDashboardDataTableComponent,
    ResultManagementDataTableComponent,
    AddEventComponent,
    OperateDialogComponent,
    DashboardDetailComponent,
    ManageWorkflowDetailComponent,
    ChangeTruckComponent,
    SplitShipmentTableComponent,
    ResultServiceReportTableComponent,
    KPITableComponent,
    KPIShipmentTableComponent,
    ResultFleetMonitoringDataTableComponent,
    ShipmentScanningStatusComponent,
    BreadcrumbAllOrderComponent,
    ResultPullReportDataTableComponent,
    // TmsLookupInputComponent,
    // DatepickerComponent
  ],
  exports: [
    SearchFilterComponent,
    SearchFilterClauseComponent,
    SearchFilterConditionComponent,
    ResultDataTableComponent,
    ResultDataMapperComponent,
    ButtonPanelComponent,
    ButtonPanelHeaderComponent,
    ResetScrollDirective,
    ClickOutsideDirective,
    ScrollBottomDirective,
    DropdownComponent,
    GridDatepickerComponent,
    PaginatorComponent,
    FocusDirective,
    SearchableEntitySearchComponent,
    SearchableEntityDetailsComponent,
    AccordionComponent,
    AccordionLayoutComponent,
    LfTabbedpanesComponent,
    LfTabbedpaneComponent,
    TooltipComponent,
    OverflowTooltipDirective,
    TableCustomizerComponent,
    ImportComponent,
    SearchableEntityDynamicDetailsComponent,
    DefaultAccordionLayoutComponent,
    MultitabSearchableEntitySearchComponent,
    ColumnLayoutPrimaryWidgetComponent,
    ColumnLayoutSecWidgetComponent,
    DynamicWidgetHolderComponent,
    SimpleWidgetLayoutComponent,
    SearchableEntityStaticFormComponent,
    LookupInputComponent,
    LookupFilterInputComponent,
    ViewTransactionsPopupComponent,
    ShowTaskPopupComponent,
    ViewBalancesPopupComponent,
    ViewBalancesCellRendererComponent,
    CheckboxComponent,
    DynamicSearchableEntitySearchComponent,
    ResultDynamicDataTableComponent,
    SearchButtonGroupComponent,
    LfRelativeSidebarComponent,
    ShipmentPrimengTableComponent,
    ResultOrderEventDataTableComponent,
    ServerConfigurationDetailComponent,
    ResultOrderProcessingDataTableComponent,
    ResultAllOrderDataTableComponent,
    ResultSafetyReportDataTableComponent,
    ResultOrderVisibilityDataTableComponent,
    ResultInboundDataTableComponent,
    ResultOutboundDataTableComponent,
    ResultLocationVarianceReportDataTableComponent,
    ResultHandoverDataTableComponent,
    ResultReasonCodeDataTableComponent,
    ResultCommodityDataTableComponent,
    ResultSafetyCheckDataTableComponent,
    ResultTransportHandlingDataTableComponent,
    ResultServerConfigurationDataTableComponent,
    ResultViewMessagesDataTableComponent,
    ShipmentPrimengDragTableComponent,
    ResultManageEventDataTableComponent,
    shipmentColor,
    HeadingPrimaryComponent,
    DataTableCellRendererComponent,
    TmsDropdownComponent,
    TmsLookupInputComponent,
    ButtonPermissionComponent,
    ResultManageWorkflowDataTableComponent,
    ManageWorkflowExportComponent,
    ResultManagementDataTableComponent,
    DashboardDetailComponent,
    ManageWorkflowDetailComponent,
    SplitShipmentTableComponent,
    ResultServiceReportTableComponent,
    KPITableComponent,
    KPIShipmentTableComponent,
    ResultFleetMonitoringDataTableComponent,
    ShipmentScanningStatusComponent,
    BreadcrumbAllOrderComponent,
    ResultPullReportDataTableComponent,
  ],
  entryComponents: [
    ColumnLayoutPrimaryWidgetComponent,
    ColumnLayoutSecWidgetComponent,
    ViewTransactionsPopupComponent,
    ShowTaskPopupComponent,
    ViewBalancesPopupComponent,
    ViewBalancesCellRendererComponent,
    DriverDetailComponent,
  ],
  providers: [
    DocumentUtilService,
    SearchService,
    DynamicEntityDataService,
    LocalPagedSearchService,
    SearchParams,
    SearchStatus,
    PrinterService,
    ValidatorService,
    SharedServiceRegistry,
    CrossFunctionalityNavigationService,
    DefaultMapService,
    JqueryService,
    DialogService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimingLogInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseErrorInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ButtonFactoryService,
        DataTableEditorService,
        SavedSearchService,
        AccordionComponent,
        ButtonPanelComponent,
        ButtonPanelHeaderComponent,
        SharedServiceRegistry,
        FileImportService,
        CrossFunctionalityNavigationService,
        LocalPagedDataManager,
        FileUtilityService,
      ],
    };
  }
}
