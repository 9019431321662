import { Injectable } from '@angular/core';
import { CoreServiceRegistry } from '../../core/core-service-registry';
import { SharedServiceRegistry } from '../../shared/shared-service-registry';
import { SearchParams } from '../../shared/search/models/search-params.model';
import { DataTableConfig } from '../../shared/search/models/data-table-config';
import { SearchEvent } from '../../shared/search/models/search-event';
import { DetailedEntityDataEditorService } from '../../shared/services/searchable-entity/detailed-entity/detailed-entity-data-editor.service';
import { FleetMonitoringDataService } from './fleet-monitoring-data.service';
import { SearchCriteria, Clause } from '../../shared/search/models/search-filter-model';
import { FleetMonitoringBackendService } from './fleet-monitoring-backend.service';


@Injectable()
export class FleetMonitoringDataEditorService extends DetailedEntityDataEditorService {

  public dataService: FleetMonitoringDataService;
  public backendService: FleetMonitoringBackendService;
  // public lookedUpProperty: string;
  public focussedRowIndex: number;

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
  }

  // method used to obtain the lookup title
  public getLookupModalTitle(property) {
    switch (property) {


      case 'fromStorerKey': return this.resourceService.get('lbl_lookup').concat(' ').concat(this.resourceService.get('storer_Id'));

      case 'toStorer': return this.resourceService.get('lbl_lookup').concat(' ').concat(this.resourceService.get('storer_Id'));

      case 'commodity': return this.resourceService.get('lbl_lookup').concat(' ').concat(this.resourceService.get('commodity'));

      case 'loc': return this.resourceService.get('lbl_lookup').concat(' ').concat(this.resourceService.get('loc'));
      default: return property;
    }
  }

  // this method sets the parameters for the lookup
  public createLookupParamsForColumn(property: any): SearchParams {
    const lookupParams = new SearchParams();
    lookupParams.isFullFetch = true;
    lookupParams.columnCount = 10;
    lookupParams.defaultSearchColumn = '';
    const tableConfig: DataTableConfig = new DataTableConfig();
    tableConfig.showIdAsAHyperLink = true;
    tableConfig.supportFiltering = true;
    lookupParams.dataTableConfig = tableConfig;
    // if this is from the header section, get the property directly
    if (property === 'fromStorerKey' || property === 'toStorer') {
      lookupParams.tableId = 'storertd';
      lookupParams.idProperty = 'storerName';
    } else {
      // convert the display property to the original property
      property = this.dataService.getOriginalPropertyForDisplayProperty(property, this.focussedRowIndex);
      // if this is from the table section, get the original property for the display property
      // this property is set from the cell editor
      if (property === 'fromSku' || property === 'toSku') {
        lookupParams.tableId = 'INVADJSKUTD';
        lookupParams.idProperty = 'sku';
      } else if (property === 'fromLoc' || property === 'toLoc') {
        lookupParams.tableId = 'INVADJSKUTD';
        lookupParams.idProperty = 'locId';
      }
    }
    return lookupParams;
  }

  public createDefaultSearchCriteria(currentRow: any, selectedColumnValue: any, property: any) {
    // commmodity and location lookup should be based on storerKey and facility
    if (property !== 'commodity' && property !== 'loc') {
      return new SearchCriteria();
    }
    const defaultSearchCriteria = new SearchCriteria();
    defaultSearchCriteria.conditions = [];
    defaultSearchCriteria.loadDefaultCriteria = false;
    const defaultCondition = this.createSearchCondition(1);
    defaultCondition.isReadOnly = true;
    defaultCondition.isMandatory = true;
    const defaultClause = new Clause();
    let defaultClause2 = null;
    let defaultClause3 = null;
    defaultClause.operation = '=';

    const lookedUpProperty = this.dataService.getOriginalPropertyForDisplayProperty(property,
      this.focussedRowIndex);
    let storerProperty = null;
    let facility = null;
    if (lookedUpProperty === 'fromSku' || lookedUpProperty === 'fromLoc') {
      storerProperty = 'fromStorerKey';
      facility = 'fromFacility';
    } else if (lookedUpProperty === 'toSku' || lookedUpProperty === 'toLoc') {
      storerProperty = 'toStorer';
      facility = 'toFacility';
    }
    let selectedStorer = this.dataService.primaryDataMap[storerProperty].cVal;
    if (selectedStorer === '') {
      selectedStorer = '*';
    }
    let selectedFacility = this.dataService.primaryDataMap[facility].cVal;
    if (selectedFacility === '') {
      selectedFacility = '*';
    }
    defaultClause.column = 'storerKey';
    defaultClause.value = selectedStorer;

    defaultClause.operation = '=';
    defaultCondition.clauses = [defaultClause];

    defaultCondition.operator = 'AND';
    defaultClause2 = new Clause();
    defaultClause2.operation = '=';
    defaultClause2.logicalOperation = 'AND';
    defaultClause2.column = 'facility';
    defaultClause2.value = selectedFacility;
    let selectedCommodity = '';
    // location lookup should also consider commodity field
    if (property === 'loc' && currentRow['commodity']) {
      selectedCommodity = currentRow['commodity'].cVal;
      defaultClause3 = new Clause();
      defaultClause3.operation = '=';
      defaultClause3.logicalOperation = 'AND';
      defaultClause3.column = 'sku';
      defaultClause3.value = selectedCommodity;
    }
    if (defaultClause2) {
      defaultCondition.clauses.push(defaultClause2);
    }
    if (defaultClause3 && property !== 'commodity') {
      defaultCondition.clauses.push(defaultClause3);
    }
    defaultSearchCriteria.conditions.push(defaultCondition);

    return defaultSearchCriteria;
  }

  public validateBeforeLookup(currentRow: any, property: any): boolean {
    if (property === 'commodity') {
      let storerProperty = null;
      const lookedUpProperty = this.dataService.getOriginalPropertyForDisplayProperty(property,
        this.focussedRowIndex);
      if (lookedUpProperty === 'fromSku') {
        storerProperty = 'fromStorerKey';
      } else if (lookedUpProperty === 'toSku') {
        storerProperty = 'toStorer';
      }
      const selectedStorer = this.dataService.primaryDataMap[storerProperty].cVal;
      if (selectedStorer === null || selectedStorer === '') {
        this.alertService.clearAll().error(this.resourceService.get('Please select a corresponding storer.'));
        return false;
      }
    }
    return true;
  }
  public handleLookupEvent(event: SearchEvent, property: any, currentColumnValue: any, currentRowCells: any) {
    super.handleLookupEvent(event, property, currentColumnValue, currentRowCells);
    if (property === 'commodity') {
      const row = { cells: currentRowCells };
      this.autoPopulate(row, 'pack');
      if (currentRowCells['fromSku']) {
        currentRowCells['fromSku'] = event.selectedItemId;
      } else if (currentRowCells['toSku']) {
        currentRowCells['toSku'] = event.selectedItemId;
      }
    } else if (property === 'fromStorerKey') {
      this.populateDrpdwnOnFromStorer();
    }
  }

  // this method returns the dependant property
  public getDependantMapForProperty(property: string) {
    let propMap;
    if (property === 'fromStorerKey') {
      propMap = {
        'fromStorerKey': 'storerName'
      };
    } else if (property === 'toStorer') {
      propMap = {
        'toStorer': 'storerName'
      };
    } else if (property === 'commodity') {
      propMap = {
        'commodity': 'sku',
        'descr': 'skuDescr',
        'pack': 'Packkey',
        'storer_key': 'storerKey'
      };
    } else if (property === 'loc') {
      propMap = {
        'loc': 'locId',
      };
    } else {
      propMap = {};
    }
    return propMap;
  }

  // method finds the next property to focus
  public getNextPropertyToFocus(property) {
    switch (property) {

      case 'toSku': return 'toPack';
      case 'fromSku': return 'fromPack';
      default: return property;
    }
  }
  /* Start LFWM-954(Trimmed white sace- not a best solution,it should handle in backend or database) :
 This method is used to set options values for dropdown irrespective of space */
  public fetchOptionsValues(dataList: any, key: string) {
    let selectedValue = null;
    const dataListKeys = Object.keys(dataList);
    for (let i = 0; i < dataListKeys.length; i++) {
      if (dataListKeys[i].trim() === key.trim()) {
        selectedValue = dataList[dataListKeys[i]];
        break;
      }
    }
    return this.trimKeyValues(selectedValue);
  }
  public trimKeyValues(dataList: any) {
    if (dataList) {
      dataList.forEach((data: any) => {
        data.key = data.key.trim();
      });
    }
    return dataList;
  }
  /* End LFWM-954 : This method is used to set options values for dropdown irrespective of space */

  public populateAllRestrictedValues(pageRows: any) {
    // either iterate on metadata and call for all ea columns or
    // make call to each RestrictedValue based columns in this case;
    this.populateRestrictedValues(pageRows, 'pack');
  }

  public populateRestrictedValues(pageRows: any, colName: any) {
    switch (colName) {
      case 'pack': {
        if (this.dataService.getPrimaryDataIdValue()) {
          const itemId = this.dataService.getPrimaryDataIdValue();
          pageRows.forEach(row => {
            if (row.type === 'from') {
              this.populateRestrictedValuesForRow('fromPack', pageRows, itemId);
            } else if (row.type === 'to') {
              this.populateRestrictedValuesForRow('toPack', pageRows, itemId);
            }
          });
        }
        break;
      }
    }
  }
  // for transfer screen, the values should be populated for from row and two row
  public populateRestrictedValuesForRow(pack: any, pageRows: any, itemId: any) {
    const request = {
      'propertyName': pack,
      'key': itemId
    };
    this.backendService.populateRestrictedValues(request).subscribe((res: any) => {
      // this.dataService.lookupMap.packKeys = {};
      this.dataService.lookupMap.packKeys = res;
      pageRows.forEach((row: any) => {
        if (pack === 'fromPack') {
          if (row.type === 'from' && row.cells['TRANS_UOM']) {
            row.cells['TRANS_UOM'].cOptions = this.fetchOptionsValues(this.dataService.lookupMap.packKeys, row.cells['pack'].cVal);
            row.cells['TRANS_UOM'].cValOldUOM = row.cells['TRANS_UOM'].cVal.trim();
            row.cells['TRANS_UOM'].isLoading = false;
            row.cells['TRANS_UOM'].cVal = row.cells['TRANS_UOM'].cVal.trim();
          }
        } else if (pack === 'toPack') {
          if (row.type === 'to' && row.cells['TRANS_UOM']) {
            row.cells['TRANS_UOM'].cOptions = this.fetchOptionsValues(this.dataService.lookupMap.packKeys, row.cells['pack'].cVal);
            row.cells['TRANS_UOM'].cValOldUOM = row.cells['TRANS_UOM'].cVal.trim();
            row.cells['TRANS_UOM'].isLoading = false;
            row.cells['TRANS_UOM'].cVal = row.cells['TRANS_UOM'].cVal.trim();
          }
        }
      });
    });
    pageRows.forEach((row: any) => {
      if (row.cells['TRANS_UOM']) {
        row.cells['TRANS_UOM'].isLoading = true;
      }
    });
  }
  // method used to autoPopulate  uom based on Pack
  public autoPopulate(row: any, colName: any) {
    if (colName === 'pack') {
      row.cells['TRANS_UOM'].isLoading = true;
      const packKeyVal = row.cells[colName].cVal;
      if (packKeyVal === '') {
        row.cells['TRANS_UOM'].cOptions = null;
        // set to null so that it uses default uom values from columnMetadata
        row.cells['TRANS_UOM'].cVal = '';
        row.cells['TRANS_UOM'].cValOldUOM = '';
        row.cells['TRANS_UOM'].isLoading = false;
        row.cells['pack'].isValueInvalid = false;
        row.focusedCellId = 'TRANS_UOM';
        return;
      }
      if (this.dataService.lookupMap.packKeys && this.dataService.lookupMap.packKeys[packKeyVal]) {
        // LFWM-954
        const cOptions = this.fetchOptionsValues(this.dataService.lookupMap.packKeys, packKeyVal);
        row.cells['TRANS_UOM'].cOptions = cOptions;
        row.cells['TRANS_UOM'].isLoading = false;
        if (cOptions.length > 0) {
          row.cells['pack'].isValueInvalid = false;
          // row.cells['uom'].cValPrev = row.cells['uom'].cVal;
          row.cells['TRANS_UOM'].cVal = cOptions[0].key;
          row.cells['TRANS_UOM'].cValPrev = cOptions[0].key;
          row.cells['TRANS_UOM'].cValOldUOM = cOptions[0].key;
          row.cells['TRANS_UOM'].isEdited = true;
          row.cells['qty'].cVal = 0;
          row.cells['qty'].isEdited = true;
          row.cells['qty'].cValPrev = 0;
          row.focusedCellId = 'TRANS_UOM';
        } else {
          row.cells['pack'].isValueInvalid = true;
          row.cells['TRANS_UOM'].cVal = '';
          row.cells['TRANS_UOM'].cValPrev = '';
          row.cells['TRANS_UOM'].cValOldUOM = '';
          row.cells['TRANS_UOM'].isEdited = true;
          row.cells['qty'].cVal = 0;
          row.cells['qty'].isEdited = true;
          row.focusedCellId = 'pack';
        }
      } else {
        this.backendService.autoPopulate(packKeyVal)
          .subscribe((res: any) => {
            // JIRA 1076
            const formatedRes = {}; // stores the response with trimmed keys
            if (res) {
              Object.keys(res).forEach((key: any) => {
                formatedRes[key.trim()] = res[key];
              });
            }
            // this.dataService.lookupMap.packKeys = {};
            if (!this.dataService.lookupMap.packKeys) { // If null initialise
              this.dataService.lookupMap.packKeys = {};
            }
            const cOptions = (res && formatedRes[packKeyVal.trim()]) ? formatedRes[packKeyVal.trim()] : [];
            // packKeyVal is trimmed to remove space
            this.dataService.lookupMap.packKeys[packKeyVal] = cOptions;
            row.cells['TRANS_UOM'].cOptions = cOptions;
            row.cells['TRANS_UOM'].isLoading = false;
            if (cOptions.length > 0) {
              row.cells['pack'].isValueInvalid = false;
              // row.cells['uom'].cValPrev = row.cells['uom'].cVal;
              row.cells['TRANS_UOM'].cVal = cOptions[0].key;
              row.cells['TRANS_UOM'].cValPrev = cOptions[0].key;
              row.cells['TRANS_UOM'].cValOldUOM = cOptions[0].key;
              row.cells['TRANS_UOM'].isEdited = true;
              row.cells['qty'].cVal = 0;
              row.cells['qty'].isEdited = true;
              row.cells['qty'].cValPrev = 0;
              row.focusedCellId = 'TRANS_UOM';
            } else {
              row.cells['pack'].isValueInvalid = true;
              row.cells['TRANS_UOM'].cVal = '';
              row.cells['TRANS_UOM'].cValPrev = '';
              row.cells['TRANS_UOM'].cValOldUOM = '';
              row.cells['TRANS_UOM'].isEdited = true;
              row.cells['qty'].cVal = 0;
              row.cells['qty'].isEdited = true;
              row.cells['qty'].cValPrev = 0;
              row.focusedCellId = 'pack';
            }
          });
      }
    } else if (colName === 'TRANS_UOM') {
      if (row.cells[colName].cVal === '') {
        row.cells[colName].isValueInvalid = true;
        row.focusedCellId = colName;
        return;
      } else if (row.cells[colName].cValOldUOM === '') {
        // if packkey is reset do not call ComputeUOM
        row.cells[colName].isValueInvalid = false;
        //  row.focusedCellId = 'expectedQty';
        return;
      }
      row.cells[colName].isValueInvalid = false;
      //  row.cells['expectedQty'].isLoading = true;
      // row.cells['receivedQty'].isLoading = true;
      const oldUomVal = row.cells[colName].cValOldUOM;
      const newUomVal = row.cells[colName].cVal;
      const packKeyVal = row.cells['pack'].cVal;
      // cvalprev=cval is set from editkeypresslistner
      const fromQty = row.cells['qty'].cVal;
      // const fromReceivedQty = row.cells['receivedQty'].cVal;
      if (fromQty === 0) {
        row.cells['qty'].isValueInvalid = false;
        row.cells['qty'].isLoading = false;
        row.focusedCellId = 'qty';
        return; // return if both values are zero. i.e no service call reqd
      }
      const reqObj = {
        fromExpectedQty: fromQty ? fromQty : 0,
        fromReceivedQty: '',
        fromUOM: oldUomVal, // ? oldUomVal : newUomVal,
        toUOM: newUomVal,
        packKey: packKeyVal
      };
      this.backendService.autoPopulateUOMFeilds(reqObj)
        .subscribe((res: any) => {
          if (res.statusCode === 200 && res.statusMessage === 'SUCCESS') {
            row.cells['qty'].cValPrev = row.cells['qty'].cVal;
            row.cells['qty'].cVal = res.toExpectedQty;
            row.cells['qty'].isEdited = true;
            row.cells['qty'].isValueInvalid = false;
            row.cells['qty'].isLoading = false;
            row.focusedCellId = 'qty';
          } else {
            this.alertService.clearAll().error('Could not fetch from qty on UOM change');
            row.cells['qty'].isValueInvalid = true;
            row.cells['qty'].isLoading = false;
            row.focusedCellId = 'TRANS_UOM';
          }
        });
    }
  }

  /**
 * populatOnFromStorer
 */
  public populateDrpdwnOnFromStorer() {
    this.dataService.typeCoptions = this.dataService.typeCoptions ? this.dataService.typeCoptions
      : this.dataService.primaryMetaDataMap['type'].values;
    this.dataService.reasonCoptions = this.dataService.reasonCoptions ? this.dataService.reasonCoptions
      : this.dataService.primaryMetaDataMap['reasonCode'].values;
    const typeValues: any[] = this.getValuesByFromStorer('type');
    const reasonValues: any[] = this.getValuesByFromStorer('reasonCode');
    this.dataService.primaryDataMap['type'].cOptions = typeValues;
    this.dataService.primaryDataMap['reasonCode'].cOptions = reasonValues;
  }

  /**
   * getValuesByFromStorer
   */
  public getValuesByFromStorer(colName: string) {
    const cOptionsByFromStorer: any[] = [];
    const defaultOptionsByFromStorer: any[] = [];
    const fromStorer: string = this.dataService.primaryDataMap['fromStorerKey'].cVal.trim();
    const originalCoptions: any[] = colName === 'type' ? this.dataService.typeCoptions : this.dataService.reasonCoptions;

    // iterarting to find type or reason associated with a storer key
    originalCoptions.forEach((coption: any) => {
      if (coption.storerkey === fromStorer) {
        // if there is a match for storer key
        cOptionsByFromStorer.push(coption);
      } else if (coption.storerkey !== fromStorer && !(coption.storerkey)) {
        // if there is no match for storer key
        defaultOptionsByFromStorer.push(coption);
      }
    });
    if (cOptionsByFromStorer.length > 0) {
      return cOptionsByFromStorer;
    } else {
      return defaultOptionsByFromStorer;
    }
  }
}
