import { Component } from '@angular/core';
import { AbstractFormWidgetComponent } from '../abstract-form-widget/abstract-form-widget.component';

@Component({
  selector: 'lfwms-column-layout-sec-widget',
  templateUrl: './column-layout-sec-widget.component.html',
  styleUrls: ['./column-layout-sec-widget.component.css']
})
export class ColumnLayoutSecWidgetComponent extends AbstractFormWidgetComponent {

  // realigns the widget
  public realignWidget() {
    this.propertyList = this.preparePropertyGrid(this.widgetConfig.propertyList, this.getCellsPerRow());
  }

  public getCellsPerRow() {
    let cellsPerRow: number = 0;
    this.windowWidth = window.innerWidth - this.sidePanelWidth;
    switch (true) {
      case (this.windowWidth > 1250): {
        cellsPerRow = 5;
        break;
      }
      case (this.windowWidth <= 1250 && this.windowWidth > 1030): {
        cellsPerRow = 4;
        break;
      }
      case (this.windowWidth <= 1030 && this.windowWidth >= 800): {
        cellsPerRow = 3;
        break;
      }
      case (this.windowWidth <= 800 && this.windowWidth >= 560): {
        cellsPerRow = 2;
        break;
      }
      case (this.windowWidth < 560): {
        cellsPerRow = 1;
        break;
      }
      default: {
        cellsPerRow = 5;
        break;
      }
    }
    return cellsPerRow;
  }

  // Method to define grid layout for each accordion.
  public preparePropertyGrid(propertyList: Array<any>, cellsPerRow) {
    let accordionRow;
    const accordionDefinition = [];
    for (let x = 0; x < propertyList.length; x++) {
      if (x % cellsPerRow === 0) {
        if (accordionRow && accordionRow.length === cellsPerRow) {
          accordionDefinition.push(accordionRow);
        }
        accordionRow = [];
      }
      accordionRow.push(propertyList[x]);
    }
    if (accordionRow && accordionRow.length !== 0) {
      for (let y = accordionRow.length; y < cellsPerRow; y++) {
        accordionRow.push('dummy');
      }
      accordionDefinition.push(accordionRow);
    }
    return accordionDefinition;
  }
}

