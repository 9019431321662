import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from 'sce-core';
import { UserService } from 'sce-core';
import { NavigationService } from 'sce-core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class OnlyLoggedInUsersGuard implements CanActivate {
  public header = new HttpHeaders({ 'api': 'admin' });
  constructor(public tokenService: TokenService,
    public userService: UserService,
    public router: Router,
    public navService: NavigationService,
    public http$: HttpClient) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      const param = route.queryParams;
      let accessToken = '';
      let userName = '';
      let password = '';
      if ('access_token' in param) {
        accessToken = param.access_token;
        if (!accessToken) {
          this.userService.clearStore();
        }
        this.queryUserInfo(accessToken).subscribe(data => {
          if(data?.statusCode != 400){
            localStorage.setItem('user_info',JSON.stringify(data['userInfo']))
          }
        })
        // this.getAppPrivRestriction(8).subscribe(resp => {          
        //   localStorage.setItem('tms_button_permission',JSON.stringify(resp))
        // }, (error2) => {
        //   console.debug('BillingPrivilegeError', error2);
        // });
      }
      if (param.userName) {
        userName = param.userName;
      }
      if (param.password) {
        password = param.password;
      }

      if (accessToken && accessToken !== this.tokenService.getToken()) {
        const tokenExpiry = (+new Date() + 24 * 3600 * 1000);
        this.tokenService.setToken(userName, accessToken, tokenExpiry);
       // this.userService.resetUserInfo();
      }

      if (this.tokenService.isAuthenticatedUser()) {
        if (this.navService.appInitialized) {
          const name = state.url.split('/');
          if (name.length > 2) {
            this.navService.directUrlNavigation = state.url
          }
          return true;
        }
        this.navService.initAppDataAndReload(state.url);
        return false;
      } else {
        this.userService.logout();
        return false;
      }
    // if (this.tokenService.isAuthenticatedUser()) {
    //   if (this.navService.appInitialized) {
    //     const name = state.url.split('/');
    //     if (name.length > 2) {
    //       this.navService.directUrlNavigation = state.url
    //     }
    //     return true;
    //   }
    //   this.navService.initAppDataAndReload(state.url);
    //   return false;
    // } else {
    //   this.userService.logout();
    //   return false;
    // }
  }

  public getAppPrivRestriction(appId): Observable<any> {
    return this.http$.get(environment.wmsApiBaseUrl +'api/users/' + appId + '/privrestriction', { headers: this.header }).pipe(map((response: any) => {
      const privilegeCodes = [];
      if (response.userPermissions) {
        response.userPermissions.forEach((privilege, index) => {
          privilegeCodes.push(privilege.privilegeCode);
        });
      }
      response.userPermissionsList = privilegeCodes;
      return response;
    }),catchError((error) => {
      return Observable.throw(error);
    }));
  }

  public queryUserInfo(accessToken:any){
    return this.http$.post(environment.notificationApiBaseUrl + '/htms/api/user/sce/getUserInfo', JSON.stringify({key: accessToken})).pipe(
      map((response: any) => {   
        return response;
      }));
  }

}
