import { Injectable } from '@angular/core';
import { SearchableEntityDataService } from './searchable-entity-data.service';
import { SearchableEntityActionService } from './searchable-entity-action.service';
import { SearchableEntityButtonService } from './searchable-entity-button.service';
import { SearchableEntityDataEditorService } from './searchable-entity-data-editor.service';
import { SearchableEntityBackendService } from './searchable-entity-backend.service';

@Injectable()
export class SearchableEntityServiceFactory {

  constructor(public dataService: SearchableEntityDataService,
    public actionService: SearchableEntityActionService,
    public backendService: SearchableEntityBackendService,
    public dataEditor: SearchableEntityDataEditorService,
    public buttonService: SearchableEntityButtonService) {
    if (dataService) {
      dataService.setDependentServices(dataEditor, buttonService);
    }
    if (actionService) {
      actionService.setDependentServices(dataService, buttonService, dataEditor, backendService);
    }
    if (backendService) {
      backendService.setDependentServices(dataService);
    }
    if (dataEditor) {
      dataEditor.setDependentServices(dataService, backendService);
    }
    if (buttonService) {
      buttonService.setDependentServices(dataService, actionService);
    }
  }

  public getDataService(): SearchableEntityDataService {
    return this.dataService;
  }

  public getActionService(): SearchableEntityActionService {
    return this.actionService;
  }

  public getDataEditor(): SearchableEntityDataEditorService {
    return this.dataEditor;
  }

  public getBackendService(): SearchableEntityBackendService {
    return this.backendService;
  }

  public getButtonService(): SearchableEntityButtonService {
    return this.buttonService;
  }
}
