import { Component } from '@angular/core';
// import { ICellRendererAngularComp } from 'ag-grid-angular';
// import { ICellRendererParams } from 'ag-grid/main';
import { SearchEvent } from '../../../../models/search-event';
import { ITEM_CLICK_EVENT } from '../../../../search-constants';
import { DatePickerConfigService } from '../../../../../components/datepicker/datepicker-config.service';
import { ResourceService } from 'sce-core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

@Component({
  selector: 'lfwms-custom-basic-cell-renderer',
  templateUrl: './custom-basic-cell-renderer.component.html',
  styleUrls: ['./custom-basic-cell-renderer.component.css']
})
export class CustomBasicCellRendererComponent implements ICellRendererAngularComp {

  public isLoading: boolean = true;
  public params: any;
  public templateId: number = 0;
  public cellValue: any;
  public cellDisplayValue: any;
  public customClass: string = '';
  public showFa: boolean = false;

  // Flag to display the 'x' mark / checkbox
  public isNewRow: boolean = false;

  constructor(
    private dateConfigService: DatePickerConfigService,
    protected resourceService: ResourceService,
    ) {

  }


  public setFaClass(params) {
    console.debug(params,'----params')
   if (params.colDef.field === 'equipmentNumber' && !params.data.equipmentGid && this.params.value) {
      this.showFa = true;
      this.customClass = 'fa fa-exclamation orange';
    } else if (params.colDef.field === 'actualEquipmentGroupGid' && this.params.value && params.data.equipmentGroupGid != this.params.value) {
      this.showFa = true;
      this.customClass = 'fa fa-exclamation orange';
    } else if (params.colDef.field === 'driverName' && this.params.value && !params.data.driverGid) {
      this.showFa = true;
      this.customClass = 'fa fa-exclamation orange';
    }
  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellValue = this.params.value;
    this.setFaClass(params);
    /* For new rows, row selection checkbox will be
      replaced with row delete button 'x' button */
    if (this.params.node.data['isNewItem']) {
      this.isNewRow = this.params.node.data['isNewItem'].cVal;
    }
    if (this.params.column.colDef.colId === 'isSelected') {
      // Row selection checkbox template
      this.templateId = 1;
      this.cellValue = this.params.value;
    } else if (this.params.value) {
      this.cellValue = this.params.value.cVal;
      if (this.params.context.componentParent
        .tableConfig.showIdAsAHyperLink && (this.params.column.colDef.colId
          === this.params.context.componentParent
            .searchService.searchParams.idProperty)) {
        // Hyperlink template
        this.templateId = 2;
      } else if (this.params.cellMetaData
        && this.params.cellMetaData.dataType === 'Currency') {
        // Currency template
        this.templateId = 3;
      } else if (this.params.cellMetaData
        && this.params.cellMetaData.type === 'DROPDOWN' || this.params.cellMetaData.type === 'EDITABLEDROPDOWN') {
        const filteredOptions: Array<any> = this.params.cellMetaData.values
          .filter((option: any) => {
            return option.key === this.cellValue;
          });
        this.cellDisplayValue
          = ((filteredOptions.length > 0) ?
            filteredOptions[0].value : this.cellValue);
        if (this.cellDisplayValue === '- Select -') {
          this.cellDisplayValue = '';
        }
        // Dropdown template
        this.templateId = 4;
      } else if (this.params.cellMetaData
        && (this.params.cellMetaData.type === 'CHECKBOX' || this.params.cellMetaData.type === 'CHECKBOX_CHR')) {
        // Adding CHECKBOX_CHR type denoting the saved value will be 'Y' or 'N'
        // CHECKBOX type denoting the saved value will be '1' or '0'
        // Checkbox template
        this.templateId = 5;
        this.cellValue = this.params.value.cVal;
      } else if (this.params.cellMetaData &&
        (this.params.cellMetaData.type === 'DATE' || this.params.cellMetaData.type === 'DATETIME')) {
        // Date or Datetime Template
        this.templateId = 6;
        if (this.params.cellMetaData.type === 'DATE' && this.params.value.cVal) {
          this.cellValue = this.dateConfigService.getFormattedDisplayDate(this.params.value.cVal);
        } else if (this.params.cellMetaData.type === 'DATETIME' && this.params.value.cVal) {
          this.cellValue = this.dateConfigService.getFormattedDisplayDateTime(this.params.value.cVal);
        }
      } else if (this.params.cellMetaData
        && this.params.cellMetaData.type === 'HYPERLINK') {
        // HYPERLINK template
        this.templateId = 2;
      } else if (this.params.cellMetaData
        && this.params.cellMetaData.type === 'HYPERLINK2') {
        // HYPERLINK template
        this.templateId = 12;
      } else if (this.params.cellMetaData
        && this.params.cellMetaData.type === 'INDICATOR' && this.params.value.cVal === 'BLUE') {
          // HYPERLINK template
        this.templateId = 13;
      } else if (this.params.cellMetaData
        && this.params.cellMetaData.type === 'INDICATOR' && this.params.value.cVal === 'GREEN') {
          // HYPERLINK template
        this.templateId = 14;
      } else if (this.params.cellMetaData
        && this.params.cellMetaData.type === 'INDICATOR' && this.params.value.cVal === 'YELLOW') {
          // HYPERLINK template
        this.templateId = 15;
      } else if (this.params.cellMetaData
        && this.params.cellMetaData.type === 'INDICATOR' && this.params.value.cVal === 'RED') {
          // HYPERLINK template
        this.templateId = 16;
      } else if (this.params.cellMetaData
        && this.params.cellMetaData.type === 'INDICATOR' && this.params.value.cVal === 'ORANGE') {
          // HYPERLINK template
        this.templateId = 17;
      } else {
        // Default renderer template
        this.templateId = 0;
      }
    }
    this.isLoading = false;
  }

  public refresh(params: any): boolean {
    this.params = params;
    if (this.params.column.colDef.colId === 'isSelected') {
      this.cellValue = this.params.value;
    } else {
      if (this.params.value) {
        /* JIRA LFWM-1756 Fixes - Date format [country specific format ] of already existing date changes
       to default input format [YYYY-MM-DD HH:mm:ss] when choosing dates from calender. It should be displyed
       in the country specific format always */
        if (this.params.colDef.cellEditorParams.cellMetaData.type === 'DATE' && this.params.value.cVal) {
          this.cellValue = this.dateConfigService.getFormattedDisplayDate(this.params.value.cVal);
        } else if (this.params.colDef.cellEditorParams.cellMetaData.type === 'DATETIME' && this.params.value.cVal) {
          this.cellValue = this.dateConfigService.getFormattedDisplayDateTime(this.params.value.cVal);
        } else {
          this.cellValue = this.params.value.cVal;
        }
      }
    }
    return true;
  }

  public navigateToHyperlink() {
    const searchEvent: SearchEvent = new SearchEvent(ITEM_CLICK_EVENT);
    searchEvent.selectedItemId = this.params.value.cVal;
    searchEvent.selectedProperty = this.params.colDef.field;
    searchEvent.selectedRow = this.params.context.componentParent.searchService
      .getPagedData().pageRows[this.params.rowIndex];
    this.params.context.componentParent.searchService.publishEvent(searchEvent);
  }


  public navigateToHyperlink2(linkNo) {
    const searchEvent: SearchEvent = new SearchEvent(ITEM_CLICK_EVENT);
    searchEvent.selectedItemId = linkNo;
    searchEvent.selectedProperty = this.params.colDef.field;
    searchEvent.selectedRow = this.params.context.componentParent.searchService
      .getPagedData().pageRows[this.params.rowIndex];
    this.params.context.componentParent.searchService.publishEvent(searchEvent);
  }

}
