class SpecialButtonConfig {
    buttonType: string ;
    returnType?: string ; // optional-- default value 'file' used if not provided
}
export class ButtonItem {

    public isEnabled: boolean = true;
    public isVisible: boolean = true;

    constructor(public action: string, public elementId: string, public accessPrivilege: string,
        public imageUrl: string, public subItems: Array<ButtonItem>,
        public specialButtonConfig?: SpecialButtonConfig) {
           // console.log(this.specialButtonConfig)
         }

         /*specialButtonConfig -- is used for rendering upload/import buttons
         or any other special buttons
         in button-panel.component.html*/

}
