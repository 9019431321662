import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/all-modules';

@Component({
  selector: 'lfwms-view-balances-cell-renderer',
  templateUrl: './view-balances-cell-renderer.component.html',
  styleUrls: ['./view-balances-cell-renderer.component.css']
})
export class ViewBalancesCellRendererComponent implements ICellRendererAngularComp {

  public params: any;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  public refresh(): boolean {
    return true;
  }

}

