<div class="drpdwn-wrapper">
<p-dropdown #primeFocusInput [options]="dropdownOptions" appendTo="body" [(ngModel)]="selectedOption" [scrollHeight]="scrollHeight"
  (onChange)="onSelect($event);" (onFocus)="onFocus();"
  (keypress)="onKeydown($event)" [editable]="isEditable">
  <ng-template let-item pTemplate="selectedItem"> 
    <span>{{ item.label }}</span>
  </ng-template> 
  <ng-template let-dropdownOption pTemplate="item"> 
        <span>{{ dropdownOption.label }}</span>
  </ng-template>
</p-dropdown>
</div>