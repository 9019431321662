<ng-template [ngIf]="!isLoading">
  <div class="renderer-container" [ngSwitch]="templateId">
    <ng-template [ngSwitchCase]="0">
      <!-- Default renderer template -->
      <span class="renderer-default">
        <i class="" [ngClass]="customClass" *ngIf="showFa"></i>
        {{ cellValue }}
      </span>
    </ng-template>
    <ng-template [ngSwitchCase]="1">
      <!-- New row selection template -->
      <ng-template [ngIf]="isNewRow" [ngIfElse]="defaultRowSelect">
        <span (click)="params.context.componentParent
            .deleteNewRow(params.rowIndex);">
          <i class="fa fa-times-circle lfwms-cancel-newrow"></i>
        </span>
      </ng-template>
      <!-- Default row selection template -->
      <ng-template #defaultRowSelect>
        <span [ngSwitch]="cellValue" (click)="params.context.componentParent
            .toggleSelect(params.rowIndex, params.node);">
          <ng-template [ngSwitchCase]="true">
            <i class="fa fa-check-square-o lfwms-checkbox" [ngClass]="{ 'fa-disabled': (params.context
                .componentParent.pageRows[params.rowIndex]?.isSelectionFreezed) }">
            </i>
          </ng-template>
          <ng-template [ngSwitchCase]="false">
            <i class="fa fa-square-o lfwms-checkbox" [ngClass]="{ 'fa-disabled': (!params.context
                .componentParent.showSelectOption || params.context
                .componentParent.pageRows[params.rowIndex]?.isSelectionFreezed) }">
            </i>
          </ng-template>
        </span>
        <ng-template [ngIf]="params.node.data['showAdditionalSelectionIcon']">
          <img class="lf-svg lf-additional-flag" src="{{ 'assets/images/svg/' + params.context.componentParent
              .searchService.searchParams.dataTableConfig.additionalSelectionIcon }}" />
        </ng-template>
      </ng-template>
    </ng-template>
    <!-- Hyperlink renderer template -->
    <ng-template [ngSwitchCase]="2">
      <span class="renderer-default">
        <a class="hyperlink-content" (click)="navigateToHyperlink();">{{ cellValue }}</a>
      </span>
    </ng-template>
    <!-- Hyperlink2 renderer template -->
    <ng-template [ngSwitchCase]="12">
      <span class="renderer-default">
        <a *ngFor="let v of params.cellMetaData.values" class="hyperlink-content hyperlink-content2" (click)="navigateToHyperlink2(v);">{{ v }}</a>
      </span>
    </ng-template>
    <!-- indicator1 renderer template -->
    <ng-template [ngSwitchCase]="13">
        <i class="fa fa-circle" aria-hidden="true" style="color: #0e3fe0;"></i>
    </ng-template>
    <ng-template [ngSwitchCase]="14">
      <i class="fa fa-circle" aria-hidden="true" style="color: #0da84e;"></i>
    </ng-template>
    <ng-template [ngSwitchCase]="15">
      <i class="fa  fa-caret-up fa-2x" aria-hidden="true" style="color: #FF8000;"></i>
    </ng-template>
    <ng-template [ngSwitchCase]="16">
      <i class="fa fa-square" aria-hidden="true" style="color: #e00e0e;"></i>
    </ng-template>
    <ng-template [ngSwitchCase]="17">
      <i class="fa fa-circle" aria-hidden="true" style="color: #18e0da;"></i>
    </ng-template>
      <!-- Currency renderer template -->
    <ng-template [ngSwitchCase]="3">
      <span class="renderer-default">
        <span *ngIf="params.cellMetaData
          && params.cellMetaData.dataType === 'Currency'">$</span>{{ cellValue }}
      </span>
    </ng-template>
    <!-- Dropdown or Editable Dropdown renderer template -->
    <ng-template [ngSwitchCase]="4">
      <span class="renderer-default">
        <i class="" [ngClass]="customClass" *ngIf="showFa"></i>
        {{ cellDisplayValue }}
      </span>
    </ng-template>
    <!-- Checkbox renderer template -->
    <ng-template [ngSwitchCase]="5">
      <span [ngSwitch]="cellValue">
        <ng-template [ngSwitchCase]="true">
          <i class="fa fa-check-square-o lfwms-checkbox"></i>
        </ng-template>
        <ng-template [ngSwitchCase]="false">
          <i class="fa fa-square-o lfwms-checkbox"></i>
        </ng-template>
        <ng-template [ngSwitchCase]="'Y'">
          <i class="fa fa-check-square-o lfwms-checkbox"></i>
        </ng-template>
        <ng-template [ngSwitchCase]="'N'">
          <i class="fa fa-square-o lfwms-checkbox"></i>
        </ng-template>
        <ng-template [ngSwitchCase]=null>
          <i class="fa fa-square-o lfwms-checkbox"></i>
        </ng-template>
        <ng-template ngSwitchDefault>
          <i class="fa fa-square-o lfwms-checkbox"></i>
        </ng-template>
      </span>
    </ng-template>
    <ng-template [ngSwitchCase]="6">
      <!-- Date or Datetime renderer template -->
      <span class="renderer-default">{{ cellValue }}</span>
    </ng-template>
  </div>
</ng-template>
