import {UserService} from 'sce-core';
import {Injectable} from '@angular/core';
import {SearchStatus} from '../../../../../shared/search/models/search-status.model';
import {UserPreferenceService} from '../../../../../layout/home/services/user-preference.service';
// import {DatePickerConfigService} from 'sce-ui-lib';
import {SimpleEntityDataService} from '../../../../../shared/services/searchable-entity/simple-entity/simple-entity-data.service';
import {DashboardCrrSearchService} from './dashboard-crr-search.service';
import {CodeValueService} from '../../../common/services/code-value.service';
import { ShipmentMatchSearchService } from '../../shipment-match-home/services/shipment-match-search.service';
import { DatePickerConfigService } from '../../../../../shared/components/datepicker/datepicker-config.service';

@Injectable()
export class DashboardCrrDataService extends SimpleEntityDataService {
  public dateConfigService: DatePickerConfigService;
  public userPreferencesService: UserPreferenceService;
  public userService: UserService;
  public searchService: DashboardCrrSearchService;
  public displayMode: string = 'E';

  public initializeSearchContext2(codeValueService: CodeValueService) {
    this.searchParams = this.entityConfig.getPrimarySearchParams();
    this.searchParams.tableEditor = this.dataEditor;
    this.searchParams.alertService = this.alertService;
    if (this.buttonService) {
      this.searchParams.actionButtons = this.buttonService.getButtonsForPanel('search-table');
    }
    this.searchStatus = new SearchStatus();
    this.searchService = new DashboardCrrSearchService(this.coreServiceRegistry.http$,
      this.searchParams,
      this.searchStatus,
      this.coreServiceRegistry.resourceService,
      this.userPreferencesService,
      this.alertService,
      this.dateConfigService, this.userService);
    this.searchService.setCodeValueService(codeValueService);
  }

  public initializeSearchContext3(codeValueService: CodeValueService) {
    this.searchParams = this.entityConfig.getPrimarySearchParams();
    this.searchParams.tableEditor = this.dataEditor;
    this.searchParams.alertService = this.alertService;
    if (this.buttonService) {
      this.searchParams.actionButtons = this.buttonService.getButtonsForPanel('search-table');
    }
    this.searchStatus = new SearchStatus();
    this.searchService = new ShipmentMatchSearchService(this.coreServiceRegistry.http$,
      this.searchParams,
      this.searchStatus,
      this.coreServiceRegistry.resourceService,
      this.userPreferencesService,
      this.alertService,
      this.dateConfigService, this.userService);
    this.searchService.setCodeValueService(codeValueService);
  }
}
