import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ButtonFactoryService } from '../button-factory.service';
import { ButtonItem } from '../button.model';

@Component({
  selector: 'lfwms-button-panel-header',
  templateUrl: './button-panel-header.component.html',
  styleUrls: ['./button-panel-header.component.css']
})
export class ButtonPanelHeaderComponent {

  @Input()
  buttonArray: Array<ButtonItem>;

  @Output()
  buttonClickHandler: EventEmitter<string> = new EventEmitter();

  constructor(public factoryService: ButtonFactoryService) {}

  public handleButtonClick(button: ButtonItem) {
    this.buttonClickHandler.emit(button.action);
  }
  // Method is used to check whether the user having corresponding privilege code to enable the button
  public checkButtonPrivilege(privilege: String) {
    return this.factoryService.checkButtonPrivilege(privilege);
  }
}
