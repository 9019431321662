export const ADD_FAVOURITE: number = 0;
export const DELETE_FAVOURITE: number = 1;
export const ADD_SEARCH: number = 2;
export const DELETE_SEARCH: number = 3;
export const ADD_RECENT: number = 4;
export const UPDATE: number = 5;

export class UserPreferenceEvent {

    public type: number;

    public item: string;

    public source: string;

    public isExternal: boolean;

    public preferenceId: number;
}
