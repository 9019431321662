import { Injectable } from '@angular/core';
import { ButtonItem } from '../../../button/button.model';
import { CoreServiceRegistry } from '../../../../core/core-service-registry';
import { SharedServiceRegistry } from '../../../shared-service-registry';
import { SearchableEntityButtonService } from '../searchable-entity-button.service';
import { DetailedEntityDataService } from './detailed-entity-data.service';
import { DetailedEntityActionService } from './detailed-entity-action.service';
import { SearchEvent } from '../../../search/models/search-event';
import { AUTO_ADD_ROW_EVENT } from '../../../search/search-constants';

@Injectable()
export class DetailedEntityButtonService extends SearchableEntityButtonService {

  public dataService: DetailedEntityDataService;
  public actionService: DetailedEntityActionService;

  public detailMainButtons: Array<ButtonItem>;
  public detailTableButtons: Array<ButtonItem>;
  public dynamicButtons: any[] = [];
  public dynamicButtonsExist: boolean = false;

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
    this.detailMainButtons = this.userButtonMap['detail-main'];
    this.detailTableButtons = this.userButtonMap['detail-table'];
  }

  public loadButtonsForUser() {
    super.loadButtonsForUser();
    if (this.allButtonsMap['detail-main']) {
      this.userButtonMap['detail-main'] = this.buttonFactoryService.loadButtonsByPrivilege(this.allButtonsMap['detail-main']);
    } else {
      this.userButtonMap['detail-main'] = [];
    }
    if (this.allButtonsMap['detail-table']) {
      this.userButtonMap['detail-table'] = this.buttonFactoryService.loadButtonsByPrivilege(this.allButtonsMap['detail-table']);
    } else {
      this.userButtonMap['detail-table'] = [];
    }
  }

  public handleButtonActionForCreate(action: any) {
    const needOkConfirmation = false;
    switch (action) {
      // if there are any actions which need confirmation, add it here.
    }
    if (needOkConfirmation) {
      const dialogMsg = 'msg_save_data';
      this.confirmDialogService.okDialog(this.resourceService.get(dialogMsg));
    } else {
      this.performButtonActions(action);
    }
  }

  public handleButtonActionForEdit(action: any) {
    let needYesNoConfirmation = false;
    switch (action) {
      // if there are any actions which need confirmation, add it here.
      case 'Edit':
        needYesNoConfirmation = true;
        break;
    }
    if (needYesNoConfirmation) {
      const dialogMsg = 'msg_save_data';
      this.confirmDialogService.yesnocancelDialog(this.resourceService.get(dialogMsg)).subscribe((isConfirmed: any) => {
        if (isConfirmed) {
          this.actionService.savePage().subscribe(response => {
            if (response.statusCode && response.statusCode === 200) {
              const successMsg = 'msg_save_succ';
              this.alertService.clearAll().success(successMsg);
                this.performButtonActions(action);
            } else {
              this.alertService.clearAll().error(this.resourceService.get(response.statusMessage));
            }
          });
        } else if (isConfirmed === false) {
            this.performButtonActions(action);
        }
      });
    } else {
        this.performButtonActions(action);
    }
  }

  // method used to perform button actions for various button clicks
  public performButtonActions(action: any) {
    switch (action) {
      case 'Edit': {
        this.actionService.editPage();
        break;
      }
      case 'Save': {
        this.actionService.successMsg = 'msg_save_succ';
        const saveObs = this.actionService.savePage();
        if (saveObs) {
          saveObs.subscribe((response) => {
            if (response.statusCode === 200) {
              // If there are line items in details page
              if (this.dataService.entityConfig.getShowDetailLineItems()) {
                if (!this.dataService.getPrimarySelectedDocument()) {
                  this.dataService.setPrimarySelectedDocument(response.moduleIds[0].cVal);
                }
                // Setting Search Criteria in Details Search Service which is the input for Data Table in Details Accordion
                if (this.dataService.displayMode === 'C') {
                  this.dataService.getDetailsSearchService().setSearchCriteria({
                    conditions: [{
                      mandatory: false,
                      clauses: [{
                        column: this.dataService.primarySearchParams.idProperty,
                        operation: '=',
                        value: this.dataService.getPrimarySelectedDocument(),
                        logicalOperation: null
                      }],
                      logicalOperation: null
                    }]
                  });
               }
                this.dataService.getDetailsSearchService().clearMarkerFlags();
                if (this.dataService.detailsSearchService.getNewPageEventInProgress()) {
                  // this save has come while navigating to a new page. Hence continue the navigation
                  this.dataService.detailsSearchService.setNewPageEventInProgress(false);
                  // if this was a create flow, set it to edit flow
                  if (this.dataService.displayMode === 'C') {
                    this.dataService.setDisplayMode('E');
                    this.initializeButtons(this.dataService.primaryDataMap);
                  }
                  const evt = new SearchEvent(AUTO_ADD_ROW_EVENT);
                  this.dataService.detailsSearchService.publishEvent(evt);
                  return;
                }
              }
              this.actionService.clearEditedFlags();
              const successMsg = 'msg_save_succ';
              this.alertService.clearAll().success(this.resourceService.get(successMsg));
              this.navService.preventNavigation = false;
              if (this.dataService.displayMode === 'C') {
                this.navService.removeLastBreadCrumbItem();
                let id;
                if (this.dataService.getPrimarySelectedDocument().trim()) {
                  id = this.dataService.getPrimarySelectedDocument();
                } else {
                  id = response.moduleIds[0].cVal;
                }
                this.dataService.setDisplayMode('V');
                this.initializeButtons(this.dataService.getPrimaryDataMap());
                 // If there are line items in details page
                //  Jira 1823 , as we have navigatewithRouteParms, it will do the same loadBypage, so an extra call leads to duplication of data
                // if (this.dataService.entityConfig.getShowDetailLineItems()) {
                //   this.dataService.getDetailsSearchService().performSearch();
                // }
                this.navService.retainAlerts().navigateWithRouteParams(this.dataService.getEntityConfiguration().getDetailPageRoute(), [id]);
              } else if (this.dataService.displayMode === 'E') {
                this.dataService.setDisplayMode('V');
                this.initializeButtons(this.dataService.getPrimaryDataMap());
              }
            } else {
               // If there are line items in details page
              if (this.dataService.entityConfig.getShowDetailLineItems()) {
                if (this.dataService.detailsSearchService.getNewPageEventInProgress()) {
                  this.dataService.detailsSearchService.setNewPageEventInProgress(false);
                }
            }
              this.alertService.clearAll();
              this.alertService.error(this.resourceService.get(response.statusMessage));
            }
          }, (error) => {
             // If there are line items in details page
            if (this.dataService.entityConfig.getShowDetailLineItems()) {
              if (this.dataService.detailsSearchService.getNewPageEventInProgress()) {
                this.dataService.detailsSearchService.setNewPageEventInProgress(false);
              }
          }
            this.alertService.error(this.resourceService.get(error));
          });
        }

        break;
      }
      case 'Delete': {
        this.actionService.dialogMsg = this.dataService.createAlertMessage(this.resourceService.get('msg_conf_del'));
        this.actionService.successMsg = this.resourceService.get('msg_del_succ');
        this.actionService.delete();
        break;
      }
      case 'Delete Line Item(s)': {
        this.actionService.dialogMsg = this.dataService.createAlertMessage(this.resourceService.get('msg_itm_conf_del'));
        this.actionService.errorMsg = this.resourceService.get('msg_sel_detail');
        this.actionService.successMsg = this.resourceService.get('msg_del_itm_succ');
        this.actionService.deleteLineItem();
        break;
      }
      case 'Delete All Line Items': {
        this.actionService.dialogMsg = this.dataService.createAlertMessage(this.resourceService.get('msg_del_itm_all'));
        this.actionService.successMsg = this.resourceService.get('msg_del_all_succ');
        this.actionService.errorMsg = this.resourceService.get('msg_no_detail');
        this.actionService.deleteAllLineItems();
        break;
      }
      case 'Create': {
        this.actionService.showDetailsPage();
        break;
      }
      case 'Export as Excel': {
        this.exportAsExcel();
        break;
      }
      case 'Export as CSV': {
        this.exportAsCSV();
        break;
      }
    }
  }


  public printReport() {
    const docuementType = this.dataService.primarySearchParams.tableId;

    let facility = '';
    let storerkey = '';
    if (this.dataService.primaryDataMap && this.dataService.primaryDataMap['facility']) {
      facility = this.dataService.primaryDataMap['facility'].cVal;
    }
    if (this.dataService.primaryDataMap && this.dataService.primaryDataMap['storerKey']) {
      storerkey = this.dataService.primaryDataMap['storerKey'].cVal;
    }
    // [LFWM-1981] - passing data service for - ASN, Trade Returns, Inbound XDock, Outbound XDock, beacuse for detail print we need line items.
    this.printerService.printReport(docuementType, storerkey, facility, this.dataService.getPrimaryDataMap(), this.dataService);
  }

  public initializeButtons(cellDataMap: any) {
    this.initializeButtonsForPage(cellDataMap, 'search');
    this.initializeButtonsForPage(cellDataMap, 'details');
  }

  // this method sets the visible and enabled flags for the buttons based on the page attributes
  public initializeButtonsForPage(cellDataMap: any, pageName: string) {
    if (pageName === 'search') {
      this.initializeSearchPageButtons(cellDataMap);
      // [JIRA LFWM-611 Fixes]-If there are no search results in search table then there is no need of finalize button
      if (!this.dataService.primarySearchService ||
        !this.dataService.primarySearchService.getPagedData() ||
        !this.dataService.primarySearchService.getPagedData().pageRows ||
        this.dataService.primarySearchService.getPagedData().pageRows.length <= 0) {
        this.buttonFactoryService.rebuildButtonArray(this.userButtonMap['search-table'],
          []);
      }
    } else if (pageName === 'details') {
      this.initializeDetailPageButtons(cellDataMap);
      // [JIRA LFWM-611 fixes]-If there is no detail line items in detail grid, then there is no need of any buttons
      if (!this.dataService.detailsSearchService ||
        !this.dataService.detailsSearchService.getPagedData()
        || !this.dataService.detailsSearchService.getPagedData().pageRows
        || this.dataService.detailsSearchService.getPagedData().pageRows.length <= 0) {
        if (this.dataService.displayMode === 'C' || this.dataService.displayMode === 'E') {
          this.buttonFactoryService.rebuildButtonArray(this.userButtonMap['detail-table'],
            ['Add Row']);
        } else {
          this.buttonFactoryService.rebuildButtonArray(this.userButtonMap['detail-table'],
            []);
        }
      }
    }
  }

  public initializeSearchPageButtons(cellDataMap: any) {
  }

  public initializeDetailPageButtons(cellDataMap: any) {
  }

  public exportAsExcel() {
    this.actionService.exportData(this.dataService.primarySearchService, 'excel');
  }

  public exportAsCSV() {
    this.actionService.exportData(this.dataService.primarySearchService, 'csv');
  }

  /**
   * This method handles the button actions from the main panel of the detail page
   * @param action - selected button action
   */
  public handleMainButtonPanelActions(action) {
    const isCreateFlow = (this.dataService.displayMode === 'C');
    if (isCreateFlow) {
      this.handleButtonActionForCreate(action);
    } else {
      let haveUnsavedData;
      if (this.dataService.getDetailsSearchService()) {
        haveUnsavedData = (this.dataService.getDetailsSearchService().getEditedCellsPerRow().length > 0)
          || (this.dataService.getEditedPrimaryCells().length > 0);
      } else {
        haveUnsavedData = this.dataService.getEditedPrimaryCells().length > 0;
      }
      if (haveUnsavedData) {
        this.handleButtonActionForEdit(action);
      } else {
        this.performButtonActions(action);
      }
    }
  }

  /* [LFWM-2125] - replacing the static [ex: RCM01] button name with dynamic button name which is already created.
   dynamic button name is not a static one.
  */
  public replacingStaticButtonNameWithDynamicButtonName() {
    this.userButtonMap['detail-main'].forEach((button: any) => {
      this.dynamicButtons.forEach((dynamicButton: any) => {
        // comparing baction name of already created button and static button name of dynamic button information
        if (button.action.toUpperCase() === dynamicButton.staticButtonName.toUpperCase()) {
          button.elementId = dynamicButton.buttonName;
          return false;
        }
      });
    });
  }
}
