import { Component, OnDestroy } from '@angular/core';
import { SharedServiceRegistry } from './../../../shared-service-registry';
import { CoreServiceRegistry } from './../../../../core/core-service-registry';
import { ResourceService } from 'sce-core';
import { SearchService } from './../../../search/search.service';
import { SearchParams } from './../../../search/models/search-params.model';
import { SearchStatus } from './../../../search/models/search-status.model';
import { AlertService } from 'sce-core';
import { SearchEvent } from 'src/app/shared/search/models/search-event';
import { NEW_ROW_EVENT, NEW_SEARCH_EVENT, REFRESH_SEARCH_EVENT } from 'src/app/shared/search/search-constants';
import { Observable, Subject } from 'rxjs';
import { metaDataTag, metaDataTagForDetails } from 'src/app/features/notification/notification-tag/notification-tag.config';
import { SearchableEntityDataService } from 'src/app/shared/services/searchable-entity/searchable-entity-data.service';
import { DataTableConfig } from 'src/app/shared/search/models/data-table-config';
import { metaDataGrpForEmail } from 'src/app/features/notification/notification-template/notification-template.config';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SearchCriteria } from 'src/app/shared/search/models/search-filter-model';
import { NotificationTagSearchService } from 'src/app/features/notification/notification-tag/notification-tag/services/notification-tag-search.service';
import { DetailedEntityDataService } from 'src/app/shared/services/searchable-entity/detailed-entity/detailed-entity-data.service';
//import * as from './addTag.json';

@Component({
  selector: 'lfwms-view-transactions-popup',
  templateUrl: './view-transactions-popup.component.html',
  styleUrls: ['./view-transactions-popup.component.css']
})
export class ViewTransactionsPopupComponent implements OnDestroy {

  public searchParams: SearchParams;
  public searchStatus: SearchStatus;
  public showSelectOptions: boolean;
  public srcTypeMapping: any = {
    'asntd': 'RECEIPT',
    'picktd': 'PickDetail',
    'trtd': 'RECEIPT',
    'ixdocktd': 'RECEIPT',
    'oxdocktd': 'RECEIPT',
    'adjtd': 'Adjustment',
    'uccadjtd': 'Adjustment',
    'transfertd': 'Transfer',
    'inventoryqctd': 'InventoryQCDetail',
    'jobdettd': 'WOJobInv',
    'kittd': 'Kit'
  };
  public isLoading: boolean = true;
  public searchService: SearchService;
  public notiService: SearchService;
  public dataService: DetailedEntityDataService;
  public resourceService: ResourceService;
  public eventSubject = new Subject<any>();
  public inputOptionsForAppcode = [];
  public inputOptionsForCountry = [];
  passingsearch: SearchService;
  public showtagForms = false;
  public appCode;
  public appId;
  public country;
  public countryId;
  public eventCode;
  public tagId;
  public allRows;
  public allRowsData;
  showTemplateRightItems: boolean;
  showSmsRightItems: boolean;
  public searchCriteria: SearchCriteria;


  constructor(coreServiceRegistry: CoreServiceRegistry, public alertService: AlertService,
    public sharedServiceRegistry: SharedServiceRegistry) {
    this.resourceService = coreServiceRegistry.resourceService;
   
  }



  public getDropDownAppCodeValues() {
    let url;
    url = 'api/tag/AppCode/list';
    this.searchService.getDropDownValues(url)
      .subscribe((resp) => {
        resp.typeList.forEach(element => {
          const obj = {
            'key': element.appId,
            'value': element.appName,
          };
          this.inputOptionsForAppcode.push(obj);
        });
      })

    // get country list

    let urlCountry;
    urlCountry = 'api/tag/Country/list'
    this.searchService.getDropDownValues(urlCountry)
      .subscribe((resp) => {
        resp.typeList.forEach(element => {
          const obj = {
            'key': element.countryId,
            'value': element.countryName,
          };
          this.inputOptionsForCountry.push(obj);
        });
      })
  }

  public EventCode = [
    { id: 1, name: 'POA01' },
    { id: 2, name: 'POA02' },
    { id: 3, name: 'POA03' },
  ];


  public initializeForTag(searchService: any, dataEditor, searchCriteria: any) {
 
    
    this.showSelectOptions = false;
    this.showTemplateRightItems = false;
    this.showtagForms = true;
    this.passingsearch = searchService;
    this.searchParams = new SearchParams();
    this.searchParams.tableId = 'addTagtd';
    this.searchStatus = new SearchStatus();


  this.searchService = this.sharedServiceRegistry
  .createTagSearchService(this.searchParams, this.searchStatus);
  this.notiService = this.sharedServiceRegistry
  .createTagSearchService(this.searchParams, this.searchStatus); 
  this.searchCriteria = searchCriteria;
    this.getDropDownAppCodeValues();
    this.searchService.fetchMetadata()
      .subscribe(() => {
        console.log(this.searchService.inputOptionsForTag, "options for tag------------------------------");
        this.searchService.searchParams.actionButtons = [];
       this.searchService.setSearchCriteria(searchCriteria);
        this.searchService.searchParams.dataTableConfig = new DataTableConfig();
        this.searchService.searchParams.dataTableConfig.editable = true;
        //       this.searchService.searchParams.url = 'assets/json/addTag.json';
        this.searchService.searchParams.dataTableConfig.columnWidth = 290;
        //    this.searchService.searchParams.dataTableConfig.hiddenPropertiesForTable = ['TAG_ID']
        this.searchService.searchParams.tableEditor = dataEditor;
        this.searchService.searchParams.paginateLocally = true;
        this.searchService.searchParams.dataTableConfig.enablePagination = false;
        this.searchService.performSearch();
        this.isLoading = false;
      });
  }

  public initialize(searchService: any, dataEditor, searchCriteria: any) {
    // searchCriteria.conditions[0].clauses.push({
    //   column: 'sourceType',
    //   operation: 'contains',
    //   value: this.srcTypeMapping[datatableId],
    //   logicalOperation: 'AND'
    // });
    // this.searchService = searchService;
    this.showTemplateRightItems = false;
    this.showSelectOptions = true;
    this.passingsearch = searchService;
    this.searchParams = new SearchParams();
    this.searchParams.tableId = 'recipgroupsearchtd';
    this.searchStatus = new SearchStatus();
    // this.searchService.searchParams.actionButtons = [];
    // this.searchService.setSearchCriteria(searchCriteria);
    // this.searchService.searchParams.url = 'api/recipient/details/user';
    this.searchService = this.sharedServiceRegistry
      .createPomsSearchService(this.searchParams, this.searchStatus);
    this.searchService.fetchMetadata()
      .subscribe(() => {
        this.searchService.searchParams.actionButtons = [];
        this.searchService.setSearchCriteria(searchCriteria);
        this.searchService.searchParams.url = 'api/recipient/details/user';
        this.searchService.searchParams.tableEditor = dataEditor;
        this.searchService.performSearch();
        this.isLoading = false;
        //  this.searchService.isNewrows = true;
      });
  }


  public initializeForTemplate(searchService: any, emailOrSms , dataEditor, searchCriteria: any) {
    this.searchService = searchService;
    this.searchService['htmlContent'] ='';
    //this.searchParams = new SearchParams();
   // this.searchParams.tableId = 'searchUserTd';
    //this.searchStatus = new SearchStatus();
    this.searchService.searchParams.idProperty = "TEMPLATE_NAME";
    this.searchService.searchParams.dataTableConfig.showIdAsAHyperLink = true;
    this.searchService.searchParams.dataTableConfig.hiddenPropertiesForTable = ['TEMPLATE_ID', 'TEMPLATE']

    // this.searchService = this.sharedServiceRegistry
    //   .createTemplateSearchService(this.searchParams, this.searchStatus);
    this.searchService.setMetadata(metaDataGrpForEmail);

    if(emailOrSms === 'Email'){
      this.searchService.searchParams.url = 'api/template/details/email';
      this.showTemplateRightItems = true;
    } else {
      this.searchService.searchParams.url = 'api/template/details/sms';
      this.showSmsRightItems = true;
    }

    this.searchService.fetchMetadata()
      .subscribe(() => {
        this.searchService.searchParams.actionButtons = [];
        this.searchService.setSearchCriteria(searchCriteria);
        // this.searchService.searchParams.url = 'api/template/details/email';
        this.searchService.searchParams.tableEditor = dataEditor;
        this.searchService.performSearch();
        this.isLoading = false;
      });
  }

  public fetchSearchTable(){
    if(this.appCode !== undefined && this.country !== undefined && this.eventCode !== undefined){
    this.searchCriteria['conditions'][0]['clauses'].forEach(cond =>{
       if(cond['column'] === "APP_NAME"){
        cond['value'] = this.appCode;
       }else if(cond['column'] === "COUNTRY_NAME"){
        cond['value'] = this.country;
       }else if(cond['column'] === "EVENT_CODE"){
        cond['value'] = this.eventCode;
       }
    })
    this.notiService.setSearchCriteria(this.searchCriteria);
    this.notiService.pagedData.pageDefinition.pageSize = 100;
    this.searchParams.tableId = 'tagsearch';
    this.notiService.performSearch();
    console.log(this.notiService);
  }
  }

  public valueChanged(event){
    this.fetchSearchTable();
    this.searchParams.tableId = 'addTagtd'
    let popupData = [];
    // console.log(this.allRowsData,"Data ===================================");
    this.searchService.performSearch();
    if(this.appCode !== undefined && this.country !== undefined && this.eventCode !== undefined){
     setTimeout(() => {
       this.notiService.pagedData.pageRows.forEach(data=>{
        if(data.cells['APP_NAME'].cVal === this.appCode && data.cells['COUNTRY_NAME'].cVal === this.country
         && data.cells['EVENT_CODE'].cVal === this.eventCode){
          console.log("record is exist");
          this.searchService.pagedData.pageRows.forEach(rowData=>{
            if(rowData.cells['TAG'].cVal === data.cells['TAG'].cVal){
              rowData.cells['TAG_LABEL'].cVal =  data.cells['TAG_LABEL'].cVal;
              rowData.cells['DESCRIPTION'].cVal = data.cells['DESCRIPTION'].cVal;
              rowData.cells['SAMPLE_VALUE'].cVal = data.cells['SAMPLE_VALUE'].cVal;
              rowData.cells['isEdited'] = true;
            }
          })
        }
      })
      const events = new SearchEvent(REFRESH_SEARCH_EVENT);
      events.isSearchSuccess = true;
      this.searchService.publishEvent(events);
      
   }, 400);
 //     this.searchService.setPagedRows(this.searchService.pagedData.pageRows);
    if(this.searchService.displayMode === 'V'){
    this.searchService.setDisplayMode('E');
    }
    }
    console.log(this.searchService.pagedData.pageRows,"after validation ---------");
  }

  public handlePopulateAction() {
    const selectedRows = this.searchService.getSelectedRows();

    if (selectedRows.length === 0) {
      this.alertService.warn('No rows is available to add')
      // this.lookupAlertService.error(this.resourceService.get('msg_sel_detail'));
      return;
    }

    const populateEvent = {
      'type': 'cancel', 'selectedItems': selectedRows
    };
    this.cancelPopup(populateEvent);

  }


  ///////////////////////Save data Tag screen///////////////////
  public handlePopulateActionForTag() {
    console.log(this.searchService.pagedData.pageRows,"page data after save button")
    //this.allRows = this.searchService.getAllRows();
    this.allRows = this.searchService.getPagedData().pageRows.filter((row: any) => {
      return row.isEdited;
    });

    let selectedRowTag = []
    if (this.allRows.length === 0) {
      this.alertService.warn('No rows is available to add')
      // this.lookupAlertService.error(this.resourceService.get('msg_sel_detail'));
      return;
    }

    this.inputOptionsForAppcode.forEach(code => {
      if (code['value'] === this.appCode) {
        this.appId = code['key']
      }
    })
    this.inputOptionsForCountry.forEach(code => {
      if (code['value'] === this.country) {
        this.countryId = code['key']
      }
    })


    this.allRows.forEach((tags, index) => {
        if(!tags.cells.isEdited){
          selectedRowTag.push(this.allRows[index]);
          selectedRowTag[index].cells['isNewRow'] = { cVal: true };
        }else{
         selectedRowTag.push(this.allRows[index]);
        }
    })

    const populateEvent = {
      'type': 'add', 'selectedItems': selectedRowTag, 'appcode': this.appCode, 'country': this.country, 'eventcode': this.eventCode, 'appid': this.appId, 'countryid': this.countryId
    };
    this.cancelPopup(populateEvent);

  }

  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  public receiveEvent(): Observable<any> {
    return this.eventSubject.asObservable();
  }

  public cancelPopup(populateEvent) {

    this.notifyEvent(populateEvent);
  }

  public notifyEvent(value: any) {
    this.eventSubject.next(value);
  }

  public ngOnDestroy() {
    // Memory Cleanup
    this.searchService = null;
  }

  public copyText(){
    this.searchService['copiedText'] = this.searchService['htmlContent'];
  }

  
  htmlContent:any ='';
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '20rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    toolbarHiddenButtons: [
      ['fontName'],
      ['fontSize']
      ],
    defaultFontSize: '5',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

}
