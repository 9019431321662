
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertService, UserService } from 'sce-core';
import { ResourceService } from 'sce-core';
import { ModalService } from './modal.service';
import { SearchableEntityDataService } from './searchable-entity/searchable-entity-data.service';
import { SearchService } from '../search/search.service';

@Injectable()
export class PrinterService {

  public hasAdditionalFields: Boolean = false;
  public printerId: any;
  public parentDataService: SearchableEntityDataService;
  public tableSearchServiceForWaveControl: SearchService;

  constructor(public http$: HttpClient,
    public alertService: AlertService,
    public modalService: ModalService,
    public resourceService: ResourceService,
    public userService: UserService) { }

  /* [LFWM-2027] - documnetType - tableid
  * storer - storerkey value from parent screen
  * facility - facility value from parent screen
  * dataService - dataService of parent screen
  * primaryDataMap - primaryDataMap from parent screen
  * tableSearchService - its a optional parameter - [for wave control screen - tableSearchService is passed from wave control
    screen because which has more than one detail search service and detail table.
  */
  public printReport(documentType: string, storer: string,
      facility: string, primaryDataMap: any, dataService: any, tableSearchService?: any) {
      this.parentDataService = dataService;
      this.tableSearchServiceForWaveControl = tableSearchService;
    let tempList = [];
    const reportDefaultValues = {
      'documentType' : documentType,
      'storer' : storer,
      'facility' : facility,
        };
    this.getModuleReport(reportDefaultValues)
      .subscribe((resp: any) => {
        const dataToRemove: Array<string> = [
          'ExtendedParmDefault1',
          'ExtendedParmDefault2',
          'ExtendedParmDefault3',
          'ExtendedParmDefault4',
          'ExtendedParmDefault5',
          'ReportTitle',
          'NeedExtendedParm'];
      Object.keys(resp)
        .forEach((key: string) => {
          if (dataToRemove.indexOf(key) !== -1) {
            delete resp[key];
          }
        });
      tempList = <any[]>resp;
      if (this.userService.userInfo.defaultPrinter) {
        this.printerId = this.userService.userInfo.defaultPrinter;
        this.invokePrintModal(tempList, reportDefaultValues,
          this.printerId, primaryDataMap, false, null, 'DocReport', null);
      }else {
        this.userService.fetchDefaultPrinter()
          .subscribe((printeresp: any) => {
            this.printerId = printeresp;
            this.invokePrintModal(tempList, reportDefaultValues,
              this.printerId, primaryDataMap, false, null, 'DocReport', null);
          });
      }
    }, (err: any) => {
      this.alertService.clearAll()
        .error(this.resourceService.translateServerResponse(err));
    });
  }

  public printFromViewReportScreen(reportConfig: any,
      rowDataMap: any, dataEditor: any, keyEventHandler: any) {
    if (this.userService.userInfo.defaultPrinter) {
      this.printerId = this.userService.userInfo.defaultPrinter;
      this.invokePrintModal(reportConfig, rowDataMap, this.printerId,
        rowDataMap, true, dataEditor, 'ViewReport', keyEventHandler);
    } else {
      this.userService.fetchDefaultPrinter()
        .subscribe((printeresp: any) => {
          this.printerId = printeresp;
          this.invokePrintModal(reportConfig, rowDataMap, this.printerId,
            rowDataMap, true, dataEditor, 'ViewReport', keyEventHandler);
        });
    }
  }

  public invokePrintModal(reportConfig: any, inputValues: any,
      printerIds: any, primaryDataMap: any, showKeyValueParams: any,
      dataEditor: any, source: any, keyEventHandler: any) {
    this.alertService.clearAll();
    // const modalRef: any = this.modalService
    //   .showModal(PrintLookupComponent,
    //     { overlayHeader: this.resourceService.get('lbl_print_report'),
    //       showHeader: true,
    //       overlayHeight: 520,
    //       overlayWidth: 600
    //     });
    // if (dataEditor) {
    //   modalRef.content.setDataTableEditor(dataEditor, keyEventHandler);
    // }
    // modalRef.content.setInitialData(reportConfig, inputValues,
    //   printerIds, primaryDataMap, showKeyValueParams, source, this.parentDataService, this.tableSearchServiceForWaveControl);
    // if (source === 'ViewReport') {
    //   modalRef.content.printReportReceiveEvent()
    //     .subscribe((requestData: any) => {
    //       this.submitViewReportPrint(requestData)
    //         .subscribe((printeresp: any) => {
    //           if (printeresp.statusCode === 200) {
    //             const successMsg = this.resourceService.get('msg_prnt_succ');
    //             this.alertService.clearAll().success(successMsg);
    //             modalRef.hide();
    //           } else {
    //             this.alertService.clearAll().error(this.resourceService
    //               .translateServerResponse(printeresp.statusMessage));
    //           }
    //         }, (err) => {
    //           this.alertService.clearAll().error(this.resourceService.translateServerResponse(err));
    //         });
    //       });
    // } else {
    //   modalRef.content.printReportReceiveEvent()
    //     .subscribe((requestData: any) => {
    //       this.submitPrintReport(requestData)
    //         .subscribe((printeresp: any) => {
    //           if (printeresp.statusCode === 200) {
    //             const successMsg = this.resourceService.get('msg_prnt_succ');
    //             this.alertService.clearAll().success(successMsg);
    //             modalRef.hide();
    //           } else {
    //             this.alertService.clearAll().error(this.resourceService.translateServerResponse(printeresp.statusMessage));
    //           }
    //         }, (err) => {
    //           this.alertService.clearAll().error(this.resourceService.translateServerResponse(err));
    //         });
    //       });
    // }
  }

  public getModuleReport(reportDefaultValues: any) {
    const request = {
      'documentType': reportDefaultValues.documentType,
      'storerkey': reportDefaultValues.storer,
      'facility': reportDefaultValues.facility,
      'computerName': ''
    };
    const response$ = this.http$.post('api/report/module/report', JSON.stringify(request)).pipe(
    map((response: any) => {
      return response;
    }),
    catchError((error: any) => {
      if (error.error.length !== 0) {
        if (error.error.statusCode !== 200) {
          return observableThrowError(error.error.statusMessage);
        }
      }
    }),);
  return response$;
  }

  public submitPrintReport(printReportInput: any) {
    // LFWM-1811 : Print service url change
    const response$ = this.http$.post('api/report/print', JSON.stringify(printReportInput)).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }),);
    return response$;
  }

  public getViewReportDetails(reportType: any) {
    const request = {
      'moduleID': 'ViewReport',
      'reportID': reportType.trim()
    };
    const response$ = this.http$.post('api/report/viewReport/params', JSON.stringify(request)).pipe(
    map((response: any) => {
      return response;
    }),
    catchError((error: any) => {
      if (error.error.length !== 0) {
        if (error.error.statusCode !== 200) {
          return observableThrowError(error.error.statusMessage);
        }
      }
    }),);
  return response$;
  }
  public submitViewReportPrint(printReportInput) {
    const response$ = this.http$.post('api/report/viewReport/print', JSON.stringify(printReportInput)).pipe(
    map((response: any) => {
      return response;
    }),
    catchError((error: any) => {
      if (error.error.length !== 0) {
        if (error.error.statusCode !== 200) {
          return observableThrowError(error.error.statusMessage);
        }
      }
    }),);
    return response$;
  }

}
