<div class="col_lay_pri_wdgt-content" *ngIf="widgetConfig.isOpen">

    <table class="table col_lay_pri_wdgt-table">

        <tr *ngFor="let property of propertyList">

            <td *ngFor="let key of property">

                <div *ngIf="key !== 'dummy' && metadataMap[key].displayType !== 'H'" (focusin)="keyEventHandler.setFocusedCell(cellDataMap, key)"
                    [ngClass]="{ 'cell-error-col_lay_pri_wdgt': cellDataMap[key].isValueInvalid }">

                    <span class="col_lay_pri_wdgt-cell-title">{{ getCellTitle(key) }}</span>

                    <ng-template [ngIf]="(dataService.displayMode === 'C' || dataService.displayMode === 'E') &&
                                (metadataMap[key].displayType === 'E' || metadataMap[key].displayType === 'EL' || metadataMap[key].displayType === 'L')"
                        [ngIfElse]="defaultCellTemplate">

                        <div class="col_lay_pri_wdgt-cell-edit" [ngSwitch]="metadataMap[key].type">

                            <ng-template [ngSwitchCase]="'TEXTFIELD'">

                                <div [ngSwitch]="metadataMap[key].displayType">

                                    <ng-template [ngSwitchCase]="'E'">
                                        <input type="text" [maxlength]="metadataMap[key].maxLength" [ngModel]="cellDataMap[key].cVal"
                                            (ngModelChange)="valueChanged(key, $event)" (click)="keyEventHandler.setFocusedCell(cellDataMap, key);"
                                            [set-focus]="keyEventHandler.isFocused(cellDataMap, key)">

                                    </ng-template>

                                    <ng-template [ngSwitchCase]="'EL'">
                                        <!-- 
                                            -- reuse of lfwms-lookup-input for auto suggestion- the mandatory inputs are
                                            -- @input [isDetailOrAcn]="'accordion'"
                                            -- @input [tableEditor]="dataEditor"
                                            -- @input [dataModel]="cellDataMap[key]"
                                            -- @input [propertyName]="key"
                                            -- @input [currentRow]="cellDataMap"
                                            -- @input [keyEventHandler]="keyEventHandler"
                                        -->
                                        <lfwms-lookup-input class="lkp-inpt-sugg-prop" [isDetailOrAcn]="'accordion'"
                                            [tableEditor]="dataEditor" [dataModel]="cellDataMap[key]" [propertyName]="key"
                                            [currentRow]="cellDataMap" [keyEventHandler]="keyEventHandler"
                                            (onValueChange)="onValueChangeInTypeaheadTextBox($event)" (keydown)="keyEventHandler.editKeyPressListner($event, cellDataMap, metadataMap[key]);"></lfwms-lookup-input>
                                    </ng-template>

                                    <ng-template [ngSwitchCase]="'L'">
                                        <!-- 
                                            -- reuse of lfwms-lookup-input for auto suggestion- the mandatory inputs are
                                            -- @input isDetailOrAcn
                                            -- @input dataEditor
                                            -- @input cellDataMap[key]
                                            -- @input key
                                            -- @input cellDataMap
                                            -- @input keyEventHandler
                                        -->
                                        <lfwms-lookup-input class="lkp-inpt-sugg-prop" [isDetailOrAcn]="'accordion'"
                                            [tableEditor]="dataEditor" [dataModel]="cellDataMap[key]" [propertyName]="key"
                                            [currentRow]="cellDataMap" [keyEventHandler]="keyEventHandler"
                                            (onValueChange)="onValueChangeInTypeaheadTextBox($event)" (keydown)="keyEventHandler.editKeyPressListner($event, cellDataMap, metadataMap[key]);"></lfwms-lookup-input>
                                    </ng-template>

                                </div>

                            </ng-template>

                            <ng-template [ngSwitchCase]="'DROPDOWN'">

                                <lfwms-dropdown [inputValue]="cellDataMap[key]"
                                    [isDropup]="false" [inputOptions]="filteredMetaData[key].values" (valueChanged)="valueChanged(key, $event);"></lfwms-dropdown>

                            </ng-template>

                            <ng-template [ngSwitchCase]="'EDITABLEDROPDOWN'">

                                <lfwms-dropdown [inputValue]="cellDataMap[key]" [isDropup]="false"
                                    [inputOptions]="filteredMetaData[key].values"
                                    (valueChanged)="valueChanged(key, $event);" [isEditable]='true'></lfwms-dropdown>

                            </ng-template>

                            <!-- Code for checkbox in edit mode starts -->
                            <ng-template [ngSwitchCase]="'CHECKBOX'">
                                <span (click)="toggleSelect(key)">
                                    <ng-template [ngIf]="cellDataMap[key].cVal==='Y' || cellDataMap[key].cVal==='1'">
                                        <i class="fa fa-check-square-o lfwms-checkbox"></i>
                                    </ng-template>
                                    <ng-template [ngIf]="cellDataMap[key].cVal==='N' || cellDataMap[key].cVal==='0'">
                                        <i class="fa fa-square-o lfwms-checkbox"></i>
                                    </ng-template>
                                </span>
                            </ng-template>
                            <ng-template [ngSwitchCase]="'CHECKBOX_CHR'">
                                <span (click)="toggleSelect(key)">
                                    <ng-template [ngIf]="cellDataMap[key].cVal==='Y' || cellDataMap[key].cVal==='1'">
                                        <i class="fa fa-check-square-o lfwms-checkbox"></i>
                                    </ng-template>
                                    <ng-template [ngIf]="cellDataMap[key].cVal==='N' || cellDataMap[key].cVal==='0'">
                                        <i class="fa fa-square-o lfwms-checkbox"></i>
                                    </ng-template>
                                </span>
                            </ng-template>
                            <!-- Code for checkbox in edit mode ends -->

                            <ng-template [ngSwitchCase]="'DATE'">

                                <lfwms-datepicker [(ngModel)]="cellDataMap[key].cVal" (ngModelChange)="valueChanged(key)" title={{dateFormat}}></lfwms-datepicker>
                                </ng-template>
                                
                                <ng-template [ngSwitchCase]="'DATETIME'">
                                
                                    <lfwms-datepicker [(ngModel)]="cellDataMap[key].cVal" (ngModelChange)="valueChanged(key)" title={{dateTimeFormat}}
                                        [showTimePicker]="true"></lfwms-datepicker>
                                
                                </ng-template>

                        </div>

                    </ng-template>

                    <ng-template #defaultCellTemplate>

                        <!-- Code change for displaying  Checkbox starts -->
                        <ng-template [ngIf]="metadataMap[key].type==='CHECKBOX' || metadataMap[key].type==='CHECKBOX_CHR'"
                            [ngIfElse]="elseBlock">
                            <ng-template [ngIf]="cellDataMap[key].cVal==='Y' || cellDataMap[key].cVal==='1'">
                                <i class="fa fa-check-square-o lfwms-checkbox"></i>
                            </ng-template>
                            <ng-template [ngIf]="cellDataMap[key].cVal==='N' || cellDataMap[key].cVal==='0'">
                                <i class="fa fa-square-o lfwms-checkbox"></i>
                            </ng-template>

                        </ng-template>
                        <ng-template #elseBlock>
                            <span class="col_lay_pri_wdgt-cell-view">{{ getCellDisplayValue(key) }}</span>
                        </ng-template>
                        <!-- Code change for displaying  Checkbox ends -->
                    </ng-template>
                </div>
            </td>
        </tr>
    </table>

</div>