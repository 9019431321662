import { Injectable } from '@angular/core';
// import { LookupPopupComponent } from '../../../shared/lookup/popup/lookup-popup.component';
import { ITEM_CLICK_EVENT, ITEM_AUTO_POPULATE } from '../../../shared/search/search-constants';
import { SearchStatus } from '../../../shared/search/models/search-status.model';
import { SearchParams } from '../../../shared/search/models/search-params.model';
import { SearchEvent } from '../../../shared/search/models/search-event';
import { AlertService } from 'sce-core';
import { SearchService } from '../search.service';
import { Condition, Clause, SearchCriteria } from '../models/search-filter-model';
import { ResourceService } from 'sce-core';
import { SharedServiceRegistry } from '../../shared-service-registry';
import { CoreServiceRegistry } from '../../../core/core-service-registry';
// import { MultiColumnPopupComponent } from '../../lookup/multi-column-popup/multi-column-popup.component';

@Injectable()
export class DataTableEditorService {

  public primaryData: any;
  public alertService: AlertService;
  public resourceService: ResourceService;

  public searchValue: string = '';

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    this.resourceService = coreServiceRegistry.resourceService;
    this.alertService = sharedServiceRegistry.alertService;
  }

  public getPrimaryData() {
    return this.primaryData;
  }

  public setPrimaryData(primaryData: any) {
    this.primaryData = primaryData;
  }

  public openLookup(currentRow: any, property: any, primeFocusInput?: any) {
    // const currentRowCells = currentRow.cells;
    // const isValid = this.validateBeforeLookup(currentRowCells, property);
    // if (isValid !== true) {
    //   return;
    // }
    // const currentValue = currentRowCells[property].cVal;
    // const lookupParams = this.createLookupParamsForColumn(property);
    // lookupParams.alertService = this.alertService;
    // const lookupStatus = new SearchStatus();
    // const defaultSearchCriteria: any = this
    //   .createDefaultSearchCriteria(currentRowCells, currentValue, property);
    // const modalRef: any = this.sharedServiceRegistry.sceModalService
    //   .showModal(LookupPopupComponent,
    //     {
    //       overlayHeader: this.getLookupModalTitle(property),
    //       showHeader: true,
    //       overlayHeight: 520,
    //       overlayWidth: 1024
    //     });
    // modalRef.content.defaultSearchCriteria = defaultSearchCriteria;
    // modalRef.content.initializeComponents(lookupParams,
    //   lookupStatus, defaultSearchCriteria, this);
    // modalRef.content.lookupService.receiveEvent()
    //   .subscribe(($event: SearchEvent) => {
    //     if ($event.type === ITEM_CLICK_EVENT) {
    //       this.sharedServiceRegistry.sceModalService.modalReference.hide();
    //       this.handleLookupEvent($event, property, currentValue, currentRowCells);
    //       currentRow.isEdited = true;
    //       // JIRA LFWM-1664 - After reseting the lookup value Cursor control should be at the last updated/clicked text box
    //       if (primeFocusInput) {
    //         setTimeout(() => {
    //           primeFocusInput.nativeElement.focus();
    //           primeFocusInput.nativeElement.select();
    //         }, 5);
    //       }
    //     }
    //   });
  }

  public openMultiColumnPopup(currentRow: any, property: any, primeFocus: any) {
    // const currentRowCells = currentRow.cells;
    // const isValid = this.validateBeforeMultiColumnPopup(currentRowCells, property);
    // if (isValid !== true) {
    //   this.alertService.clearAll().error(this.resourceService.get('loc_id_mandatory'));
    //   return;
    // }
    // const modalRef: any = this.sharedServiceRegistry.sceModalService
    //   .showModal(MultiColumnPopupComponent,
    //     {
    //       overlayHeader: 'Warehouse Order# ' + currentRowCells['orderKey'].cVal,
    //       showHeader: true,
    //       overlayHeight: 520,
    //       overlayWidth: 1024
    //     });
    // modalRef.content.currentRow = currentRow;
    // modalRef.content.property = property;
    // modalRef.content.alertService = this.alertService;
    // modalRef.content.initializeComponents(this);
    // modalRef.content.receiveEvent()
    //   .subscribe((event: any) => {
    //   if (event.type === 'populate') {
    //     this.handleMultiColumnPopupEvent(event, property, currentRow);
    //     this.sharedServiceRegistry.sceModalService.modalReference.hide();
    //     if (primeFocus) {
    //       setTimeout(() => {
    //         primeFocus.nativeElement.focus();
    //         primeFocus.nativeElement.select();
    //       }, 5);
    //     }
    //   }
    //   });
  }

  /**
   * handleMultiColumnPopupEvent
   */
  public handleMultiColumnPopupEvent(event: any , property: string, currentRow: any) {
    const columnKey = Object.keys(event.selectedItems);
      if (columnKey.indexOf('orderLineNo') > -1) {
        currentRow.cells['lot'].cVal = '';
        currentRow.cells['loc'].cVal = '';
        currentRow.cells['id'].cVal = '';
        currentRow.cells['orderLineNo'].cVal = event.selectedItems['orderLineNo'];
        currentRow.cells['orderLineNo'].isEdited = true;
        currentRow.cells['commodity'].cVal = event.selectedItems['commodity'];
        currentRow.cells['commodity'].isEdited = true;
        currentRow.cells['storerKey'].cVal = event.selectedItems['storerKey'];
        currentRow.cells['storerKey'].isEdited = true;
        currentRow.cells['facility'].cVal = event.selectedItems['facility'];
        currentRow.cells['facility'].isEdited = true;
        currentRow.cells['packKey'].cVal = event.selectedItems['packKey'];
        currentRow.cells['packKey'].isEdited = true;
        currentRow.isEdited = true;
      }
      if (columnKey.indexOf('lot') > -1) {
        currentRow.cells['loc'].cVal = '';
        currentRow.cells['loc'].isEdited = true;
        currentRow.cells['id'].cVal = '';
        currentRow.cells['id'].isEdited = true;
        currentRow.cells['lot'].cVal = event.selectedItems['lot'];
        currentRow.cells['lot'].isEdited = true;
        currentRow.isEdited = true;
      }
      if (columnKey.indexOf('loc') > -1) {
        currentRow.cells['id'].cVal = '';
        currentRow.cells['id'].isEdited = true;
        currentRow.cells['loc'].cVal = event.selectedItems['loc'];
        currentRow.cells['loc'].isEdited = true;
        currentRow.isEdited = true;
      }
      if (columnKey.indexOf('id') > -1) {
      currentRow.cells['id'].cVal = event.selectedItems['id'];
      currentRow.cells['id'].isEdited = true;
      currentRow.isEdited = true;
      }
  }

  /**
   * validateBeforeMultiColumnPopup
   */
  public validateBeforeMultiColumnPopup(currentRowCells: any, property: any) {
    if (property === 'id') {
      if (currentRowCells['lot'].cVal || currentRowCells['loc'].cVal) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public getLookupModalTitle(property: any): any {
    return property;
  }

  public autoPopulateLookupProperty(currentRow: any, property: any, keyHandler?: any) {
    const currentRowCells = currentRow.cells;
    const isValid = this.validateBeforeLookup(currentRowCells, property);
    if (isValid !== true) {
      return;
    }
    // set loading spinner for fields if required
    this.refreshAutoPopulatedLookupProperty(currentRow, property, this.getDependantMapForProperty(property)
      , true, false);
    const currentValue = currentRowCells[property].cVal;
    const lookupParams = this.createLookupParamsForColumn(property);
    lookupParams.alertService = this.alertService;
    const lookupStatus = new SearchStatus();
    const searchCriteria = this.createDefaultSearchCriteria(currentRowCells, currentValue, property);
    const idPropertyClause = new Clause();
    idPropertyClause.column = lookupParams.idProperty;
    idPropertyClause.value = currentValue;
    idPropertyClause.operation = '=';
    const itemIdCondition = this.createSearchCondition(2);
    itemIdCondition.clauses = [idPropertyClause];
    searchCriteria.conditions.push(itemIdCondition);

    const lookupService = this.sharedServiceRegistry.createSearchService(lookupParams, lookupStatus);
    // this.lookupStatus = this.lookupService.searchStatus;
    lookupService.getFirstItemForSearchCriteria(lookupService.formatSearchCriteria(searchCriteria, null))
      .subscribe((response: any) => {
        const hasErrors: boolean = !response || response.errors || !response.cells;
        if (hasErrors) {
          const errorResp: string = response.errors ? response.errors : 'err_inv_lookup';
          this.alertService.clearAll().error(this.resourceService.get(errorResp));
        } else {
          const transformedResponse: any = new Object();
          transformedResponse.cells = {};
          response.cells.forEach(cellData => {
            transformedResponse.cells[cellData.cName] = cellData;
          });
          const event = new SearchEvent(ITEM_AUTO_POPULATE);
          event.selectedItemId = currentValue;
          event.selectedProperty = property;
          event.selectedRow = transformedResponse;
          this.handleLookupEvent(event, property, currentValue, currentRowCells);
        }
        this.refreshAutoPopulatedLookupProperty(currentRow, property, this.getDependantMapForProperty(property)
          , false, hasErrors);
        if (keyHandler) {
          keyHandler.focusedProperty = currentRow.focusedCellId;
        }
      });
  }

  public refreshAutoPopulatedLookupProperty(row: any, baseProperty: string,
    propertyMap: any, isStartEvent: boolean, hasError: boolean) {
    // if this is a start event
    let isLoading: boolean = false;
    if (isStartEvent && row.cells[baseProperty].cVal && row.cells[baseProperty].cVal !== '') {
      isLoading = true;
    }
    const dependantProps = Object.keys(propertyMap);
    dependantProps.forEach(property => {
      if (property !== baseProperty) {
        if (isStartEvent) {
          row.cells[property].cValPrev = row.cells[property].cVal;
          row.cells[property].cVal = '';
        }
        row.cells[property].isLoading = isLoading;
      }
    });
    if (!isLoading) {
      row.cells[baseProperty].isValueInvalid = hasError;
      row.focusedCellId = hasError ? baseProperty : this.getNextPropertyToFocus(baseProperty);
    }
  }

  // default implementation
  public getNextPropertyToFocus(property) {
    return null;
  }

  public createSearchCondition(id) {
    const searchCondition = new Condition();
    searchCondition.id = id;
    searchCondition.displayMode = 'VIEW';
    return searchCondition;
  }

  public createDefaultSearchCriteria(currentRow: any, selectedColumnValue: any, property: any) {
    return new SearchCriteria();
  }

  public createLookupParamsForColumn(property: any): SearchParams {
    return new SearchParams();
  }

  public handleLookupEvent(event: SearchEvent, property: any, currentColumnValue: any, currentRowCells: any) {
    if (currentRowCells && currentRowCells[property] && currentRowCells[property].isValueInvalid) {
      currentRowCells[property].isValueInvalid = false;
    }
    const prevVal = currentRowCells[property].cVal;
    const depenedentPropertyMap = this.getDependantMapForProperty(property);
    const currentValue = event.selectedRow.cells[depenedentPropertyMap[property]].cVal;
    this.valueChanged(property, prevVal, currentValue);
    this.setSelectedItemToDependantProperties(property, this.getDependantMapForProperty(property), event.selectedRow, currentRowCells);
  }

  public setSelectedItemToDependantProperties(property: string, dependantProperties: any, selectedItem: any, currentRowCells: any) {
    // const lookupResp = selectedItem.cells;
    const allProps = Object.keys(dependantProperties);
    allProps.forEach(prop => {
      currentRowCells[prop].cValPrev=currentRowCells[prop].cVal ;
      currentRowCells[prop].cVal = selectedItem.cells[dependantProperties[prop]].cVal;
      currentRowCells[prop].isEdited = true;
    });
  }

  public getDependantMapForProperty(property: string) {
    return {};
  }

  public populateRestrictedValues(pageRows: any, colName: any) { }

  public populateAllRestrictedValues(pageRows: any) {
    /* implemenet as below
    either iterate on meatdata and call for all ea columns or
     make call to each RestrictedValue based columns in this case;
     this.populateRestrictedValues(pageRows, 'example-packKey');
     this.populateRestrictedValues(pageRows, 'other columns');
    */
  }

  public autoPopulate(row: any, colName: any) { }

  public validateProperty(value: any, key: any) { }

  public validateBeforeLookup(currentRow: any, property: any): boolean {
    return true;
  }

  // LFWM-963 method used to update the property of the line item with the value required
  public updateLineItemDetails(property: any, value: any) { }
  // Method used to update the header status of adjustment detail on change of line item status
  public updateDetailStatus(key: any) { }

  public getAutoFilter(key, searchValue: string, searchService: SearchService, displayType: string) {
    if (Array.isArray(searchValue)) {
      // searchValue will be in the form of an array for dropdowns
      this.formCriteriaForDropDowns(key, searchValue, searchService);
    } else {
      this.searchValue = searchValue;
      let filterCondition = searchService.getFilterCondition();
      if (!filterCondition) {
        filterCondition = new Condition();
        filterCondition.isReadOnly = true;
        filterCondition.isMandatory = true;
        filterCondition.operator = 'AND';
        // setting ag grid search filters separately
        searchService.setFilterCondition(filterCondition);
      }

      // after removing existing clause, create new one only if the search value is not empty.
      if (searchValue !== '') {
        let keyFound: boolean = false;
        if (filterCondition.clauses.length > 0) {
          filterCondition.clauses.forEach((clause, index) => {
            if (clause['column'] === key) {
              clause.value = searchValue;
              keyFound = true;
              return;
            }
          });
        }
        if (!keyFound) {
          const newClause = new Clause();
          if (displayType === 'DATE') {
            newClause.operation = '=';
          } else {
            newClause.operation = 'like';
          }
          newClause.column = key;
          newClause.value = searchValue;
          if (filterCondition.clauses.length > 0) {
            newClause.logicalOperation = 'AND';
          }
          filterCondition.clauses.push(newClause);
        }
      } else {
        if (filterCondition.clauses.length > 0) {
          filterCondition.clauses.forEach((clause, index) => {
            if (clause['column'] === key) {
              filterCondition.clauses.splice(index, 1);
            }
          });
        }
        /* LFWM 1326 if there is only one clause the logical operation should e null*/
        if (filterCondition.clauses.length === 1) {
          filterCondition.clauses[0].logicalOperation = null;
        }
      }
    }
    searchService.performSearch();
  }

  public removeConditionExist(key: string, searchConditions: any) {
    if (searchConditions && searchConditions.clauses.length > 0) {
      searchConditions.clauses.forEach((clause, index) => {
        if (clause['column'] === key) {
          searchConditions.clauses.splice(index, 1);
        }
      });
    }
    return searchConditions;
  }



  public crateDefaultCriteriaForFilter(selectedColumnValue: any, property: any) {
    const defaultSearchCriteria = new SearchCriteria();
    defaultSearchCriteria.conditions = [];
    defaultSearchCriteria.loadDefaultCriteria = false;
    const defaultCondition = this.createSearchCondition(1);
    defaultCondition.isReadOnly = true;
    defaultCondition.isMandatory = true;
    const defaultClause = new Clause();
    defaultClause.operation = 'like';
    defaultClause.column = property;
    defaultClause.value = selectedColumnValue;
    defaultCondition.clauses = [defaultClause];
    defaultCondition.operator = 'AND';
    defaultSearchCriteria.conditions.push(defaultCondition);
    return defaultSearchCriteria;
  }

  /**
 * method used to emit event when property value using lookup
 */
  public valueChanged(property, prevVal, currentValue) { }

  /*Forms the search criteria for filtering dropdowns. All the keys whose values matched with the
    user entered values will be present in searchValue array*/
  public formCriteriaForDropDowns(key: any, searchValue: any, searchService: any) {
    this.searchValue = searchValue;
    let filterCondition = searchService.getFilterCondition();
    if (searchValue.length > 0) {
      if (!filterCondition) {
        // if filter condition is absent create a new one
        filterCondition = new Condition();
        filterCondition.isReadOnly = true;
        filterCondition.isMandatory = true;
        filterCondition.operator = 'AND';
        searchService.setFilterCondition(filterCondition);
      }
      /*If there are any existing clauses in the filter condition,
      remove all those clauses whose column matches with the key*/
      if (filterCondition.clauses.length > 0) {
        filterCondition.clauses = filterCondition.clauses.filter(function (clause: any) {
          return clause['column'] !== key;
        });
      }
      const newClause = new Clause();
      newClause.operation = 'IN';
      newClause.column = key;
      newClause.value = searchValue.toString();
      if (filterCondition.clauses.length > 0) {
        newClause.logicalOperation = 'AND';
      } else {
        newClause.logicalOperation = null;
      }
      filterCondition.clauses.push(newClause);
    } else {
      if (filterCondition.clauses.length > 0) {
        filterCondition.clauses = filterCondition.clauses.filter(function (clause: any) {
          return clause['column'] !== key;
        });
      }
    }
  }
}
