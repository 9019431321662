import { Injectable } from '@angular/core';
import { SearchService } from '../../../search/search.service';
import { SearchParams } from '../../../search/models/search-params.model';
import { SearchStatus } from '../../../search/models/search-status.model';
import { SharedServiceRegistry } from '../../../shared-service-registry';
import { CoreServiceRegistry } from '../../../../core/core-service-registry';
import { SearchableEntityDataService } from '../searchable-entity-data.service';
import { SearchableEntityDataEditorService } from './../searchable-entity-data-editor.service';
import { SearchableEntityButtonService } from '../searchable-entity-button.service';

@Injectable()
export class DynamicEntityDataService extends SearchableEntityDataService {

  public initializedDynamicContexts: Array<string> = [];

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
  }

  public setDependentServices(dataEditor: SearchableEntityDataEditorService,
    buttonService: SearchableEntityButtonService) {
    this.buttonService = buttonService;
    this.dataEditor = dataEditor;
  }

  public initializeSearchContext(dynamicContext?: string) {
    const entityContext: any = this.entityConfig.getEntityContext(dynamicContext);
    if (entityContext) {
      entityContext['searchStatus'] = new SearchStatus();
      if (entityContext['searchParams']) {
        entityContext['searchParams'].alertService = this.alertService;
        entityContext['searchService'] = this.sharedServiceRegistry
          .createSearchService(entityContext['searchParams'], entityContext['searchStatus']);
        if (this.buttonService) {
          entityContext['searchParams'].actionButtons = this.buttonService.getButtonsForPanel('');
        }
      }
    }
  }

  public getSearchParams(dynamicContext?: string): SearchParams {
    const entityContext: any = this.entityConfig.getEntityContextObject()[dynamicContext];
    return (entityContext) ? entityContext['searchParams'] : undefined;
  }

  public getSearchService(dynamicContext?: string): SearchService {
    const entityContext: any = this.entityConfig.getEntityContextObject()[dynamicContext];
    return (entityContext) ? entityContext['searchService'] : undefined;
  }

  public getSearchStatus(dynamicContext?: string): SearchStatus {
    const entityContext: any = this.entityConfig.getEntityContextObject()[dynamicContext];
    return (entityContext) ? entityContext['searchStatus'] : undefined;
  }

  public setDisplayMode(displayMode: string, dynamicContext?: string) {
    const entityContext: any = this.entityConfig.getEntityContextObject()[dynamicContext];
    if (entityContext['searchService']) {
      entityContext['searchService'].setDisplayMode(displayMode);
    }
  }

  public getAllLineItems(dynamicContext?: string): Array<any> | undefined {
    const entityContext: any = this.entityConfig.getEntityContextObject()[dynamicContext];
    if (entityContext['searchService']) {
      if (entityContext['searchService'].getPagedData().pageRows.length === 0) {
        return [];
      } else {
        const allLineItems: Array<any> = [];
        entityContext['searchService'].getPagedData().pageRows
          .map((lineItemRow: any, index: number) => {
            const lineItem = [];
            const lineItemCells = lineItemRow.cells;
            Object.keys(lineItemCells)
              .forEach((lineItemProperty: string) => {
                lineItem.push({ cName: lineItemProperty,
                                cVal: lineItemCells[lineItemProperty].cVal,
                                cValOrig: lineItemCells[lineItemProperty].cValOrig });
              });
            allLineItems.push({ rIdx: index, cells: lineItem });
          });
        return allLineItems;
      }
    }
  }

  public getModifiedLineItems(dynamicContext?: string): Array<any> {
    const entityContext: any = this.entityConfig.getEntityContextObject()[dynamicContext];
    const editedRows = entityContext['searchService'].getPagedData().pageRows
      .filter((row: any) => {
        return row.isEdited;
      });
    return editedRows;
  }
  public  shallowCopyDataService(targetDataService: DynamicEntityDataService) {
    if (!targetDataService) {
      return;
    }
    super.shallowCopyDataService(targetDataService);
  }
}
