export class Metadata {
    public moduleId: string;
    public elementId: string;
    public columnList: ColumnMetaData[];
    public defaultOperator: string;
}

export class ColumnMetaData {
    public id: string;
    public propertyName: string;
    public displayValue: string;
    public elementId: string;
    public type: string;
    public displayType: string;
    public defaultVal: any;
    public isMandatory: boolean;
    public defaultOp: string;
    public maxLength: number;
    public index: number;
    public priority: string;
    public toolTip: string;
    public values: any;
    public columnGroup: string;
    public dataType: string;
    public dashboardParam: string;
}

export class SearchCriteria {
    public conditions: Condition[] = [];
    public loadDefaultCriteria: boolean = true;
}

export class Condition {
    public id: number;
    public isMandatory: boolean;
    public clauses: Clause[] = [];
    public operator: string;
    public displayMode: string;
    public isReadOnly: boolean;
}

export class Clause {
    public id: string;
    public isMandatory : boolean;
    public logicalOperation: string;
    public column: string;
    public operation: string;
    public value: any;
    // adding the property value1 for between operator
    public value1: any;
    public isValid: boolean = true;
    public validationErrMsg: string;
    // formattedDateValue and formattedDateValue1 is used to display the date values in country specific format [display purpose only]
    public formattedDateValue: string;
    public formattedDateValue1: string;
    public dashboardParam: string;
    public typeOfField: string;
}

export class statusData {
    public statusList: statusobj[];
   
}
export class statusobj {
    public number: number;
    public statusCode: string
}
