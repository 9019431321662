import { Injectable } from '@angular/core';
import { SearchableEntityDataService } from './searchable-entity/searchable-entity-data.service';


@Injectable()
export class CrossFunctionalityNavigationService {

  public sourceDataService: SearchableEntityDataService;

  public hasUnsavedChanges: boolean;

  public clearSourceDataService() {
    this.sourceDataService = null;
  }
  // method used to copy back the prev state of so while navigating from pm to so
  public copyFromSourceDataService(targetDataService: SearchableEntityDataService) {
    if (this.sourceDataService && targetDataService) {
      this.sourceDataService.shallowCopyDataService(targetDataService);
    }
  }
}
