<div [ngSwitch]="templateId" class="header-container">
    <ng-template [ngSwitchCase]="0">
        <div class="header-content">
            <span class="header-text">
                {{ params.column.colDef.headerName }}
            </span>
            <span class="pull-right header-icons">
                <ng-template [ngIf]="params.context.componentParent.pageDefinition.sortColumn === params.column.colId"
                    [ngIfElse]="unsorted">
                    <span class="icon-sort" [ngSwitch]="params.context.componentParent.pageDefinition.sortOrder">
                        <ng-template [ngSwitchCase]="0">
                            <span (click)="params.context.componentParent.
                            toggleColumnSort(params.column.colId)">
                                <i class="fa fa-sort-amount-asc" aria-hidden="true"></i>
                            </span>
                        </ng-template>
                        <ng-template [ngSwitchCase]="1">
                            <span (click)="params.context.componentParent.toggleColumnSort(params.column.colId)">
                                <i class="fa fa-sort-amount-desc" aria-hidden="true"></i>
                            </span>
                        </ng-template>
                    </span>
                </ng-template>
                <ng-template #unsorted>
                    <span class="icon-sort">
                        <span (click)="params.context.componentParent.toggleColumnSort(params.column.colId)">
                            <i class="fa fa-sort" aria-hidden="true"></i>
                        </span>
                    </span>
                </ng-template>
                <span *ngIf="params.context.componentParent.tableConfig.enablePing" class="icon-misc" (dblclick)="$event.stopPropagation(); $event.preventDefault();">
                    <img class="lf-svg"
                        src="{{ 'assets/images/svg/' + (this.params.column.pinned ? 'icon-pin.svg' : 'icon-unpin.svg') }}"
                        (click)="params.context.componentParent.toggleColumnPinning(params.column.colId, params.column.pinned);" />
                </span>
            </span>
        </div>
        <ng-template [ngIf]="params.context.componentParent.tableMode !== 'C' && params.context.componentParent.tableConfig.enableFilter">
            <div class="header-filter">
                <ng-template [ngIf]="params.column.colDef.cellRendererParams.cellMetaData.type!=='DATE'" 
                [ngIfElse]="elseBlock">
                    <ng-template [ngIf]="showTypeAhead && (params.column.colDef.cellRendererParams.cellMetaData.displayType === 'L' ||
                    params.column.colDef.cellRendererParams.cellMetaData.displayType === 'EL' ||
                    params.column.colDef.cellRendererParams.cellMetaData.displayType === 'ET' ||
                    params.column.colDef.cellRendererParams.cellMetaData.displayType === 'EA')"
                        [ngIfElse]="elseBlock">
                        <!-- 
                                            -- reuse of lfwms-lookup-input for auto suggestion- the mandatory inputs are
                                            -- @input [isDetailOrAcn]="'accordion'"
                                            -- @input [tableEditor]="dataEditor"
                                            -- @input [dataModel]="cellDataMap[key]"
                                            -- @input [propertyName]="key"
                                            -- @input [currentRow]="cellDataMap"
                                            -- @input [keyEventHandler]="keyEventHandler"
                                        -->
                                  
                       <lfwms-lookup-input class="lkp-inpt-sugg-prop" [isDetailOrAcn]="'column-filter'"
                            [tableEditor]="dataEditor" [dataModel]="params.column.colDef.cellRendererParams.cellMetaData"
                            [propertyName]="params.column.colDef.cellRendererParams.cellMetaData.propertyName"
                            [currentRow]="[]" [keyEventHandler]="keyEventHandler" [showLookupIcon]="false"
                            (onColumnFilter)="filterdFromTypeAheadSuggestion=true; handleSuggestionSelected($event)"
                            (keydown)=onValueChange($event)
                            [disableField]="this.params.context.componentParent.tableMode==='E' ? 'true' : false"></lfwms-lookup-input>
</ng-template>
                    <ng-template #elseBlock>
                        <input type="text" #input class="input-text" tooltipPosition="right" (focus)="onFilterFocus()"
                            (keydown.tab)="onFilterTab()" [ngClass]="(params.column.colDef.cellRendererParams.cellMetaData.isValid === undefined
                            || params.column.colDef.cellRendererParams.cellMetaData.isValid) ?
                                'form-control input-row' : 'form-control input-row cell-error'"
                            pTooltip="{{params.column.colDef.cellRendererParams.cellMetaData.toolTip}}" [(ngModel)]="text"
                            (change)="onChange()" name="value" [value]="text" >
                    </ng-template>
                </ng-template>
                <ng-template #elseBlock>
                    <lfwms-datepicker [(ngModel)]="text" (ngModelChange)="onChange()" (focus)="onFilterFocus()"
                        (tab)="onFilterTab()" pTooltip="{{params.column.colDef.cellRendererParams.cellMetaData.toolTip}}"
                        tooltipPosition="right" [allowBlankDate]='true' [yOffset]='19' [disabled]="this.params.context.componentParent.tableMode==='E' ? true : false"></lfwms-datepicker>
                    <!-- yoffset 19 - to show as dropdown irrespective of scroll position -->
                </ng-template>
            </div>
        </ng-template>
    </ng-template>
    <!-- <ng-template [ngSwitchCase]="1">
        <span class="pull-left" *ngIf="params.context.componentParent.displayCustomizer">
            <img class="lf-svg lf-customizer" src="assets/images/svg/icon-customize_white.svg" (click)="params.context.componentParent.showCustomizerPanel()" />
        </span>
        <span [ngSwitch]="params.context.componentParent.selectedRowCount">
            <ng-template [ngSwitchCase]="-1">
                <span>
                    <i class="fa fa-square-o lfwms-hdr-checkbox disabled fa-disabled"></i>
                </span>
            </ng-template>
            <ng-template [ngSwitchCase]="0">
                <span (click)="params.context.componentParent.toggleSelectAll()">
                    <i class="fa fa-square-o lfwms-hdr-checkbox" [ngClass]="{ 'fa-disabled': !params.context.componentParent.showSelectOption }"></i>
                </span>
            </ng-template>
            <ng-template [ngSwitchCase]="params.context.componentParent.rowsData.length">
                <ng-template [ngIf]="params.context.componentParent.rowsData.length !== 0">
                    <span (click)="params.context.componentParent.toggleSelectAll()">
                        <i class="fa fa-check-square-o lfwms-hdr-checkbox"></i>
                    </span>
                </ng-template>
            </ng-template>
            <ng-template ngSwitchDefault>
                <span (click)="params.context.componentParent.toggleSelectAll()">
                    <i class="fa fa-minus-square-o lfwms-hdr-checkbox"></i>
                </span>
            </ng-template>
        </span>
    </ng-template> -->
</div>