<!-- <lfwms-breadcrumb>
</lfwms-breadcrumb> -->
<div class="detailContainer">
    <div id="mapCard">
        <img class="zoomInOut" src="assets/images/svg/fleets/icon_map_zoom_out.svg" (click)="goToList()">
        <div class="zoomSettingBox">
            <img class="zoomSetting" src="assets/images/svg/fleets/icon_map_setting.svg" (click)="handleSeetings()">
            <div *ngIf="showSeetings" id="settingModal" >
                <div>Geofences
                    <p-inputSwitch disabled="true"  class="p-inputSwitch-lg"></p-inputSwitch>
                </div>
                <div>Traffic Layer
                    <p-inputSwitch disabled="true"  class="p-inputSwitch-lg"></p-inputSwitch>
                </div>
                <div>Clustering
                    <p-inputSwitch disabled="true"  class="p-inputSwitch-lg"></p-inputSwitch>
                </div>
                <div>Vehicles
                    <p-inputSwitch
                    [(ngModel)]="isVehiclesSwitchChecked"
                    (onChange)="handleVehiclesSwitchChange($event)" 
                    class="p-inputSwitch-lg">
                    </p-inputSwitch>
                </div>
            </div>
        </div>
        <div id="vehicleModal">
		<!-- <img class="zoomInOut" *ngIf="!zoomInOutFlag1" src="assets/images/svg/fleets/icon_map_zoom_in.svg" (click)="handleViewMap()"> -->
            <div class="vehicleTitle">Vehicle
                <img src="assets/images/svg/fleets/icon_truck-front.svg" style="margin-left: 5px;width: 22px;">
                <span class="vehical">{{this.params.totalShipment}}<img src="assets/images/svg/fleets/icon_truck-side.svg"></span>
            </div>
            <div class="normalDrop">
            <p-dropdown 
                [showClear]="true"
                placeholder="Truck Id"
                [options]="params.equipmentAll"  
                optionLabel="truckNumber" 
                [filter]="true" 
                optionValue="truckXid"  
                [(ngModel)]="selectedTruckNumber"
                (onChange)="onTruckSelected($event)"
                >
            </p-dropdown>
        </div>
        <div *ngIf="contentPartFlag" class="address">{{detailModal.location}}</div>
			<div *ngIf="contentPartFlag" class="contentPart">
			<div class="vehicleHead">{{detailModal.formattedDate}}</div>
				<p>
					<span class="contentPartTitle">Via</span>
					<span class="contentPartContent">{{detailModal.location}}</span>
				</p>
				<p>
					<span class="contentPartTitle">Travel Duration</span>
					<span class="contentPartContent">{{detailModal.arrivalStopTravelDuration + 'mins'}} </span>
				</p>
				<p>
					<span class="contentPartTitle">ETA</span>
					<span class="contentPartContent">{{detailModal.formatedETA}}</span>
				</p>
				<p>
					<span class="contentPartTitle">Distance </span>
					<span class="contentPartContent">{{detailModal.arrivalStopDistance + 'km'}}</span>
				</p>
			</div>
		</div>
        
        <div class="map" id="fleetMap"></div>
    </div>
    
</div>
