
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from 'sce-core';

@Injectable()
export class HomeService {

  constructor(public http: HttpClient,
    public resourceService: ResourceService) { }

  public fetchRssFeed(): Observable<any> {
   // const url: string = 'api/feed/1/link';
    const url: string = 'assets/public/link.json'
    console.log(this.http);
    
    const response$: any = this.http.get(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        return observableThrowError(this.resourceService.get('server_err_pop_list')
          + this.resourceService.get(error.message));
      }),);
    return response$;
  }

}
