import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './home/header/header.component';
//import { LoginComponent } from 'sce-core';
import { FooterComponent } from './home/footer/footer.component';
//import { MenuComponent } from 'sce-core';
//import { BreadcrumbComponent } from './home/breadcrumb/breadcrumb.component';
import { ProfileComponent } from './home/profile/profile.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { HomeService } from './home/home.service';
import { LeftPanelContentsComponent } from './home/left-panel-contents/left-panel-contents.component';
import { LeftPanelService } from './home/services/left-panel.service';
import { UserPreferenceService } from './home/services/user-preference.service';
import { TooltipModule } from 'primeng-lts/tooltip';
import { DialogModule } from 'primeng-lts/dialog';
//import { SCEModalComponent} from 'sce-core';
import { SceCoreModule } from 'sce-core';
import {SelectButtonModule} from 'primeng/selectbutton';
import {ButtonModule} from 'primeng/button';
import { SCEIframeComponent } from "../shared/components/iframe/iframe.component";

@NgModule({
    declarations: [
        HomeComponent,
        HeaderComponent,
        // LoginComponent,
        FooterComponent,
        // MenuComponent,
       // BreadcrumbComponent,
        ProfileComponent,
        LeftPanelContentsComponent,
        SCEIframeComponent
    ],
    providers: [
        HomeService,
        UserPreferenceService,
        LeftPanelService
    ],
    entryComponents: [
    //SCEModalComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DialogModule,
        SharedModule,
        TooltipModule,
        SceCoreModule,
        SelectButtonModule,
        ButtonModule,
        
    ]
})
export class LayoutModule { }
