export const FETCH_DASHBOARD_DETAILS = {
    'SG': {code: 'SG', id: 2223, notificationId: '8', moduleId: 'scheduler Group', pageTitle: 'Scheduler', tableId: 'schedulersearchtd'},
    'OD': {code: 'OD', id: 2225, notificationId: '8', moduleId: 'NotificationScheduler', pageTitle: 'mn_operation_notification', tableId: 'schedulersearchtd'}
};

export const INITIALBREADCRUMRG = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'setup', displayValue: 'Setup', route: undefined },
    { id: 'notification', displayValue: 'Notification', route: undefined },
    { id: 'scheduler-group', displayValue: 'scheduler Group', route: '/home/notification/notification-scheduler' }
]

export const INITIALBREADCRUMPOD = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'global_control', displayValue: 'Global Control', route: undefined },
    { id: 'perf_mgmt', displayValue: 'Performance scheduler', route: undefined },
    { id: 'operation_notification', displayValue: 'Operation NotificationScheduler', route: '/home/global-control/operation-notification' }
]

export const INITIALBREADCRUMSCHEDULER = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'setup', displayValue: 'Setup', route: undefined },
    { id: 'notification', displayValue: 'Notification', route: undefined },
    { id: 'scheduler', displayValue: 'Scheduler', route: '/home/notification/notification-scheduler' }

]

export const metaDataGrpDetail = {
    'columnList': [
        {
            'id': '1000',
            'elementId': 'SCHEDULER_NAME',
            'propertyName': 'SCHEDULER_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'schedulerName',
            'moduleId': 'schedulerId'
        },
        {
            'id': '1001',
            'elementId': 'APP_NAME',
            'propertyName': 'APP_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'appName',
            'moduleId': 'GroupNameTd'
        },
        {
            'id': '1002',
            'elementId': 'COUNTRY_NAME',
            'propertyName': 'COUNTRY_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 60,
            'index': 4,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'countryName'
        },
        {
            'id': '1003',
            'elementId': 'Description',
            'propertyName': 'DESCRIPTION',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 100,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'description'
        },
        {
            'id': '1004',
            'elementId': 'COUNTRY_ID',
            'propertyName': 'COUNTRY_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'countryId'
        },
        {
            'id': '1005',
            'elementId': 'REAL_TIME',
            'propertyName': 'REAL_TIME',
            'type': 'MULTIDROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [{ 'key': '', 'value': '-Select-' }, { 'key': 'Y', 'value': 'Yes' }, { 'key': 'N', 'value': 'No' }],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'realTime'
        },
        {
            'id': '1006',
            'elementId': 'TIME_ZONE',
            'propertyName': 'TIME_ZONE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'timeZone'
        },
        {
            'id': '1007',
            'elementId': 'START_DATETIME',
            'propertyName': 'START_DATETIME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'startDateTime'
        },
        {
            'id': '1008',
            'elementId': 'END_DATETIME',
            'propertyName': 'END_DATETIME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'endDateTime'
        },
        {
            'id': '1009',
            'elementId': 'CREATED_BY',
            'propertyName': 'CREATED_BY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'createdBy'
        },
        {
            'id': '1010',
            'elementId': 'CREATED_DATE',
            'propertyName': 'CREATED_DATE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'createdDate'
        },
        {
            'id': '1011',
            'elementId': 'LAST_UPDATED_BY',
            'propertyName': 'LAST_UPDATED_BY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'lastUpdatedBy'
        },
        {
            'id': '1012',
            'elementId': 'LAST_UPDATED_DATE',
            'propertyName': 'LAST_UPDATED_DATE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'lastUpdatedDate'
        },
        {
            'id': '1013',
            'elementId': 'FREQUENCY',
            'propertyName': 'FREQUENCY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'frequency'
        },
        {
            'id': '1014',
            'elementId': 'SCHEDULER_ACTIVE',
            'propertyName': 'SCHEDULER_ACTIVE',
            'type': 'MULTIDROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [{ 'key': '', 'value': '-Select-' }, { 'key': 'Y', 'value': 'Yes' }, { 'key': 'N', 'value': 'No' }],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'schedulerActive'
        },
        {
            'id': '1015',
            'elementId': 'SCHEDULER_ID',
            'propertyName': 'SCHEDULER_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'schedulerId'
        },
        // {
        //     'id': '1016',
        //     'elementId': 'RECURS',
        //     'propertyName': 'RECURS',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'recurs'
        // },
        // {
        //     'id': '1017',
        //     'elementId': 'OCCURS_ONCE_START_TIME',
        //     'propertyName': 'OCCURS_ONCE_START_TIME',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'occursOnceStartTime'
        // },
        // {
        //     'id': '1018',
        //     'elementId': 'OCCURS_EVERY',
        //     'propertyName': 'OCCURS_EVERY',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'occursEvery'
        // },
        // {
        //     'id': '1019',
        //     'elementId': 'FREQUENCY',
        //     'propertyName': 'FREQUENCY',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'frequencyDaily'
        // },
        // {
        //     'id': '1015',
        //     'elementId': 'SCHEDULER_ACTIVE',
        //     'propertyName': 'SCHEDULER_ACTIVE',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'schedulerId'
        // },
        // {
        //     'id': '1015',
        //     'elementId': 'SCHEDULER_ACTIVE',
        //     'propertyName': 'SCHEDULER_ACTIVE',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'schedulerId'
        // },
        // {
        //     'id': '1015',
        //     'elementId': 'SCHEDULER_ACTIVE',
        //     'propertyName': 'SCHEDULER_ACTIVE',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'schedulerId'
        // },
        // {
        //     'id': '1015',
        //     'elementId': 'SCHEDULER_ACTIVE',
        //     'propertyName': 'SCHEDULER_ACTIVE',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'schedulerId'
        // },
        // {
        //     'id': '1015',
        //     'elementId': 'SCHEDULER_ACTIVE',
        //     'propertyName': 'SCHEDULER_ACTIVE',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'schedulerId'
        // },
        // {
        //     'id': '1015',
        //     'elementId': 'SCHEDULER_ACTIVE',
        //     'propertyName': 'SCHEDULER_ACTIVE',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'schedulerId'
        // },
        // {
        //     'id': '1015',
        //     'elementId': 'SCHEDULER_ACTIVE',
        //     'propertyName': 'SCHEDULER_ACTIVE',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'schedulerId'
        // },
        // {
        //     'id': '1015',
        //     'elementId': 'SCHEDULER_ACTIVE',
        //     'propertyName': 'SCHEDULER_ACTIVE',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'V',
        //     'dataType': 'String',
        //     'cellDataName': 'schedulerId'
        // },

       
    ]
    }

export const metaDataGrp = {
  'elementId': 'SCHEDULER_TB',  
  'columnList': [
    {
        'id': '1000',
        'elementId': 'SCHEDULER_NAME',
        'propertyName': 'SCHEDULER_NAME',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 30,
        'index': 58,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'E',
        'dataType': 'String',
        'cellDataName': 'schedulerName',
        'moduleId': 'schedulerId'
    },
    {
        'id': '1001',
        'elementId': 'APP_NAME',
        'propertyName': 'APP_NAME',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 58,
        'priority': 'HIGH',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'E',
        'dataType': 'String',
        'cellDataName': 'appName',
        'moduleId': 'GroupNameTd'
    },
    {
        'id': '1002',
        'elementId': 'COUNTRY_NAME',
        'propertyName': 'COUNTRY_NAME',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 60,
        'index': 4,
        'priority': 'HIGH',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'dropDownId':'',
        'displayType': 'E',
        'dataType': 'String',
        'cellDataName': 'countryName'
    },
    {
        'id': '1003',
        'elementId': 'Description',
        'propertyName': 'DESCRIPTION',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 100,
        'index': 6,
        'priority': 'LOW',
        'toolTip': null,
        'columnGroup': 'Primary',
        'displayType': 'E',
        'dataType': 'String',
        'cellDataName': 'description'
    },
    {
        'id': '1004',
        'elementId': 'COUNTRY_ID',
        'propertyName': 'COUNTRY_ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'countryId'
    },
    {
        'id': '1005',
        'elementId': 'REAL_TIME',
        'propertyName': 'REAL_TIME',
        'type': 'MULTIDROPDOWN',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [{ 'key': '', 'value': '-Select-' }, { 'key': 'Y', 'value': 'Yes' }, { 'key': 'N', 'value': 'No' }],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'realTime'
    },
    {
        'id': '1006',
        'elementId': 'TIME_ZONE',
        'propertyName': 'TIME_ZONE',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'timeZone'
    },
    {
        'id': '1007',
        'elementId': 'START_DATETIME',
        'propertyName': 'START_DATETIME',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'startDateTime'
    },
    {
        'id': '1008',
        'elementId': 'END_DATETIME',
        'propertyName': 'END_DATETIME',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'endDateTime'
    },
    {
        'id': '1009',
        'elementId': 'CREATED_BY',
        'propertyName': 'CREATED_BY',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'createdBy'
    },
    {
        'id': '1010',
        'elementId': 'CREATED_DATE',
        'propertyName': 'CREATED_DATE',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'createdDate'
    },
    {
        'id': '1011',
        'elementId': 'LAST_UPDATED_BY',
        'propertyName': 'LAST_UPDATED_BY',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lastUpdatedBy'
    },
    {
        'id': '1012',
        'elementId': 'LAST_UPDATED_DATE',
        'propertyName': 'LAST_UPDATED_DATE',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lastUpdatedDate'
    },
    {
        'id': '1013',
        'elementId': 'FREQUENCY',
        'propertyName': 'FREQUENCY',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'frequency'
    },
    {
        'id': '1014',
        'elementId': 'SCHEDULER_ACTIVE',
        'propertyName': 'SCHEDULER_ACTIVE',
        'type': 'MULTIDROPDOWN',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [{ 'key': '', 'value': '-Select-' }, { 'key': 'Y', 'value': 'Yes' }, { 'key': 'N', 'value': 'No' }],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'schedulerActive'
    },
    {
        'id': '1015',
        'elementId': 'SCHEDULER_ID',
        'propertyName': 'SCHEDULER_ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'schedulerId'
    },
  ]
}

// export const metaDataGrpTable = {
//     'columnList': [
//         {
//             'id': '1000',
//             'elementId': 'Group Id',
//             'propertyName': 'GROUP_ID',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': false,
//             'maxLength': 30,
//             'index': 58,
//             'priority': 'LOW',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'H',
//             'dataType': 'String',
//             'cellDataName': 'groupId',
//         },
//         {
//             'id': '1001',
//             'elementId': 'No',
//             'propertyName': 'No',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': true,
//             'maxLength': 30,
//             'index': 58,
//             'priority': 'HIGH',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'H',
//             'dataType': 'String',
//             'cellDataName': 'no',
//         },
//         {
//             'id': '1002',
//             'elementId': 'Type',
//             'propertyName': 'USER_TYPE',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': true,
//             'maxLength': 60,
//             'index': 4,
//             'priority': 'HIGH',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'V',
//             'dataType': 'String',
//             'cellDataName': 'userType'
//         },
//         {
//             'id': '1003',
//             'elementId': 'Name',
//             'propertyName': 'USER_NAME',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': false,
//             'maxLength': 20,
//             'index': 6,
//             'priority': 'LOW',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'V',
//             'dataType': 'String',
//             'cellDataName': 'userName'
//         },
//         {
//             'id': '1004',
//             'elementId': 'Email',
//             'propertyName': 'EMAIL',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': false,
//             'maxLength': 20,
//             'index': 45,
//             'priority': 'LOW',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'V',
//             'dataType': 'String',
//             'cellDataName': 'email'
//         },
//         {
//             'id': '1005',
//             'elementId': 'Phone Number',
//             'propertyName': 'PHONE',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': true,
//             'maxLength': 30,
//             'index': 58,
//             'priority': 'HIGH',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'V',
//             'dataType': 'String',
//             'cellDataName': 'phoneNumber',
//         },
//         {
//             'id': '1006',
//             'elementId': 'WhatsApp',
//             'propertyName': 'WHATSAPP',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': true,
//             'maxLength': 60,
//             'index': 4,
//             'priority': 'HIGH',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'V',
//             'dataType': 'String',
//             'cellDataName': 'whatsApp'
//         },
//         {
//             'id': '1007',
//             'elementId': 'WeChat',
//             'propertyName': 'WECHAT',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': false,
//             'maxLength': 20,
//             'index': 6,
//             'priority': 'LOW',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'V',
//             'dataType': 'String',
//             'cellDataName': 'weChat'
//         },
//         {
//             'id': '1008',
//             'elementId': 'Company',
//             'propertyName': 'COMPANY',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': false,
//             'maxLength': 20,
//             'index': 6,
//             'priority': 'LOW',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'V',
//             'dataType': 'String',
//             'cellDataName': 'company'
//         },
//         {
//             'id': '1009',
//             'elementId': 'userId',
//             'propertyName': 'USER_ID',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': false,
//             'maxLength': 20,
//             'index': 6,
//             'priority': 'LOW',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'H',
//             'dataType': 'String',
//             'cellDataName': 'userId'
//         },
//         {
//             'id': '1010',
//             'elementId': 'Line',
//             'propertyName': 'LINE',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': false,
//             'maxLength': 20,
//             'index': 6,
//             'priority': 'LOW',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'V',
//             'dataType': 'String',
//             'cellDataName': 'line'
//         },
//         {
//             'id': '1010',
//             'elementId': 'Telegram',
//             'propertyName': 'TELEGRAM',
//             'type': 'TEXTFIELD',
//             'defaultVal': '',
//             'defaultOp': '=',
//             'isMandatory': false,
//             'maxLength': 20,
//             'index': 6,
//             'priority': 'LOW',
//             'toolTip': null,
//             'values': [],
//             'columnGroup': 'Primary',
//             'displayType': 'V',
//             'dataType': 'String',
//             'cellDataName': 'telegram'
//         },
//         // {
//         //     'id': '1004',
//         //     'elementId': 'Email',
//         //     'propertyName': 'Email',
//         //     'type': 'TEXTFIELD',
//         //     'defaultVal': '',
//         //     'defaultOp': '=',
//         //     'isMandatory': false,
//         //     'maxLength': 20,
//         //     'index': 45,
//         //     'priority': 'LOW',
//         //     'toolTip': null,
//         //     'values': [],
//         //     'columnGroup': 'Primary',
//         //     'displayType': 'E',
//         //     'dataType': 'String',
//         //     'cellDataName': 'Email'
//         // },
       
//     ]
//     }
