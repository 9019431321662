import { Injectable } from '@angular/core';
import { SearchService } from '../../../search/search.service';
import { SearchParams } from '../../../search/models/search-params.model';
import { SearchStatus } from '../../../search/models/search-status.model';
import { SearchEvent } from '../../../search/models/search-event';
import { NEW_SEARCH_EVENT, REFRESH_SEARCH_EVENT } from '../../../search/search-constants';
import { SharedServiceRegistry } from '../../../shared-service-registry';
import { CoreServiceRegistry } from '../../../../core/core-service-registry';
import { PageDefinition } from '../../../search/models/page-definition.model';
import { SearchableEntityDataService } from '../searchable-entity-data.service';
import { DetailedEntityButtonService } from './detailed-entity-button.service';
import { DocumentUtilService } from '../../document-util.service';
import { DataTableConfig } from '../../../search/models/data-table-config';

@Injectable()
export class DetailedEntityDataService extends SearchableEntityDataService {

  public buttonService: DetailedEntityButtonService;

  public primarySearchService: SearchService;
  public primarySearchParams: SearchParams;
  public primarySearchStatus: SearchStatus;

  public docType: string;

  // This will store all the properties and their values of the primary entity as a map
  public primaryDataMap: any;

  // This will store the primary metadata as a map with the property name as the key
  public primaryMetaDataMap: any;

  // This will store the columns for each group as a map
  public primaryColumnGrouping: any;

  public lookupMap: any = {};

  public primarySelectedDocument: string;

  public detailsSearchService: SearchService;
  public detailsSearchParams: SearchParams;
  public detailsSearchStatus: SearchStatus;

  // maximum id value for the detail table
  public maxIdValueForDetailTable: string;

  /* LFWM-1054 Flag used to distinguish whether we need to send only the edited properties or the full properties to save
  while updating a particular document */
  public sendAllProperties: boolean = true;

  public documentUtilService: DocumentUtilService;
  // LFWM-441 Variable used to set the selected tab while we have tabs in the detail table section
  public selectedTab: any;
  // LFWM-441 array of search services that will be switched based on tabId
  public searchServices: Array<SearchService> = [];
  public tableIds: Array<string> = [];
  public primaryIdProperties: Array<string> = [];
  // flag used to check whether the flow is normal create flow or duplicate flow
  public isDuplicateFlow: boolean = false;

  // [LFWM-2125] - mapping primary table id with module name to get dynamic buttons
  public tableIdToModuleNameMapping = {
    'asntd': 'asn',
    'sotd': 'so',
    'potd': 'po',
    'adjtd': 'inventoryadjustment',
    'transfertd': 'inventorytransfer',
    'inventoryqctd': 'inventoryqc',
  };

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
    this.documentUtilService = sharedServiceRegistry.documentUtilService;
  }

  public setDependentServices(dataEditor, buttonService) {
    this.dataEditor = dataEditor;
    this.buttonService = buttonService;
    if (this.primarySearchParams) {
      this.primarySearchParams.actionButtons = this.buttonService.getButtonsForPanel('search-table');
    }
    if (this.detailsSearchParams) {
      this.detailsSearchParams.actionButtons = this.buttonService.getButtonsForPanel('detail-table');
    }
  }

  public getSearchService(): SearchService {
    return this.primarySearchService;
  }

  public setMetaDataMap(metadata) {
    if (metadata) {
      this.primaryMetaDataMap = {};
      metadata.columnList.map((column: any) => {
        this.primaryMetaDataMap[column.propertyName] = column;
      });
    }
  }

  /**
   * This method loads the primary search configuration from the entity config and creates a search service
   */
  public initializePrimarySearchContext() {
    this.primarySearchParams = this.entityConfig.getPrimarySearchParams();
    this.primarySearchParams.tableEditor = this.dataEditor;
    this.primarySearchParams.alertService = this.alertService;
    if (this.buttonService) {
      this.primarySearchParams.actionButtons = this.buttonService.getButtonsForPanel('search-table');
    }
    this.primarySearchStatus = new SearchStatus();
    this.primarySearchService = this.sharedServiceRegistry.createSearchService(this.primarySearchParams,
      this.primarySearchStatus);
  }


  /**
   * This method loads the detail search configuration from the entity config and creates a search service
   */
  public initializeDetailsSearchContext() {
    this.detailsSearchParams = this.entityConfig.getDetailSearchParams();
    this.detailsSearchParams.defaultSearchColumn = '';
    this.detailsSearchParams.tableEditor = this.dataEditor;
    this.detailsSearchParams.alertService = this.alertService;
    if (this.buttonService) {
      this.detailsSearchParams.actionButtons = this.buttonService.getButtonsForPanel('detail-table');
    }
    this.detailsSearchStatus = new SearchStatus();
    this.detailsSearchService = this.sharedServiceRegistry.createSearchService(this.detailsSearchParams,
      this.detailsSearchStatus);
  }

  public getPrimarySearchService() {
    return this.primarySearchService;
  }

  public getPrimarySearchParams() {
    return this.primarySearchParams;
  }

  public getPrimarySearchStatus() {
    return this.primarySearchStatus;
  }

  public getPrimarySelectedDocument() {
    return this.primarySelectedDocument;
  }

  public setPrimarySelectedDocument(primarySelectedDocument: string) {
    this.primarySelectedDocument = primarySelectedDocument;
  }

  public getPrimaryDataMap() {
    return this.primaryDataMap;
  }

  public setPrimaryDataMap(primaryDataMap: any) {
    this.primaryDataMap = primaryDataMap;
  }

  public getDetailsSearchService() {
    return this.detailsSearchService;
  }

  public getDetailsSearchParams() {
    return this.detailsSearchParams;
  }

  public getDetailsSearchStatus() {
    return this.detailsSearchStatus;
  }

  public setDisplayMode(displayMode) {
    this.displayMode = displayMode;
    this.primarySearchService.setDisplayMode(displayMode);
    if (this.detailsSearchService) {
      this.detailsSearchService.setDisplayMode(displayMode);
    }
  }

  // returns all the line items of the entity
  public getAllLineItems(primaryProperties: any, searchService?: any) {
    const allRows = this.detailsSearchService.getPagedData().pageRows;
    if (allRows.length === 0) {
      return allRows;
    }
    const allLineItems = [];
    const primaryIdProperty = this.primarySearchParams.idProperty;
    allRows.forEach((lineItemRow: any, index: number) => {
      const lineItem = [];
      const lineItemCells = lineItemRow.cells;
      const lineItemProperties = Object.keys(lineItemCells);
      lineItemProperties.forEach((lineItemProperty: any) => {
        lineItem.push({ cName: lineItemProperty, cVal: lineItemCells[lineItemProperty].cVal });
      });
      lineItem.push({ cName: primaryIdProperty, cVal: primaryProperties[primaryIdProperty].cVal });
      if (primaryProperties['storerKey']) {
        lineItem.push({ cName: 'storerKey', cVal: primaryProperties['storerKey'].cVal });
      }
      allLineItems.push({ rIdx: index, cells: lineItem });
    });
    return allLineItems;
  }

  // returns the modified primary properties of the entity
  public getModifiedPrimaryProperties(primaryProperties: any) {
    const asnDetails: Array<any> = [];
    const asnData = Object.keys(primaryProperties);
    /*LFWM-1054 updating the logic so as to incorporate all the properties even if only few properties are
    edited*/
    let haveEditedData: boolean = false;
    if (this.sendAllProperties) {
      for (let i = 0; i < asnData.length; i++) {
        if (primaryProperties[asnData[i]].isEdited) {
          haveEditedData = true;
          break;
        }
      }
      if (haveEditedData) {
        asnData.forEach((property: string) => {
          const propertyVal = primaryProperties[property];
          if (propertyVal) {
            asnDetails.push({ cName: property, cVal: propertyVal.cVal });
          }
        });
      }
    } else {
      asnData.forEach((property: string) => {
        const propertyVal = primaryProperties[property];
        if ((propertyVal && propertyVal.isEdited)) {
          asnDetails.push({ cName: property, cVal: propertyVal.cVal });
        }
      });
      if (asnDetails.length > 0) {
        const primaryIdProperty = this.primarySearchParams.idProperty;
        asnDetails.push({ cName: primaryIdProperty, cVal: primaryProperties[primaryIdProperty].cVal });
        if (primaryProperties['storerKey']) {
          asnDetails.push({ cName: 'storerKey', cVal: primaryProperties['storerKey'].cVal });
        }
      }
    }
    return asnDetails;
  }

  public reloadDetailTable(reInitializeButtons?: boolean) {
    this.getDetailsSearchService().performSearch();
    this.getDetailsSearchService().receiveEvent().subscribe((event: SearchEvent) => {
      if (event.type === NEW_SEARCH_EVENT) {
        const pageRows = this.getDetailsSearchService().getPagedData().pageRows;
        if (this.displayMode === 'E') {  // IN EDIT MODE
          this.dataEditor.populateAllRestrictedValues(pageRows);
        }
        if (reInitializeButtons) {
          this.buttonService.initializeButtons(this.primaryDataMap);
        }
      }
    });
  }

  // Returns modified line items from the details table
  public getModifiedLineItems(primaryProperties: any): any[] {
    const editedRows = this.detailsSearchService.getEditedRows();
    const modifiedLineItems = [];
    // JIRA LFWM-960 Fixes - Editing and saving an already saved PO  throws Operation Failed error on Saving
    const skuProperty = this.getSkuProperty();
    // add mandatory props for each line item
    const primaryIdProperty = this.primarySearchParams.idProperty;
    const detailIdProperty = this.detailsSearchParams.idProperty;
    if (editedRows.length === 0) {
      return editedRows;
    }
    /*LFWM-1054 updating the logic so as to incorporate all the line item properties even
    if only few properties are edited in that row*/
    if (this.sendAllProperties) {
      this.detailsSearchService.getPagedData().pageRows.forEach((row: any, index: any) => {
        const modifiedLineItem = [];
        if (row.isEdited) {
          Object.keys(row.cells).forEach((property: any) => {
            const cell = row.cells[property];
            modifiedLineItem.push({ cName: property, cVal: cell.cVal, cValOrig: cell.cValOrig, cValPrev: cell.cValPrev });
            // previous values are needed while forming the request
          });
          modifiedLineItem.push({ cName: detailIdProperty, cVal: row.cells[detailIdProperty].cVal });
          modifiedLineItem.push({ cName: primaryIdProperty, cVal: primaryProperties[primaryIdProperty].cVal });
          if (skuProperty && row.cells[skuProperty]) {
            modifiedLineItem.push({ cName: skuProperty, cVal: row.cells[skuProperty].cVal });
          }
          if (primaryProperties['storerKey']) {
            modifiedLineItem.push({ cName: 'storerKey', cVal: primaryProperties['storerKey'].cVal });
          }
          modifiedLineItems.push({ rIdx: index, cells: modifiedLineItem });
        }
      });
    } else {
      editedRows.forEach((row: any, index: any) => {
        const modifiedLineItem = [];
        const editedProps = [];
        const isNewItem: boolean = (row.cells['isNewItem'] && row.cells['isNewItem'].cVal) ? true : false;
        Object.keys(row.cells).forEach((property: any) => {
          const cell = row.cells[property];
          if (isNewItem || cell.isEdited) {
            modifiedLineItem.push({ cName: property, cVal: cell.cVal });
            editedProps.push(property);
          }
        });
        modifiedLineItem.push({ cName: detailIdProperty, cVal: row.cells[detailIdProperty].cVal });
        modifiedLineItem.push({ cName: primaryIdProperty, cVal: primaryProperties[primaryIdProperty].cVal });
        if (skuProperty && row.cells[skuProperty]) {
          modifiedLineItem.push({ cName: skuProperty, cVal: row.cells[skuProperty].cVal });
        }
        if (primaryProperties['storerKey']) {
          modifiedLineItem.push({ cName: 'storerKey', cVal: primaryProperties['storerKey'].cVal });
        }
        const hasChangedUOM = editedProps.indexOf('uom') !== -1;
        const hasChangedPackKey = editedProps.indexOf('packKey') !== -1;
        if (hasChangedUOM && !hasChangedPackKey) {
          // if uom is edited and packKey is not, then add packKey also explicitly, so that server can validate them
          modifiedLineItem.push({ cName: 'packKey', cVal: row.cells['packKey'].cVal });
        } else if (hasChangedPackKey && !hasChangedUOM) {
          // if packKey is edited and uom is not, then add uom also explicitly, so that server can validate them
          modifiedLineItem.push({ cName: 'uom', cVal: row.cells['uom'].cVal });
        }
        if (editedProps.indexOf('expectedQty') !== -1 || editedProps.indexOf('receivedQty') !== -1) {
          // if either  or receivedQty is changed, send both packkey and uom
          if (!hasChangedUOM) {
            modifiedLineItem.push({ cName: 'uom', cVal: row.cells['uom'].cVal });
          }
          if (!hasChangedPackKey) {
            modifiedLineItem.push({ cName: 'packKey', cVal: row.cells['packKey'].cVal });
          }
        }
        modifiedLineItems.push({ rIdx: index, cells: modifiedLineItem });
      });
    }
    return modifiedLineItems;
  }

  // Method to create new record based on Metadata
  public createNewRecord() {
    return this.documentUtilService.createNewDocumentDataMap(this.primarySearchService.getMetadata(),
      this.primarySearchParams.idProperty, this.getPrimarySelectedDocument(), this.getDocType());
  }

  public getDocType() {
    return this.docType;
  }
  // Method  to override default display format for each cell value (in VIEW Mode ONLY)
  public getCellDisplayValue(property: any, isLoading: boolean): any {
    return this.primaryDataMap[property];
  }

  // gets the edited cells among cell properties
  public getEditedPrimaryCells(): Array<any> {
    const editedCells = [];
    Object.values(this.primaryDataMap).forEach((cell: any) => {
      if (cell.isEdited) {
        editedCells.push(cell);
      }
    });
    return editedCells;
  }

  public getPrimaryDataIdValue() {
    if (this.primaryDataMap) {
      const primaryDataId = this.primaryDataMap[this.primarySearchParams.idProperty];
      if (primaryDataId) {
        return primaryDataId.cVal;
      }
    }
    return null;
  }

  public createAlertMessage(inputMessage: string, property?: any) {
    if (inputMessage && inputMessage.indexOf('{}') > -1) {
      inputMessage = inputMessage.replace('{}', this.getEntityConfiguration().getPageTitle());
    }
    if (inputMessage && inputMessage.indexOf('%') > -1) {
      inputMessage = inputMessage.replace('%', property);
    }
    return inputMessage;
  }

  public handleAddNewLineItem(event) {
    const pageDefinition: PageDefinition = this.detailsSearchService.getPagedData().pageDefinition;
    // if the current table spans across multiple pages get the max value
    if (this.displayMode === 'E' && (pageDefinition.recordCount > pageDefinition.pageSize) && !this.maxIdValueForDetailTable) {
      this.detailsSearchService.getMaxValueForIdProperty().subscribe((response: any) => {
        if (response && response.propertyValues && response.propertyValues.length > 0) {
          this.maxIdValueForDetailTable = response.propertyValues[0].cVal;
          this.initializeValuesForNewLineItem(event.selectedRow);
          this.detailsSearchService.publishEvent(new SearchEvent(REFRESH_SEARCH_EVENT));
        }
      });
    } else {
      this.initializeValuesForNewLineItem(event.selectedRow);
    }

    if (this.detailsSearchService.getPagedData().pageRows.length >= pageDefinition.pageSize) {
      // if the current page has overflown, clear the max value so that it can be refetched in the next page
      this.maxIdValueForDetailTable = null;
    }

    if (this.detailsSearchService.getPagedData().pageDefinition.recordCount === 1) {
      this.buttonService.initializeButtonsForPage(this.primaryDataMap, 'details');
    }
  }

  public initializeValuesForNewLineItem(newRow) {
    let maxValue: string = '00000';
    const idProperty = this.detailsSearchParams.idProperty;
    this.detailsSearchService.getPagedData().pageRows.forEach((row: any) => {
      if (!maxValue || maxValue < row.cells[idProperty].cVal) {
        maxValue = row.cells[idProperty].cVal;
      }
    });

    if (this.maxIdValueForDetailTable && maxValue < this.maxIdValueForDetailTable) {
      maxValue = this.maxIdValueForDetailTable;
    }

    const newLineNumber = (+maxValue) + 1;
    let newLineNoStr = '' + newLineNumber;
    while (newLineNoStr.length < 5) {
      newLineNoStr = '0' + newLineNoStr;
    }

    newRow.cells[idProperty].cVal = newLineNoStr;
  }

  // Method used to customize the metadata object for display purposes
  public overrideMetadataMap(displayMode: any, properties?: any) { }

  // Method used to validate a detail property
  public validateProperty(details: any, property: any): { property: string, errorMsg: string } {
    return {
      property: '',
      errorMsg: ''
    };
  }
  public getSkuProperty() {
    return this.entityConfig.getType() === 'PO' ? 'commodity' : 'sku';
  }

  // method used to populate the restricted values
  public populateRestrictedValues() {}

  // method used to handle the destroy of detail page component
  public handleDestroyDetails() {
    this.lookupMap = {};
    this.searchServices = [];
    this.isDuplicateFlow = false;
  }
  public  shallowCopyDataService(targetDataService: DetailedEntityDataService) {
    if (!targetDataService) {
      return;
    }
    super.shallowCopyDataService(targetDataService);
    targetDataService.buttonService  =  this.buttonService;
    targetDataService.primarySearchService  =  this.primarySearchService;
    targetDataService.primarySearchParams  =  this.primarySearchParams;
    targetDataService.primarySearchStatus  =  this.primarySearchStatus;
    targetDataService.docType  =  this.docType;
    targetDataService.primaryDataMap = this.primaryDataMap;
    targetDataService.primaryMetaDataMap  =  this.primaryMetaDataMap;
    targetDataService.primaryColumnGrouping  =  this.primaryColumnGrouping;
    targetDataService.lookupMap  =  this.lookupMap;
    targetDataService.primaryIdProperties  =  this.primaryIdProperties;
    targetDataService.primarySelectedDocument  =  this.primarySelectedDocument;
    targetDataService.detailsSearchService  =  this.detailsSearchService;
    targetDataService.detailsSearchParams  =  this.detailsSearchParams;
    targetDataService.detailsSearchStatus  =  this.detailsSearchStatus;
    targetDataService.maxIdValueForDetailTable  =  this.maxIdValueForDetailTable;
    targetDataService.sendAllProperties  =  this.sendAllProperties;
    targetDataService.documentUtilService  =  this.documentUtilService;
    targetDataService.selectedTab  =  this.selectedTab;
    targetDataService.searchServices  =  this.searchServices;
    targetDataService.tableIds  =  this.tableIds;
    targetDataService.isDuplicateFlow  =  this.isDuplicateFlow;
  }


  public requestFormation(entityProperties, lineItems){
    const obj = {
      'notifRecipUser' : []
    };
    let requestItems = [];
    entityProperties.forEach(element => {
      if(element.cName === 'GROUP_ID'){
        obj['groupId']= element.cVal;
      }
      if(element.cName === 'GROUP_NAME'){
        obj['groupName']= element.cVal;
      }
      if(element.cName === 'COUNTRY_ID'){
        obj['country']= element.cVal;
      }
      if(element.cName === 'DESCRIPTION'){
        obj['description']= element.cVal;
      }
    });
    requestItems.push(obj);
     let objForUser = {};
    lineItems.forEach((elementCells, indexparent ) => {
     
      elementCells.cells.forEach((element , index ) => {
        if(element.cName === 'USER_ID'){
          objForUser['userId']= element.cVal;
        }
          objForUser['isNew']= false;
        if(element.cName === 'isNewItem'){
          objForUser['isNew']= element.cVal;
        }
          objForUser['isDeleted']= false;
          objForUser['userLineNum']= indexparent+1;
      });
      obj['notifRecipUser'].push(objForUser);  
      objForUser = {}; 
    });

  }

  public assignDataForTemplate(response){

  }

  public assignDataForScheduler(response){

  }
}

