import {
  Directive, Input, EventEmitter, ElementRef,
  Renderer2, Inject, SimpleChanges, OnChanges
} from '@angular/core';
import { Observable, timer } from 'rxjs';

@Directive({
  selector: '[set-focus]'
})
export class FocusDirective implements OnChanges {

  // @Input('focus') focusEvent: EventEmitter<boolean>;
  @Input('set-focus') focusMe: boolean;

  constructor( @Inject(ElementRef) public element: ElementRef, public renderer: Renderer2) { }

  /*
  public ngOnInit() {
    this.focusEvent.subscribe((event: any) => {
    this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
    });
  }
  */

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['focusMe'] && changes['focusMe'].currentValue) {
      // this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
         timer(5).subscribe(() => {
        this.element.nativeElement.focus();
      });
    }
  }

}
