import { Component, OnInit, Input, ViewChild, HostListener } from '@angular/core';
import { NavigationService, ResourceService, UserService } from 'sce-core';
import { DetailViewKeyEventHandlerService } from '../../../services/detail-view-key-event-handler.service';
import { SearchableEntityDataService } from '../../../services/searchable-entity/searchable-entity-data.service';
import { SearchableEntityDataEditorService } from '../../../services/searchable-entity/searchable-entity-data-editor.service';
import { LeftPanelService } from '../../../../layout/home/services/left-panel.service';
import { AlertService } from 'sce-core';
import { LFDynamicWidgetConfig } from '../../../models/lf-dynamic-widget-config';
import { FormPageConfig } from '../../../models/form-page-config';
import { DynamicWidget } from '../dynamic-widget';
import { PropertyChangeEvent, MANUAL_EDIT } from '../../../models/property-change-event';
import { DatePickerConfigService } from '../../datepicker/datepicker-config.service';

@Component({
  selector: 'lfwms-abstract-form-widget',
  templateUrl: './abstract-form-widget.component.html',
  styleUrls: ['./abstract-form-widget.component.css']
})
export class AbstractFormWidgetComponent implements OnInit, DynamicWidget {

  @Input() pageConfig: FormPageConfig;
  @Input() widgetConfig: LFDynamicWidgetConfig;

  public metadataMap: any;
  public filteredMetaData: any;
  public cellDataMap: any;
  public propertyList: any;
  public accordionTitle: string;
  public isLoading = false;
  public isPrimaryDataLoaded = false;
  public panel: any;
  public default: any;
  public dataService: SearchableEntityDataService;
  public dataEditor: SearchableEntityDataEditorService;
  public keyEventHandler: DetailViewKeyEventHandlerService;
  public windowWidth: any;
  public sidePanelWidth: number;
  public searchValue: any;
  public originalCellDataMap: any; // stores the original data
  public dateFormat: string;
  public dateTimeFormat: string;

  // Method to recalculate cell width and align Property Grid on Window Resize event
  @HostListener('window:resize', ['$event'])
  public onResize($event: any) {
    this.windowWidth = $event.target.innerWidth;
    this.realignWidget();
  }

  constructor(public resourceService: ResourceService,
    public navService: NavigationService,
    public leftPanelService: LeftPanelService,
    public alertService: AlertService,
    public userService: UserService,
    public dateConfigService: DatePickerConfigService) {
  }

  public ngOnInit() {
    this.initializeWidget();
    this.dateFormat = this.dateConfigService.defaultDateDisplayFormat;
    this.dateTimeFormat = this.dateConfigService.defaultDateDisplayFormat + ' ' + this.dateConfigService.defaultTimeDisplayFormat;
  }

  public initializeWidget() {
    this.dataService = this.pageConfig.dataService;
    this.dataEditor = this.pageConfig.dataEditor;
    this.keyEventHandler = this.pageConfig.keyEventHandler;
    this.metadataMap = this.pageConfig.metaDataMap;
    this.filteredMetaData = JSON.parse(JSON.stringify(this.metadataMap));
    this.cellDataMap = this.pageConfig.cellDataMap;
    this.originalCellDataMap = JSON.parse(JSON.stringify(this.cellDataMap));
    /* set initial value before first call to realignPropertyGrid()
    or  any events are received via subscription.edge case. */
    this.sidePanelWidth = this.leftPanelService.isOpened() ? this.leftPanelService.getLeftPanelWidthInPixels() : 0;
    this.realignWidget();
    this.leftPanelService.getLeftPanelOpenEvent().subscribe((isOpened) => {
      if (isOpened) {
        this.sidePanelWidth = this.leftPanelService.getLeftPanelWidthInPixels();
      } else {
        this.sidePanelWidth = 0;
      }
      this.realignWidget();
    });
    this.accordionTitle = this.widgetConfig.name;
  }

  // realigns the widget
  public realignWidget() {
    // this should be implemented separately for every type of widget
  }

  // method used to translate the labels
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  // Method to return Cell Title corresponding to each property
  public getCellTitle(property: any): string {
    return this.resourceService.get(this.metadataMap[property].elementId);
  }

  // Method  to override default display format for each cell value (in VIEW Mode ONLY)
  public getCellDisplayValue(property: any): any {
    if (!this.isLoading) {
      let cellDisplayValue: any;
      const cellDefinition: any = this.cellDataMap[property];
      const cellMetadata: any = this.metadataMap[property];
      // in dropdown field if default option of input options [- Select -] is selected then the cellDisplayValue should be empty in view mode.
      if (cellDefinition && !cellDefinition.cVal) {
        // if the qty field is empty then defaultvalue from metadata should taken
        if (property === 'qty' && cellMetadata.defaultVal !== '') {
          cellDisplayValue = cellMetadata.defaultVal;
        } else {
          cellDisplayValue = '';
        }
      } else {
        if (cellMetadata.type === 'DROPDOWN' || cellMetadata.type === 'EDITABLEDROPDOWN') {
          // to handle dropdown fields with cOptions
          let filteredValueObject = [];
          if (cellMetadata.values.length > 0) {
            if ((cellMetadata.values.length > 0) || (cellMetadata.values[0].key && cellMetadata.values[0].value)) {

              filteredValueObject = cellMetadata.values.filter((valueObject) => {
                /* if (cellMetadata.defaultVal) {
                  return (valueObject.key === cellMetadata.defaultVal);
                } else .trim() */ {
                  return (valueObject.key === (cellDefinition.cVal));
                }
              });

              if ((filteredValueObject.length === 0) && (this.cellDataMap[property].cVal)) {
                filteredValueObject.push({
                  key: property,
                  value: this.cellDataMap[property].cVal
                });
              }
            } else {
              if (this.cellDataMap[property].cVal) {
                filteredValueObject.push({
                  key: property,
                  value: this.cellDataMap[property].cVal
                });
              }
            }
          }
          if (filteredValueObject.length > 0) {
            if (property === 'facility') {
              cellDisplayValue = filteredValueObject[0].key;
            } else {
              cellDisplayValue = filteredValueObject[0].value;
            }
          } else {
            cellDisplayValue = '';
          }
        } else if (cellMetadata.type === 'DATE' || cellMetadata.type === 'DATETIME') {
          if (cellDefinition.cVal) {
            //  cellDisplayValue = moment(cellDefinition.cVal).format('MM/DD/YYYY');
            if (cellMetadata.type === 'DATE') {
              cellDisplayValue = this.dateConfigService.getFormattedDisplayDate(cellDefinition.cVal);
            } else if (cellMetadata.type === 'DATETIME') {
              cellDisplayValue = this.dateConfigService.getFormattedDisplayDateTime(cellDefinition.cVal);
            }
          } else {
            cellDisplayValue = '';
          }
        } else {
          cellDisplayValue = cellDefinition.cVal;
        }
      }
      return cellDisplayValue;
    } else {
      return '';
    }
  }

  public openLookup(cells: any, property: any) {
    const row = { cells: this.cellDataMap };
    this.dataEditor.openLookup(row, property);
  }

  public valueChanged(key: any, sourceEvent?: any) {
    let newVal = null;
    if (sourceEvent !== null && sourceEvent !== undefined) {
      if ('string' === typeof sourceEvent) {
        newVal = sourceEvent;
      } else if ('object' === typeof sourceEvent) {
        newVal = sourceEvent['newVal'];
      }
    }

    if (newVal === null || newVal === undefined) {
      newVal = this.cellDataMap[key].cVal;
    }

   //  this.cellDataMap[key].cValPrev = this.originalCellDataMap[key].cVal; // cVal prev is obtained from the original data
    // if the value has changed, send an event and check if the change can be accepted
    const event: PropertyChangeEvent = new PropertyChangeEvent();
    event.source = 'table-editor';
    event.type = MANUAL_EDIT;
    event.property = this.cellDataMap[key].cName;
    event.value = newVal;
    // JIRA LFWM-1474 Fixes - current value and previous value of a dropdown element should not be the same value
    if (sourceEvent.type && sourceEvent.type === 'dropdown') {
      event.previousValue = sourceEvent.prevVal;
    } else {
      event.previousValue = this.cellDataMap[key].cVal;
    }
    const acceptChange: boolean = this.dataEditor.propertyValueChanged(event);
    // if the property change is to be rejected, do not set the is edited flag
    if (acceptChange) {
      this.cellDataMap[key].isEdited = true;
      this.navService.preventNavigation = true;
      // if the event is from dropdown the current value is already set in this.cellDataMap[key].cVal
      if (sourceEvent.type && sourceEvent.type === 'dropdown') {
        this.cellDataMap[key].cValPrev = sourceEvent.prevVal;
      } else {
        this.cellDataMap[key].cValPrev = this.cellDataMap[key].cVal;
        this.cellDataMap[key].cVal = newVal;
      }
    }
  }
  // when value change in type ahead suggestion component
  public onValueChangeInTypeaheadTextBox(event) {
    this.valueChanged(event.propertyName, event);
  }

  // Method for checkbox starts
  public toggleSelect(key) {
    this.cellDataMap[key].isEdited = true;
    this.cellDataMap[key].cValPrev = this.cellDataMap[key].cVal;
    if (this.cellDataMap[key].cVal === 'Y' || this.cellDataMap[key].cVal === '1') {
      // If datatype of checkbox value is string
      if (this.metadataMap[key].dataType === 'String') {
        this.cellDataMap[key].cVal = 'N';
      } else if (this.metadataMap[key].dataType === 'Integer') { // If datatype of checkbox value is string
        this.cellDataMap[key].cVal = '0';
      }
    } else {
      if (this.metadataMap[key].dataType === 'String') {
        this.cellDataMap[key].cVal = 'Y';
      } else if (this.metadataMap[key].dataType === 'Integer') {
        this.cellDataMap[key].cVal = '1';
      }
    }
  }

  // Method for checkbox ends

  public filterMetaData(key: any) {
    for (const data in this.filteredMetaData) {
      if (this.filteredMetaData[data].type === 'DROPDOWN' || this.filteredMetaData[data].type === 'EDITABLEDROPDOWN') {
        this.filteredMetaData[data].values = [];
        this.metadataMap[data].values.forEach(element => {
          // In scenarios where there is storerkey
          if (element.storerkey && this.cellDataMap['storerKey']) {
            if ((element.storerkey === this.cellDataMap['storerKey'].cVal.trim())) {
              this.filteredMetaData[data].values.push(element);
            }
          }
        });
        if (this.filteredMetaData[data].values.length === 0) {
          this.metadataMap[data].values.forEach(element => {
            if (element.storerkey === '' || element.storerkey === null) {
              this.filteredMetaData[data].values.push(element);
            }

          });
        }
      }
    }
    return this.filteredMetaData[key].values;
  }



}
