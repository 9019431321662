export const INITIALBREADEVENT = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'tms', displayValue: 'TMS', route: undefined },
    { id: 'notification', displayValue: 'Notification', route: undefined },
    { id: 'child_tmplt', displayValue: 'Child Template', route: '/home/notification/child-template' }
]

export const FETCH_DASHBOARD_DETAILS = {
    'RG': {code: 'RG', id: 2223, notificationId: '8', moduleId: 'Recipient Group', pageTitle: 'Receipient Group', tableId: 'massuploadsearchtd'},
};

export const childTemplatemetaDataGrp = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
        {
            'id': '1001',
            'elementId': 'Template Name',
            'propertyName': 'Template Name',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'T',
            'dataType': 'String',
            'cellDataName': 'xid',
        },
        {
            'id': '1002',
            'elementId': 'Template Type',
            'propertyName': 'Template Type',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'Carrier ID'
        },
        {
            'id': '1003',
            'elementId': 'Template Category',
            'propertyName': 'Template Category',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 60,
            'index': 4,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'sourceLocationName'
        },
        {
            'id': '1004',
            'elementId': 'Create By',
            'propertyName': 'Create By',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'destLocationName'
        },
        {
            'id': '1005',
            'elementId': 'Create On',
            'propertyName': 'Create On',
            'type': 'DATE',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId': '',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'totalStopCount'
        },
        {
            'id': '1012',
            'elementId': 'lbl_tms_status',
            'propertyName': 'Status',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' },
                { 'key': 'Active', 'value': 'Active' }
            ],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'Status'
        },
    ]
}

export const metaDataChildGrpTable = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
        {
            'id': '1000',
            'elementId': 'lbl_tms_teplat_name',
            'propertyName': 'templatename',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'displayType': 'T',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dataType': 'String',
        },
        {
            'id': '1001',
            'elementId': 'lbl_tms_teplat_type',
            'propertyName': 'typename',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
        },
        {
            'id': '1002',
            'elementId': 'lbl_tms_templt_catgr',
            'propertyName': 'templatecategory',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
        },
        {
            'id': '1003',
            'elementId': 'lbl_tms_created_by',
            'propertyName': 'createdby',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
        },
        {
            'id': '1004',
            'elementId': 'created_Date',
            'propertyName': 'createdon',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
        },
        {
            'id': '1005',
            'elementId': 'status_Id',
            'propertyName': 'statusname',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
        },
        {
            'id': '1000',
            'elementId': 'action_Flag',
            'propertyName': 'action',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'displayType': 'B',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dataType': 'String',
        },
    ]
}

