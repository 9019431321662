import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'lfwms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent{

  constructor() { 
  }

}
