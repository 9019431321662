import { Injectable } from '@angular/core';
import { SearchService } from '../../../search/search.service';
import { SearchParams } from '../../../search/models/search-params.model';
import { SearchStatus } from '../../../search/models/search-status.model';
import { SearchEvent } from '../../../search/models/search-event';
import { REFRESH_SEARCH_EVENT } from '../../../search/search-constants';
import { SharedServiceRegistry } from '../../../shared-service-registry';
import { CoreServiceRegistry } from '../../../../core/core-service-registry';
import { SearchableEntityDataService } from '../searchable-entity-data.service';
import { DataTableConfig } from '../../../search/models/data-table-config';

@Injectable()
export class SimpleEntityDataService extends SearchableEntityDataService {

  public searchService: SearchService;
  public searchParams: SearchParams;
  public searchStatus: SearchStatus;
  /*Properties used to display multitab in search page */
  public searchServiceTab: SearchService;
  public searchParamsTab: SearchParams;
  public searchStatusTab: SearchStatus;
  public selectedHeader: Array<string> = [];
  public tableIds: Array<string> = [];
  public primaryIdProperties: Array<string> = [];
  /*Properties used to display multitab in search page */
  public metaDataMap: any;

  public lookupMap: any = {};
  //  Variable used to set the selected tab while we have tabs in the detail table section
  public selectedTab: any = 0;
  // array of search services that will be switched based on tabId
  public searchServices: Array<SearchService> = [];

  // maximum id value for the table
  public maxIdValue: string;
 // This will store the primary metadata as a map with the property name as the key
  public primaryDataMap: any;
  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
  }

  public setDependentServices(dataEditor, buttonService) {
    this.dataEditor = dataEditor;
    this.buttonService = buttonService;
    if (this.searchParams) {
      this.searchParams.actionButtons = this.buttonService.getButtonsForPanel('search-table');
    }
  }

  /**
   * This method loads the search configuration from the entity config and creates a search service
   */
  public initializeSearchContext() {
    this.searchParams = this.entityConfig.getPrimarySearchParams();
    this.searchParams.tableEditor = this.dataEditor;
    this.searchParams.alertService = this.alertService;
    if (this.buttonService) {
      this.searchParams.actionButtons = this.buttonService.getButtonsForPanel('search-table');
    }
    this.searchStatus = new SearchStatus();
    this.searchService = this.sharedServiceRegistry.createSearchService(this.searchParams,
      this.searchStatus);
  }

  public getSearchService(): SearchService {
    return this.searchService;
  }

  public setMetaDataMap(metadata) {
    if (metadata) {
      this.metaDataMap = {};
      metadata.columnList.map((column: any) => {
        this.metaDataMap[column.propertyName] = column;
      });
    }
  }

  public setDisplayMode(displayMode) {
    this.displayMode = displayMode;
    this.searchService.setDisplayMode(displayMode);
  }

  public getAllLineItems() {
    let allRows = [];
    if (this.searchService.searchParams.paginateLocally) {
      allRows = this.searchService.fullData;
    } else {
      allRows = this.searchService.getPagedData().pageRows;
    }
    if (allRows.length === 0) {
      return allRows;
    }
    const allLineItems = [];
    allRows.forEach((lineItemRow: any, index: number) => {
      const lineItem = [];
      const lineItemCells = lineItemRow.cells;
      const lineItemProperties = Object.keys(lineItemCells);
      lineItemProperties.forEach((lineItemProperty: any) => {
        lineItem.push({ cName: lineItemProperty, cVal: lineItemCells[lineItemProperty].cVal, cValOrig: lineItemCells[lineItemProperty].cValOrig });
      });
      allLineItems.push({ rIdx: index, cells: lineItem });
    });
    return allLineItems;
  }

  // Returns modified line items from the ASN details table
  public getModifiedLineItems(): any[] {
    let modifiedRows = [];
    if (this.searchService.searchParams.paginateLocally) {
      modifiedRows = this.searchService.getPagedData().pageRows.filter((row: any) => {
        return row.isNewRow;
      });
    } else {
      modifiedRows = this.searchService.getPagedData().pageRows.filter((row: any) => {
        return row.isEdited;
      });
    }
    return modifiedRows;
  }

  // Method to create new record based on Metadata
  public createNewRecord() {
    return {};
  }

  public handleAddNewRow(event: any) {
    if (this.entityConfig.getPrimarySearchParams().autoGenerateIdProperty) {
      const selectedRowIndex = event.selectedRow['rIdx'];
      this.searchService.createUniqueId(this.entityConfig.getType())
        .subscribe((response: any) => {
          if (response && response.documentNumber) {
            this.searchService.getPagedData().pageRows.forEach((row: any) => {
              if (row.rIdx === selectedRowIndex) {
                row.cells[this.entityConfig.getPrimarySearchParams().idProperty].cVal = response.documentNumber;
                this.searchService.publishEvent(new SearchEvent(REFRESH_SEARCH_EVENT));
              }
            });
          } else {
            this.alertService.error(this.entityConfig.getItemLabel() + ' '
              + this.resourceService.get('err_creation_failed'));
          }
        });
    }
  }
  //  Adding this logic to implement multi tabbed in search page
  public initializeMultiTabbedDetailContexts(tableIds: string[], primaryProperties: string[]) {
    tableIds.forEach((tableId: string, index: any) => {
      this.entityConfig.initializeDetailSearchForTableId(tableId, primaryProperties[index]);
      this.searchParamsTab = this.entityConfig.getDetailSearchParams();
      this.searchParamsTab.defaultSearchColumn = '';
      this.searchParamsTab.tableEditor = this.dataEditor;
      this.searchParamsTab.isFullFetch = true;
      const detailTableConfig: DataTableConfig = new DataTableConfig();
      detailTableConfig.editable = true;
      this.searchParamsTab.dataTableConfig = detailTableConfig;
      this.searchParamsTab.alertService = this.alertService;
      if (this.buttonService) {
        this.searchParamsTab.actionButtons = this.buttonService.getButtonsForPanel('detail-table');
      }
      this.searchStatusTab = new SearchStatus();
      this.searchServiceTab = this.sharedServiceRegistry.createSearchService(this.searchParamsTab,
        this.searchStatusTab);
      this.searchServices.push(this.searchServiceTab);
    });
  }
  public  shallowCopyDataService(targetDataService: SimpleEntityDataService) {
    if (!targetDataService) {
      return;
    }
    super.shallowCopyDataService(targetDataService);
    targetDataService.searchService  =  this.searchService;
    targetDataService.searchParams  =  this.searchParams;
    targetDataService.searchStatus  =  this.searchStatus;
    targetDataService.searchServiceTab  =  this.searchServiceTab;
    targetDataService.searchParamsTab  =  this.searchParamsTab;
    targetDataService.searchStatusTab  =  this.searchStatusTab;
    targetDataService.selectedHeader  =  this.selectedHeader;
    targetDataService.tableIds  =  this.tableIds;
    targetDataService.primaryIdProperties  =  this.primaryIdProperties;
    targetDataService.metaDataMap  =  this.metaDataMap;
    targetDataService.lookupMap  =  this.lookupMap;
    targetDataService.selectedTab  =  this.selectedTab;
    targetDataService.searchServices  =  this.searchServices;
    targetDataService.maxIdValue  =  this.maxIdValue;
    targetDataService.primaryDataMap  =  this.primaryDataMap;
  }
}
