<div class="rdt-container" [ngStyle]="{ 'min-height.px': gridHeight  }">
	<div  *ngIf = "(showPaginatorAndButtons === 0)" class="row rdt-top-bar">
		<div class="rdt-top-left">
			<ng-template [ngIf]="actionButtons">
				<lfwms-button-panel [buttonArray]="actionButtons" 
					(buttonClickHandler)="handleButtonPanelActions($event)">
				</lfwms-button-panel>
			</ng-template>
		</div>
		<div class="rdt-top-right"  *ngIf = "tableConfig.enablePagination">
			<lfwms-paginator class="pull-right"
				(pageChanged)="onPageChanged($event)" [searchService]="searchService" [newPageNum]="newPageNum" [newPageSize]="newPageSize" [newRecordCount]="newRecordCount">
			</lfwms-paginator>
		</div>
	</div>

	<!-- <div class="row rdt-grid">
		<div class="col-sm-12 table-style" [ngClass]="{ 'hide-grid': !isGridReady }">
			<ag-grid-angular #agGrid
				class="ag-theme-balham" [style.height] = "isTemplateScreen == true? '520px': '372px'"
				[ngClass]="{ 'disable-grid': isLoading,
							'full-width-row': isFullWidthRow }"
				[ngStyle]="{ 'height.px': gridHeight }"
				[gridOptions]="gridOptions"
				[columnDefs]="columnDefs"
				[rowData] ='rowData'
				[modules]="modules">
			</ag-grid-angular>
			<div  *ngIf = "(showPaginatorAndButtons === 1)" class="row rdt-top-bar">
					<div class="rdt-bottom-right">
						<lfwms-paginator class="pull-right"
							(pageChanged)="onPageChanged($event)" [searchService]="searchService">
						</lfwms-paginator>
					</div>
				</div>
		</div>
	</div> -->

	<div class="row rdt-grid">
		<div class="col-sm-12 table-style" [ngClass]="{ 'hide-grid': !isGridReady }">
			<ag-grid-angular #agGrid
				class="ag-theme-balham"
				[ngClass]="{ 'disable-grid': isLoading,
							'full-width-row': isFullWidthRow }"
				style="height:500px;"
				[gridOptions]="gridOptions"	
				[rowData] = 'rowData'
				[rowSelection]="rowSelection"
				(rowClicked)="onClick($event)"
				[enableCellTextSelection]="true"
				(selectionChanged)="onSelectionChanged($event)"
      	        [suppressRowClickSelection]="true"		
				[modules]="modules">
			</ag-grid-angular>
			<div  *ngIf = "(showPaginatorAndButtons === 1)" class="row rdt-top-bar">
					<div class="rdt-bottom-right">
						<lfwms-paginator class="pull-right" [newPageNum]="newPageNum" [newPageSize]="newPageSize" [newRecordCount]="newRecordCount"
							(pageChanged)="onPageChanged($event)" [searchService]="searchService">
						</lfwms-paginator>
					</div>
			</div>
		</div>
	</div>

</div>

<!-- Customize User Preferences Modal Window -->
<ng-template [ngIf]="showCustomizer">
	<lfwms-table-customizer
		[showCustomizer]="showCustomizer" [customizerConfig]="customizerConfig"
		[searchService]="searchService" (customized)="onCustomized();">
	</lfwms-table-customizer>
</ng-template>
