import { Injectable } from '@angular/core';
import { DataTableEditorService } from '../search/results/data-table-editor.service';

@Injectable()
export class GridViewKeyEventHandlerService {

  // To Style Last focused Row and Cell
  public lastFocusedRowIndex: number;
  // To identify and ignore focusin events from mouseclick
  public clickFocusedCellId: string;
  // To ignore false keypress/focusout call triggers
  public lastEditKeyPressListnerInvocationTime: number = 0;

  // Editor Service for Data Table
  constructor(public tableEditor: DataTableEditorService) { }

  // Function called on focusin events(click/tab) to set focusedcell/rowid
  public setFocusedCell(row: any, property: any) {
    this.lastFocusedRowIndex = row.rIdx;
    row.focusedCellId = property;
    this.clickFocusedCellId = property;
    // this.lastFocusedCellProperty = property;
  }

  /* Function that is listened by directive set-focus to focus the cell based on
    this.lastFocusedRowIndex & row.focusedCellId
    row.focusedCellId can be set from any external functions like from table-editor service etc.
    Also it ensures that no false trigger happens due to (focusin)
    events using mouse or any other interaction(uses this.clickFocusedCellId). */
  public isFocused(row: any, colProp: any) {
    if (row.rIdx === this.lastFocusedRowIndex && colProp === row.focusedCellId
      && colProp !== this.clickFocusedCellId) {
      return true;
    } else {
      return false;
    }
  }

  /* Function that listens to Enter/Tab keypress on input fields in edit mode
  and calls respective functions in case of EA fields*/
  public editKeyPressListner(event: any, row: any, columnMeta: any) {

    //  if (!(event.keyCode === 13 || event.keyCode === 9 || event.type==='focusout')) {
    if (!(event.keyCode === 13 || event.keyCode === 9 || event.type === 'focusout')) {
      // ignore if not ENTER13 OR Tab9.
      return true;
    }
    // In backend metadata it is named as 'columnMeta.propertyName', in front end local object it is named as 'columnMeta.prop'
    let propertyName: string = columnMeta.prop;
    if (!propertyName) {
      propertyName = columnMeta.propertyName;
    }
    if (row.cells[propertyName].cVal === row.cells[propertyName].cValPrev) {
      if (event.keyCode === 9 && row.cells[propertyName].isValueInvalid) {
        this.clickFocusedCellId = '';
        row.focusedCellId = propertyName;
        // if cell has invalid value and tab is pressed. prevent the tab click.
        return false;
      }
      // ignore if no change in value
      return true;
    }
    const invocationTime = new Date();
    if ((invocationTime.getTime() - this.lastEditKeyPressListnerInvocationTime) < 300) {
      // return if last event with 300ms
      this.lastEditKeyPressListnerInvocationTime = invocationTime.getTime();
      return true;
    }

    /* clickFocusedCellId needs to be cleared,
      since when both are same manual focus wont get triggered. */
    this.clickFocusedCellId = '';
    // this.lastEditKeyPressListnerInvocationTime = invocationTime.getTime();
  //  row.cells[propertyName].cValOldUOM = row.cells[propertyName].cValPrev;
    // Set prev value. For dependent columns update within each function
  //  row.cells[propertyName].cValPrev = row.cells[propertyName].cVal;
    // uom section to be removed on db change is made for column to EA type
    // LFWM-2014-called whenever a value is changed in duplicated line's expectedQty
    if (propertyName === 'uom' || propertyName === 'expectedQty' ) {
      this.tableEditor.autoPopulate(row, propertyName);
    }

    // uom section to be removed on db change is made for column to EA type */
    switch (columnMeta.displayType) {
      case 'EA':
        this.tableEditor.autoPopulate(row, propertyName);
        return false;
      case 'EL':
        this.tableEditor.autoPopulateLookupProperty(row, propertyName);
        return false;
      case 'L':
        this.tableEditor.autoPopulateLookupProperty(row, propertyName);
        return false;
      default:
        return true;
    }
  }
}
