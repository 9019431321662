import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LeftPanelService } from '../services/left-panel.service';
import { AlertService, NavigationService } from 'sce-core';
import { menuIdRouteMappings } from '../../../constants';
import { ResourceService } from 'sce-core';
import { UserPreferenceService } from '../services/user-preference.service';
import { UserPreferenceEvent, DELETE_FAVOURITE, ADD_FAVOURITE, ADD_RECENT, UPDATE} from '../../../shared/models/user-favourite-event';
import { DELETE_SEARCH , ADD_SEARCH } from '../../../shared/models/user-favourite-event';
import { SavedSearchService } from '../../../shared/search/saved-search.service';
import { CoreServiceRegistry } from '../../../core/core-service-registry';
import { SharedServiceRegistry } from '../../../shared/shared-service-registry';
import { Router } from '@angular/router';
// import { SearchCriteriaPopupComponent } from '../../../shared/lookup/search-criteria-popup/search-criteria-popup.component';

@Component({
  selector: 'lfwms-left-panel-contents',
  templateUrl: './left-panel-contents.component.html',
  styleUrls: ['./left-panel-contents.component.css'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '350px'
      })),
      state('closed', style({
        width: '0px'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ])
  ]
})
export class LeftPanelContentsComponent {

  public lastClickedDocId: number;
  public expandRecentItems: boolean = false;
  public recentItemsList: Array<any> = [];
  public expandSavedSearches: boolean = false;
  public savedSearchesList: Array<any> = [];
  public expandFavorites: boolean = false;
  public favoritesList: Array<any> = [];

  public alertservice: AlertService;
  public resourceService: ResourceService;
  public savedSearchService: SavedSearchService;
  public navService: NavigationService;
  public userPreferenceService: UserPreferenceService;




  constructor(public leftPanelService: LeftPanelService,
      public coreServiceRegistry: CoreServiceRegistry,
      public sharedServiceRegistry: SharedServiceRegistry,
      public router :  Router ) {
    this.resourceService = this.coreServiceRegistry.resourceService;
    this.navService = this.coreServiceRegistry.navigationService;
    this.alertservice = this.sharedServiceRegistry.alertService;
    this.savedSearchService = this.sharedServiceRegistry.savedSearchService;
    this.userPreferenceService
      = this.sharedServiceRegistry.userPreferencesService;
    this.registerForUserPreferenceEvents();
  }


  public registerForUserPreferenceEvents() {
    this.userPreferenceService.receiveEvent()
      .subscribe(($event: any) => {
      if ($event.source === 'leftmenu') {
        /* if this event originated from the left menu, ignore it.
          It will be handled separately */
        return;
      }
      if ($event.type === DELETE_FAVOURITE
          || $event.type === ADD_FAVOURITE) {
        this.userPreferenceService.getAllFavorites()
          .subscribe((response: any) => {
            this.favoritesList = response.favorites;
          });
      }
      if (($event.type === ADD_SEARCH || $event.type === DELETE_SEARCH || $event.type === UPDATE)) {
        this.userPreferenceService.getAllSavedSearchCriterias()
          .subscribe((response: any) => {
            const slicedList = response.searchResults;
            this.savedSearchesList = slicedList;
          });
      }
      if (($event.type === ADD_RECENT)) {
        /* Given delay to accomadate the time needed by the service to fetch
          data for detail screen */
        const waitTime: number = ($event.isExternal) ? 5000 : 300;
        setTimeout(() => {
          this.userPreferenceService.getRecentSearchItemsList()
            .subscribe((response: any) => {
              this.recentItemsList = response.recentItems;
            });
        }, waitTime);
      }
    });
  }

  // Toggle recent items in sidepanel
  public toggleRecentItems() {
    if (this.expandRecentItems) {
      this.expandRecentItems = false;
    } else {
      this.userPreferenceService.getRecentSearchItemsList()
        .subscribe((response: any) => {
          this.recentItemsList = response.recentItems;
          this.expandRecentItems = true;
        });
    }
  }

  // Toggle saved searches in sidepanel
  public toggleSavedSearches() {
    if (this.expandSavedSearches) {
      this.expandSavedSearches = false;
    } else {
      this.userPreferenceService.getAllSavedSearchCriterias()
        .subscribe((response: any) => {
          this.savedSearchesList = response.searchResults;
          this.expandSavedSearches = true;
        });
    }
  }

 // Toggle favorites in sidepanel
 public toggleFavorites() {
    if (this.expandFavorites) {
      this.expandFavorites = false;
    } else {
      this.userPreferenceService.getAllFavorites()
        .subscribe((response: any) => {
          this.favoritesList = response.favorites;
          this.expandFavorites = true;
        });
    }
  }

  // Method to open the popup window on button click from side panel
  public showAllSavedCriterias() {
    // this.sharedServiceRegistry.sceModalService
    //   .showModal(SearchCriteriaPopupComponent,
    //     { overlayHeader: 'All Search Criteria',
    //       showHeader: true,
    //       overlayHeight: 525,
    //       overlayWidth: 1024
    //     });
  }

  // method to delete search
  public deleteSearchCondition(searchId: number) {
    const searchIdsList: Array<any> = [];
    searchIdsList.push(searchId);
    this.savedSearchService.deleteSearchCriteria(searchIdsList)
      .subscribe((resp: any) => {
        if (resp['statusCode'] && resp['statusMessage']) {
          if (resp['statusCode'] === 200
              && resp['statusMessage'] === 'SUCCESS') {
            this.alertservice.clearAll()
              .success('Search Criteria deleted Successfully');
          }
          this.notifyEvent(DELETE_SEARCH, searchId);
          this.userPreferenceService.getAllSavedSearchCriterias()
            .subscribe((response: any) => {
              this.savedSearchesList = response.searchResults;
            });
      } else {
        if (resp.error) {
          this.alertservice.clearAll();
          this.alertservice.error(this.resourceService
            .translateServerResponse(resp.error));
        }
      }
    });
  }

  public notifyEvent(type: number, item: number) {
    const $event: UserPreferenceEvent = new UserPreferenceEvent();
    $event.type = type;
    $event.item = '' + item;
    $event.source = 'leftmenu';
    $event.preferenceId = item;
    this.userPreferenceService.notifyEvent($event);
  }

  // This method is used to navigate to corresponding detail screen
  public navigateToDetailsPage(itemType: string, documentId: number, displayValue: string) {
    if (this.lastClickedDocId !== documentId) {
      itemType = itemType.toLowerCase();
      const url: string = menuIdRouteMappings[itemType] + '/details';
      this.navService.resetBreadcrumb('home');
      if (itemType === 'commodity') {
        // LFWM-2045 for commodity recent item loadbypage call,passing storerKey
        let values: Array<any> = [];
        values   = displayValue.split('#');
        const storerKey = values[values.length - 1];
        this.navService.navigateWithRouteParams(url, [documentId.toString(), storerKey]);
      } else {
        this.navService.navigateWithRouteParams(url, [documentId.toString()]);
      }
    }
  }


  /* This method is used to navigate to corresponding search screen
    based on item type */
  public navigateToSearchPage(searchId: number, itemType: string) {
    const url = menuIdRouteMappings[itemType] + '/search';
    this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
      this.navService.navigatetoSearch(itemType, [searchId.toString()]);
    });
    this.navService.resetBreadcrumb('home')
      .addBreadCrumbItem(this.resourceService
        .get('mn_' + itemType), itemType.toLowerCase());
  }

  public navigateToFavorites(menuId: string) {
    this.navService.navigateByMenuId(menuId);
  }

  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  // Delete favorites from side panel
  public deletefavorites(preferenceId: number) {
    this.userPreferenceService.deleteFavorites(preferenceId)
      .subscribe((response: any) => {
        if (response['statusCode'] && response['statusMessage']) {
          this.notifyEvent(DELETE_FAVOURITE, preferenceId);
          this.alertservice.clearAll();
          this.userPreferenceService.getAllFavorites()
            .subscribe((data: any) => {
              this.favoritesList = data.favorites;
            });
        } else {
          if (response.error) {
            this.alertservice.clearAll()
              .error(this.resourceService
                .translateServerResponse(response.error));
          }
        }
      });
  }

}
