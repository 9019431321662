
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DetailedEntityBackendService } from '../../shared/services/searchable-entity/detailed-entity/detailed-entity-backend.service';
import { CoreServiceRegistry } from '../../core/core-service-registry';
import { SharedServiceRegistry } from '../../shared/shared-service-registry';
import { FleetMonitoringDataService } from './fleet-monitoring-data.service';
import { catchError, map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
//import { InventoryTransactionData } from '../../../../../shared/models/inventorytransaction-data.model';

@Injectable()
export class FleetMonitoringBackendService extends DetailedEntityBackendService {

  public dataService: FleetMonitoringDataService;

  constructor(public coreServiceRegistry: CoreServiceRegistry,
    public sharedServiceRegistry: SharedServiceRegistry) {
    super(coreServiceRegistry, sharedServiceRegistry);
  }
  public autoPopulate(packKeyVal: any) {
    const response$ = this.http$.get('api/core/uom/pack/' + packKeyVal).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.errors.length !== 0) {
          if (error.error.errors[0].validationCode !== 200) {
            return observableThrowError(error.error.errors['0'].validationMessage);
          }
        }
      }));
    return response$;
  }
  public autoPopulateUOMFeilds(reqObj: any) {
    const response$ = this.http$.put('api/asn/UOM/compute/', JSON.stringify(reqObj)).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.errors.length !== 0) {
          if (error.error.errors[0].validationCode !== 200) {
            console.debug(error.error.errors['0'].validationMessage);
            return observableThrowError(error.error.errors['0'].validationMessage);
          }
        }
      }));
    return response$;
  }
  public validateLocation(req: any) {
    const response$ = this.http$.post('api/core/location', JSON.stringify(req)).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }
  public finalizeTransfer(transferKey, proceedWithWarning): Observable<Response> {
    const request = {
      'transferKey': transferKey,
      'proceedWithWarning': proceedWithWarning
    };
    const response$ = this.http$.post(`api/inventory/vas/transfer/finalize/`, JSON.stringify(request)).pipe(
      map((response: any) => {
        if (response['statusCode'] === 200 && response['statusMessage'] === 'SUCCESS') {
          return response;
        }
      }),
      catchError((error: any) => {
        if (error.error.errors.length !== 0) {
          if (error.error.errors[0].validationCode !== 200) {
            console.debug('Msg:', error.error.errors['0'].validationMessage);
            return observableThrowError(error.error.errors['0'].validationMessage);
          }
        }
      }));
    return response$;
  }
  // service call for transfer Allocate functionality
  public allocate(transferKey: any, proceedWithWarning: any): Observable<Response> {
    const request = {
      'transferKey': transferKey,
      'proceedWithWarning': proceedWithWarning
    };
    const response$ = this.http$.post('api/inventory/vas/transfer/allocate', JSON.stringify(request)).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            console.debug(error.error.statusMessage);
            return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public cancelTransfer(transferId: any) {
    const response$ = this.http$.get('api/inventory/vas/transfer/' + transferId + '/cancel').pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            console.debug('Error', error.error.statusMessage);
            return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  // method to call the populate transfer functionality
  public populateTransfer(adjNumber, lotlocidlist, type, toStorer): Observable<any> {
    let request: any;
    let url: any;
    if (type === 'Adjustment') {
      request = {
        'transferkey': adjNumber,
        'lotlocidlist': lotlocidlist,
        'toStorer': toStorer //LFWM-1946  modified to pass toStorer
      };
      url = 'api/inventory/vas/transfer/populate';
    }
    const response$ = this.http$.post(url, JSON.stringify(request)).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            console.debug('Error:', error.error.statusMessage);
            return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }


  public populateTransferByUcc() {
  }

  public populateCandidateStockOnHand(transferKey: any, proceedWithWarning: any) {
    let request: any;
    let url: any;
    request = {
      'transferkey': transferKey,
      'proceedWithWarning': proceedWithWarning
    };
    url = 'api/inventory/vas/transfer/SOH/populate';
    const response$ = this.http$.post(url, JSON.stringify(request)).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            console.debug('Error :', error.error.statusMessage);
            return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public populateRestrictedValues(request: any) {
    const tableId = this.dataService.getPrimarySearchParams().tableId;
    const response$ = this.http$.post('api/core/uom/' + tableId, JSON.stringify(request)).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            console.debug('ErrorMsg', error.error.statusMessage);
            return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public fetchPack(sku: any, storerKey: any): Observable<any> {
    const request = {
      'sku': sku,
      'storerKey': storerKey
    };
    const response$ = this.http$.post('api/inventory/vas/transfer/pack', JSON.stringify(request)).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            console.debug('ErrorMsg:', error.error.statusMessage);
            return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public fetchLottables(lot: any): Observable<any> {
    const request = {
      'lot': lot
    };
    const response$ = this.http$.post('api/inventory/vas/transfer/transferLot', JSON.stringify(request)).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            console.debug('ErrorMsg::', error.error.statusMessage);
            return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public saveEntity(requestItems: any) {
    const hdr = new HttpHeaders({ 'api': 'notification' });
    const response$ = this.http$.put(`api/recipient/groups`, JSON.stringify(requestItems) ,{headers:hdr}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return observableThrowError(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public delete(request: any) {
    const hdr = new HttpHeaders({ 'api': 'notification' });
    const options = {
      headers: new HttpHeaders(
        { 'api': 'notification'
      }),
      body: request,
    };

    const response$ = this.http$.delete(request.url , options ).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((errors: any) => {
        if (errors.error.length !== 0) {
          if (errors.error.statusCode !== 200) {
            return observableThrowError(errors.error.statusMessage);
          }
        }
      }));
    return response$;
  }

  public getRgCountryList(){
    const hdr = new HttpHeaders({ 'api': 'notification' });
    const response$ = this.http$.get('api/notification/usercountrydetails', {headers:hdr}).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.errors.length !== 0) {
          if (error.error.errors[0].validationCode !== 200) {
            return observableThrowError(error.error.errors['0'].validationMessage);
          }
        }
      }));
    return response$;
  }

}


