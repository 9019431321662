<div #paginatorContainer class="paginator-container">
    <span class="page-size-panel">
        <span class="paginator-txt">Page Size</span>
        <select class="page-size-select" [(ngModel)]="pageSize" (ngModelChange)="handlePageChange(pageSize, 1);">
            <option *ngFor="let option of pageSizeOptions">{{ option }}</option>
        </select>
    </span>
    <span class="paginator-panel">
        <span class="paginator-element">
            <i class="fa fa-step-backward" aria-hidden="true"
                [ngClass]="{ 'fa-disabled': disablePrev }"
                (click)="handlePageChange(pageSize, 1);"></i>
        </span>
        <span class="paginator-element">
            <i class="fa fa-backward" aria-hidden="true"
                [ngClass]="{ 'fa-disabled': disablePrev }"
                (click)="handlePageChange(pageSize, currentPage - 1);"></i>
        </span>
        <span *ngIf="showLeftEllipsis" class="paginator-element"
            (click)="handlePageChange(pageSize, currentPage - 3);">
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
        </span>
        <ng-template ngFor let-page [ngForOf]="displayedPages">
            <span class="paginator-element" (click)="handlePageChange(pageSize, page);"
                [ngClass]="{ 'highlight' : (currentPage === page) }">{{ page }}</span>
        </ng-template>
        <span *ngIf="showRightEllipsis" class="paginator-element"
            (click)="handlePageChange(pageSize, currentPage + 3);">
            <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
        </span>
        <span class="paginator-element">
            <i class="fa fa-forward" aria-hidden="true"
                [ngClass]="{ 'fa-disabled': disableNext }"
                (click)="handlePageChange(pageSize, currentPage + 1);"></i>
        </span>
        <span class="paginator-element">
            <i class="fa fa-step-forward" aria-hidden="true"
                [ngClass]="{ 'fa-disabled': disableNext }"
                (click)="handlePageChange(pageSize, pageCount);"></i>
        </span>
    </span>
    <span class="page-goto-panel" >
        <input class="page-goto" [(ngModel)]="manualPageNumber"
            [ngClass]="{ 'highlight-text' : isInvalidPageNumber }"
            (keyup.enter)="handlePageChangeOnUserInput($event)"
            (keyup)="validatePageNumber($event)" (focusout)="onFocusOut()"/>
        <span class="paginator-txt">/ {{ pageCount }}</span>
    </span>
</div>
