// import { GridApi } from 'ag-grid-community/dist/lib/gridApi';
import { PageDefinition } from './page-definition.model';
import { GridApi } from '@ag-grid-community/all-modules';
// import { GridApi } from 'ag-grid-community';


export class PagedData<T> {
  public pageDefinition = new PageDefinition();
  public focusedRow: T;
  public focusedCellId: string;
  public pinnedPageRows = new Array<T>();
  public pageRows = new Array<T>();
  public aggridApi: GridApi;
  public localPaginateAllData: any[];

  // Returns currently focused row and columnName
  public getFocusedRow(): { row: T, cName: string } | undefined {
    if (this.aggridApi && this.aggridApi.getFocusedCell()) {
      let rowIndex: any;
      if (this.aggridApi.getFocusedCell().rowIndex !== null && this.aggridApi.getFocusedCell().rowIndex !== undefined) {
        rowIndex = this.aggridApi.getFocusedCell().rowIndex;
      }
      return {
        row: this.pageRows[rowIndex],
        cName: this.aggridApi.getFocusedCell().column.getColId()
      };
    } else {
      if (this.focusedRow && this.focusedCellId) {
        return {
          row: this.focusedRow,
          cName: this.focusedCellId
        };
      }
    }
  }

  // Returns currently selected rows by checkbox value
  public getSelectedRows(): Array<any> {
    return this.pageRows.filter((row: any) => {
      return row.isSelected;
    });
  }
  public getNonSelectedRows(): Array<any> {
    return this.pageRows.filter((row: any) => {
      return !row.isSelected;
    });
  }

  public getAllRows(): Array<any> {
    return this.pageRows.filter((row: any) => {
      return row;
    });
  }
  // Returns edited data<only edited cells per row>
  public getEditedCellsPerRow(mandatoryProps?): any[] {
    if (!mandatoryProps) {
      mandatoryProps = [];
    }
    const editedRows = [];
    this.pageRows.forEach((row: any) => {
      if (row.isEdited) {
        const editedCells = [];
        Object.values(row.cells).forEach((cell: any) => {
          if (cell.isEdited || mandatoryProps.indexOf(cell.cName) !== -1) {
            const editedCell: any = {};
            editedCell.cName = cell.cName;
            editedCell.cVal = cell.cVal;
            editedCells.push(editedCell);
          }
        });
        const editedRow: any = {};
        editedRow.cells = editedCells;
        editedRows.push(editedRow);
        return true;
      }
    });
    return editedRows;
  }

  public getEditedRows(): any[] {
    return this.pageRows.filter((row: any) => {
      return row.isEdited;
    });
  }

  // Clear markup flag<>
      /* LFWM-960 Removing the previous skip first clearing code */
  public clearMarkerFlags(): void {
    this.pageRows.forEach((row: any) => {
      const rowCells = row.cells;
      if (rowCells['isNewItem'] && rowCells['isNewItem'].cVal) {
        rowCells['isNewItem'].cVal = false;
      }
      if (row.isEdited) {
        row.isEdited = false;
        Object.values(rowCells).forEach((cell: any) => {
          cell.isEdited = false;
        });
      }
      if (row.isNewRow) {
        row.isNewRow = false;
      }
    });
  }
}
