import { Injectable } from '@angular/core';
import { ButtonItem } from './button.model';
import { AppStorageService, KEYS_APPSTORAGE } from 'sce-core';
import { UserService } from 'sce-core';

@Injectable()
export class ButtonFactoryService {

  public allPrivilegesForUser = [];

  constructor(public appStorageService: AppStorageService, public userService: UserService) {
    if (this.appStorageService.getItem(KEYS_APPSTORAGE.USER_INFO) &&
      this.appStorageService.getItem(KEYS_APPSTORAGE.USER_INFO)['userPermissionsList']) {
      this.allPrivilegesForUser = this.appStorageService.getItem(KEYS_APPSTORAGE.USER_INFO)['userPermissionsList'];
    }
  }

  public loadButtonsByPrivilege(fullButtonList: Array<ButtonItem>) {
    if (!fullButtonList) {
      return [];
    } else {
      fullButtonList.forEach((button: ButtonItem) => {
        this.enableButtonByPrivilege(button);
      });
      // return fullButtonList;
     return fullButtonList.filter(button => button.isEnabled);
    }
  }

  /**
   * This method enables the given set of buttons based on the given set of actions
   * @param buttons - The array of buttons
   * @param enabledActions - The actions to be enabled
   */
  public rebuildButtonArray(buttons: Array<ButtonItem>, enabledActions: Array<string>) {
    if (buttons === null || buttons.length === 0) {
      return;
    }
    // enable buttons recursively
    buttons.forEach((button: ButtonItem) => {
      this.enableButtonRecursively(button, enabledActions);
    });
  }

  /**
   * This method checks if the given button and its sub buttons should be enabled based on the given set of actions
   * @param button - The button to be handled
   * @param enabledActions - The actions to be enabled
   */
  public enableButtonRecursively(button: ButtonItem, enabledActions: Array<string>): boolean {
    // initialize the state to disabled
    let isButtonEnabled = false;
    if (enabledActions.indexOf(button.action) !== -1) {
      isButtonEnabled = true;
      // if the button is enabled, enable all the sub items as well
      this.enableAllButtons(button.subItems);
    } else if (button.subItems && button.subItems.length > 0) {
      // if a button is not enabled, but has sub buttons, check if any of them is enabled
      button.subItems.forEach(subButton => {
        const isSubButtonEnabled = this.enableButtonRecursively(subButton, enabledActions);
        // If any sub button is enabled, enable the parent as well
        if (!isButtonEnabled && isSubButtonEnabled) {
          isButtonEnabled = true;
        }
      });
    }
    // set button state
    button.isEnabled = isButtonEnabled;
    button.isVisible = isButtonEnabled;
    // return the state of the button
    return isButtonEnabled;
  }

  /**
   * This method enables the given set of buttons recursively
   * @param buttons - Given set of buttons
   */
  public enableAllButtons(buttons: Array<ButtonItem>) {
    if (buttons === null || buttons.length === 0) {
      return;
    }
    buttons.forEach((button: any) => {
      button.isVisible = true;
      button.isEnabled = true;
      if (button.subItems) {
        this.enableAllButtons(button.subItems);
      }
    });
  }

  // Method is used to check whether the user having corresponding privilege code to enable the button
  public checkButtonPrivilege(privilegeCode: String) {
    // Method body moved to sce-core/UserService for
    return this.userService.checkButtonPrivilege(privilegeCode);
  }

  // The method will update button isenabled property based on user privileges
  public enableButtonByPrivilege(button: ButtonItem) {
    if (button.accessPrivilege === '') {
      button.isEnabled = true;
    } else {
      button.isEnabled = this.allPrivilegesForUser.indexOf(button.accessPrivilege) !== -1;
    }
    if (button.subItems) {
      button.subItems.forEach((subButton: any) => {
        this.enableButtonByPrivilege(subButton);
      });
    }
  }
}
