<!-- <lfwms-tms-details-page [screenLabel]="screenLabel" [buttonArray]="buttons" (buttonClickHandlerAction)="back()">

</lfwms-tms-details-page> -->
<!-- <lfwms-heading-primary [mnTitle]="screenLabel"></lfwms-heading-primary> -->

<div style="padding: 30px">
  <div style="display: flex">
    <div style="font-size: 18px; color: #42b0d5; padding: 0 0 20px 20px">
      {{ getChangelabel("tms_order_details") }}
    </div>
    <div class="select-box">
      <span class="img-box">
        <img src="assets/images/svg/icon-language.svg" class="lf-svg" />
      </span>
      <select
        id="inputEmail4"
        name="cars"
        class="form-controls"
        [value]="country"
        (change)="changeCountry($event)"
        placeholder="please select a language"
      >
        <!-- <option [value]="null">please select a language</option> -->
        <option *ngFor="let data of languagelist" [value]="data.langCode">
          {{ data.langeName }}
        </option>
      </select>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div
      class="panel panel-default userListPanel"
      style="height: calc(100vh - 17px); background-color: #f0f0f0"
    >
      <div class=" y-scoll panel-body col-lg-6 col-md-12 viewport-left ">
        <!-- <div class="panel-body  col-lg-6 col-md-12  viewport-left" [ngClass]="adaptive?'adaptive-size':'none'"> -->
        <div class="title">{{ getChangelabel("tms_view_order") }}</div>
        <div class="info-card col-md-12">
          <div class="row">
            <div class="col-md-6 overflow-space">
              <span
                class="overflow-space"
                [title]="getChangelabel('tms_order_code')"
                >{{ getChangelabel("tms_order_code") }}
              </span>
              : &nbsp;
              <span
                class="overflow-space"
                [title]="orderDetail?.orderReleaseXid">
                {{ orderDetail?.orderReleaseXid }}
              </span>
            </div>
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_customer_ref')"
              >{{ getChangelabel("tms_customer_ref") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.customerRefNo">
              {{ orderDetail?.customerRefNo }}
            </span>
              <!-- {{ getChangelabel("tms_customer_ref") }} :&nbsp;{{
                orderDetail?.extOrderRef
              }} -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_shipment_id')"
              >{{ getChangelabel("tms_shipment_id") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.shipmentXid">
              {{ orderDetail?.shipmentXid }}
            </span>
              <!-- {{ getChangelabel("tms_shipment_id") }}:&nbsp;{{
                orderDetail?.shipmentXid
              }} -->
            </div>
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_po_no')"
              >{{ getChangelabel("tms_po_no") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.poNo">
              {{ orderDetail?.poNo }}
            </span>
              <!-- {{ getChangelabel("tms_po_no") }} :&nbsp;{{
                orderDetail?.clientExtRefNo
              }} -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_departure')"
              >{{ getChangelabel("tms_departure") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.departure">
              {{ orderDetail?.departure }}
            </span>
              <!-- {{ getChangelabel("tms_departure") }}:&nbsp;{{
                orderDetail?.sourceLocName
              }} -->
            </div>
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_source_order_id')"
              >{{ getChangelabel("tms_source_order_id") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.sourceOrderId">
              {{ orderDetail?.sourceOrderId }}
            </span>
              <!-- {{ getChangelabel("tms_source_order_id") }} :&nbsp;{{
                orderDetail?.wmsOrderRef
              }} -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_destination')"
              >{{ getChangelabel("tms_destination") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.destination">
              {{ orderDetail?.destination }}
            </span>
              <!-- {{ getChangelabel("tms_destination") }} :&nbsp;{{
                orderDetail?.destLocName
              }} -->
            </div>
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_order_status')"
              >{{ getChangelabel("tms_order_status") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.orderStatus">
              {{ orderDetail?.orderStatus }}
            </span>
              <!-- {{ getChangelabel("tms_order_status") }} :&nbsp;
              {{ orderDetail?.orderStatus }} -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_customer_name')"
              >{{ getChangelabel("tms_customer_name") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.customerName">
              {{ orderDetail?.customerName }}
            </span>
              <!-- {{ getChangelabel("tms_customer_name") }} :&nbsp;{{
                orderDetail?.principalName
              }} -->
            </div>
            <div class="col-md-6 overflow-space">
              {{ getChangelabel("tms_pod") }} :&nbsp;
              <span style="padding-left:10px;cursor: pointer;"> 
                <a style="margin-right: 5px;"  (click)="toggleOverlay($event,'view',orderDetail)" *ngIf="(orderDetail?.PODMediaUrl || orderDetail?.POPMediaUrl) || orderDetail?.mediaUrl">
                  <img alt="view" src="assets/images/view-black.svg" title="view" width="20px">
                </a> 
                <p-overlayPanel  #opView [showCloseIcon]="false" class="custom-overlaypanel" [style]="{ width: '90px', backgroundColor: '#42b0d5'}">
                  <div style="color: #fff;cursor: pointer;" (click)="viewEPOD(orderDetail?.PODMediaUrl)">
                    <img alt="pod"  [src]="getPODImage(orderDetail?.PODMediaUrl)" title="replace" width="20px">
                    POD
                  </div>
                  <div style="color: #fff;cursor: pointer;" (click)="viewEPOD(orderDetail?.POPMediaUrl)">
                    <img alt="pop" [src]="getPODImage(orderDetail?.POPMediaUrl)" title="replace" width="20px">
                    POP
                  </div>
                </p-overlayPanel>
            </span>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-6 overflow-space">
              {{ getChangelabel("tms_driver_name") }}
              :&nbsp;{{ orderDetail?.driverName }}
            </div>
            <div class="col-md-6 overflow-space">
              {{ getChangelabel("tms_driver_contact") }}
              :&nbsp;{{ orderDetail?.deviceId }}
            </div>
          </div> -->
          <div class="row">
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_requested_delivery_date')"
              >{{ getChangelabel("tms_requested_delivery_date") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.requestedDeliveryDate">
              {{ UTCTime(orderDetail?.requestedDeliveryDate) }}
            </span>
              <!-- {{ getChangelabel("tms_requested_delivery_date") }}
              :&nbsp;{{ orderDetail?.requestedDeliveryDate }} -->
            </div>
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_pickup_date')"
              >{{ getChangelabel("tms_pickup_date") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.estPickupDate">
              {{ UTCTime(orderDetail?.estPickupDate) }}
            </span>
              <!-- {{ getChangelabel("tms_pickup_date") }}
              :&nbsp;{{ orderDetail?.earlyPickupDate }} -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_truck_type')"
              >{{ getChangelabel("tms_truck_type") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.truckType">
              {{ orderDetail?.truckType }}
            </span>
              <!-- {{ getChangelabel("tms_truck_type") }}
              :&nbsp;{{ orderDetail?.equipmentId }} -->
            </div>
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_ship_unit_count')"
              >{{ getChangelabel("tms_ship_unit_count") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.totalShipUnitCount">
              {{ orderDetail?.totalShipUnitCount }}
            </span>
              <!-- {{ getChangelabel("tms_ship_unit_count") }}
              :&nbsp;{{ orderDetail?.cartonCount }} -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_truck_no')"
              >{{ getChangelabel("tms_truck_no") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.truckNo">
              {{ orderDetail?.truckNo }}
            </span>
              <!-- {{ getChangelabel("tms_truck_no") }}
              :&nbsp;{{ orderDetail?.truckId }} -->
            </div>
            <div class="col-md-6 overflow-space">
              <span
              class="overflow-space"
              [title]="getChangelabel('tms_tracking_note')"
              >{{ getChangelabel("tms_tracking_note") }}
            </span>
            : &nbsp;
            <span
              class="overflow-space"
              [title]="orderDetail?.trackingNote">
              {{ orderDetail?.trackingNote }}
            </span>
              <!-- {{ getChangelabel("tms_tracking_note") }}
              :&nbsp;{{ orderDetail?.eventRemarks }} -->
            </div>
          </div>
        </div>

        <div class="clearfix"></div>
        <div
          *ngIf="isShow"
          class="userlist"
          style="margin-top: 20px; height: 336px"
        >
          <div class="title">{{ getChangelabel("tms_event_history") }} :</div>
          <p-table [value]="controlTowerDetail">
            <ng-template pTemplate="header">
              <tr>
                <!-- <th style="color:#fff;">{{getLabel('lbl_tms_event_date')}}</th>
                    <th style="color:#fff;">{{getLabel('lbl_tms_event')}}</th>
                    <th style="color:#fff;">{{getLabel('lbl_tms_event_remark')}}</th> -->
                <th style="color: #fff">
                  {{ getChangelabel("tms_event_date") }}
                </th>
                <th style="color: #fff">{{ getChangelabel("tms_event") }}</th>
                <th style="color: #fff">
                  {{ getChangelabel("tms_event_remark") }}
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-controlTowerDetail>
              <tr>
                <td class="overflow-space" [title]="controlTowerDetail?.eventDate">{{ transLocationTime(controlTowerDetail?.attribute4) }}</td>
                <td class="overflow-space" [title]="controlTowerDetail?.eventDesc">{{ controlTowerDetail?.statusDesc }}</td>
                <td class="overflow-space" [title]="controlTowerDetail?.eventRemarks">{{ controlTowerDetail?.attribute3 }}</td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td
                  colspan="3"
                  style="
                    background-color: #eaf1fe;
                    height: 220px;
                    text-align: center;
                  "
                >
                  {{ getChangelabel("tms_no_records") }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <!-- <p-dataTable
            [value]="pageInfo.content"
            rowHover="true"
            reorderableColumns="true"
            [editable]="true"
            resizableColumns="false"
            [(selection)]="selectedRow"
            scrollable="true"
          >
            <p-column
              *ngFor="let column of columnDef; let i = index"
              [field]="column.propertyName"
              [editable]="column.editable"
              [header]="column.elementId"
            >
              <ng-template pTemplate="header">
                <span class="cell-header">{{ column.elementId }}</span>
              </ng-template>
              <ng-template let-row="rowData" pTemplate="body">
                <lfwms-datatable-cell-renderer
                  [cellValue]="row[column.propertyName]"
                  [row]="row"
                  [colDef]="column"
                  (clickCellAction)="clickCellAction($event)"
                  [colType]="column.type"
                >
                </lfwms-datatable-cell-renderer>
              </ng-template>
            </p-column>
          </p-dataTable> -->
        </div>
        <!-- <div *ngIf="!isShow">
          <div
            class="title"
            style="
              display: flex;
              justify-content: space-between;
              padding-top: 10px;
            "
          >
            <span style="display: inline-block">Event History:</span>
            <span style="display: inline-block">
              <button (click)="goEvent(2)" class="map-btn">
                View Map<span style="content: '\27A2'">&#10146;</span>
              </button>
            </span>
          </div>
          <div style="width: 100%">
            <table>
              <thead class="event-head">
                <th class="borde-ringht">Event</th>
                <th>EventDate</th>
              </thead>
              <tbody
                *ngFor="let column of pageInfo.content; let i = index"
                class="event-body"
              >
                <tr>
                  <td>{{ column.eventDesc }}</td>
                  <td>{{ column.eventDate }}</td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="pageInfo.content == 0" class="event-centent">
              No records found
            </div>
          </div>
          <div class="title" style="padding-top: 10px"></div>
        </div> -->
      </div>
      <div
        *ngIf="isShow"
        class="panel-body col-lg-6 col-md-12 viewport-left"
        style="padding: 20px; padding-left: 15px"
      >
        <div>
          <div
            class="map"
            id="map"
            style="width: 100%; height: calc(100vh - 60px )"
          ></div>
        </div>

        <!-- <div class="progress-show">
          <div class="progress-title">Transportation progress:</div>
          <div class="progress-body">
            <div class="progress-center">
              <div class="number-show">
                Total Progress:{{orderDetail?.progress * 100 + '%'}}
              </div>
              <div class="progress-line">
                <div class="start-place" style="position: relative">
                  <div class="detail-info">
                    {{orderDetail?.sourceLocName}}
                    <br>
                    {{orderDetail?.updateDate}}
                  </div>
                </div>
                <div class="place-line">
                  <div class="line-part"
                    [ngStyle]="{'width.%':orderDetail?.progress * 100 , 'background-color': '#70b603' }"></div>
                </div>
                <div class="end-place" style="position: relative"
                  [ngStyle]="{'background-color': orderDetail?.progress == 1 ? '#70b603' : ''}">
                  <div class="detail-info">
                    {{orderDetail?.destLocName}}
                    <br>
                    {{orderDetail?.planDeliveryDate}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
