<p-dialog #tableCustomizer class="tbl-customizer" [(visible)]="showCustomizer" 
	[modal]="true" [closable]="false" [draggable]="false" [appendTo]="'body'" [showHeader]="false"
 	[resizable]="false" [closeOnEscape]="true" [dismissableMask]="true" 
	 [style]="{'width':'680px'}">

	<div class="tbl-customizer-hdr">
		{{ getLabel('lbl_cus_tbl_pre') }}
	</div>

	<table class="tbl-preferences">

		<tr>
			<td class="col-available">
				<span class="lbl-available">{{ getLabel('lbl_aval_flds') }}</span>
			</td>
			<td class="col-btns-move"></td>
			<td class="col-displayed">
				<span class="lbl-displayed">{{ getLabel('lbl_dis_flds') }}</span>
			</td>
			<td class="col-btns-order"></td>
		</tr>

		<tr>
			<td class="col-available">
				<div class="lst-available">
					<div class="lst-avl-inner">
						<span class="cell-avl-default" [ngClass]="{ 'cell-avl-selected': column.isSelected }"
							(click)="column.isSelected = !column.isSelected;"
						 	*ngFor="let column of hiddenColumns;">{{ column.headerName }}</span>
					</div>
				</div>
			</td>
			<td class="col-btns-move">
				<span class="spn-btn-right">
					<img class="lf-svg" src="assets/images/svg/icon-right.svg" (click)="moveToDisplayedColumnsList();" />
				</span>
				<img class="lf-svg" src="assets/images/svg/icon-left.svg" (click)="moveToHiddenColumnsList();" />
			</td>
			<td class="col-displayed">
				<div class="lst-displayed">
					<div #dContainer class="lst-dsp-inner">
						<ng-template ngFor let-column [ngForOf]="displayedColumns">
							<span class="cell-dsp-default" [ngClass]="{ 'cell-dsp-selected': column.isSelected }"
								(click)="handleColumnClick(column, $event);">
								<img class="lf-svg" (click)="handleColumnPinning(column, $event);"
									src="{{ 'assets/images/svg/' + (column.pinned ? 'icon-pin.svg' : 'icon-unpin.svg') }}" />
								{{ column.headerName }}
							</span>
						</ng-template>
					</div>
				</div>
			</td>
			<td class="col-btns-order">
				<span class="spn-btn-top">
					<img class="lf-svg" src="assets/images/svg/icon-top.svg" (click)="reorderColumn(true);" />
				</span>
				<img class="lf-svg" src="assets/images/svg/icon-bottom.svg" (click)="reorderColumn(false);" />
			</td>
		</tr>

	</table>

	<div class="btm-btn-pnl">
		<div class="btm-pnl-msg">{{ userPreferenceMsg }}</div>
		<div>
			<button class="btm-pnl-btn" (click)="saveCustomization();">{{ getLabel('btn_save') }}</button>
			<button class="btm-pnl-btn" (click)="resetCustomization();">{{ getLabel('btn_reset') }}</button>
			<button class="btm-pnl-btn" (click)="cancelCustomization();">{{ getLabel('btn_cancel') }}</button>
		</div>
	</div>

</p-dialog>