<span [ngClass]="{'sugg-container': true , 'acn-sugg-container': isDetailOrAcn ==='accordion', 'lookup-sugg-container': isDetailOrAcn ==='lookup',
'detail-sugg-container': (isDetailOrAcn === 'detail'), 'column-filter-sugg-container': isDetailOrAcn ==='column-filter'  }">
    <div><input #primeFocusInput tabIndex="0" type="text" [ngClass]="[showLookupIcon ? 'form-control basic-input' : 'form-control basic-input-typeahead']"
            (keydown)="onKeyDown($event)" [set-focus]="keyEventHandler.isFocused(currentRow, propertyName)" [ngModel]="dataModel.cVal"
            (input)="getAutoSuggestion(currentRow, propertyName,
          $event.target.value, false);valueChanged($event)" [attr.disabled]= "disableField? '' : null"
            (clickOutside)="closeOnClickOutside(propertyName)" (focus)="onTextFocus($event)">
        <div class="input-group-append" *ngIf="showLookupIcon">
            <div class="btn-outline-secondary basic-input-grp-btn"
                (click)="setFocusedCell(currentRow, propertyName);
                    openLookup(currentRow, propertyName)">
                <i class="fa fa-search" aria-hidden="true"></i>
            </div>
        </div>
        <div #autoSuggestionTemplate [ngClass]="[showLookupIcon ? 'suggestion-dropdown-block' : 'suggestion-dropdown-block-typeahead']"
            *ngIf="showSuggestion" scroll-Bottom (scrollBottom)="autoSuggestionScrolledToBottom(currentRow, propertyName, dataModel.cVal)">
            <div class="suggestion-block" *ngFor="let suggestion of autoSuggestionList; let i = index" (keydown)="handleKeyboardNavigation($event , i,currentRow,
           propertyName, suggestion)"
                (click)="handleSuggestionSelected(currentRow, propertyName, suggestion)">
                <button class="suggestion-item">{{ suggestion }}</button>
            </div>
        </div>
    </div>
</span>