import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AlertService } from 'sce-core';
import * as XLSX from 'xlsx';
import { FileImportService } from '../../services/file-import.service';
import { ResourceService } from 'sce-core';

@Component({
  selector: 'lfwms-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {

  public file: File;
  public config = { animated: false };
  public import_appropriate_type = 'import_appropriate_type';
  public import_appropriate_excel = 'import_appropriate_excel';
  public import_appropriate_txt = 'import_appropriate_txt';
  @Input() public returnType: string;
  @Input() public imgPath: string;
  @Input() public importId: string;
  @Input() public buttonName: string;
  @Output() onFileImport = new EventEmitter<any>();
  @Input() public multiSelectEnabled: boolean;
  @Input() public fromSearchFilter: boolean = false; // [LFWM-2137] - true - if import from search filter clause

  public supportedFileExtensions: Array<string>;

  constructor(public alertService: AlertService,
    public fileImportService: FileImportService,
    public resourceService: ResourceService) {}

  public ngOnInit() {
    if (this.returnType === 'file') {
      this.supportedFileExtensions = ['.doc', '.docx', '.pdf', '.html'];
    } else if (this.returnType === 'imagefiles') {
      this.supportedFileExtensions = ['.jpg', '.jpeg', '.png', '.bmp', '.tif', '.tiff', '.gif'];
    } else {
      this.supportedFileExtensions = ['.xlsx', '.xls', '.csv', '.txt'];
    } // ***use only lowercase here
  }

  // function used to valiadte file type and proceed acordingly
  public validateImportedFileType(event) {
    // conditon path that returns only the entire file
    if (this.returnType === 'file' || this.returnType === 'imagefiles') {
      let fileExt = event.target.value;
      fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
      const isValidExtension = this.checkIsValidFileExtension(fileExt.toLowerCase());
      if (isValidExtension) {
        this.fileImportService.activeImportId = this.importId;
        const files = event.target.files;
        this.fileImportService.selectedFiles = files;
        this.fileImportService.selectedFile = files[0];
        this.onFileImport.emit(this.importId);
        return;
      } else {
        const upload_appropriate_type = 'upload_appropriate_type';
        this.alertService.clearAll().error(this.resourceService.get(upload_appropriate_type));
        return;
     }
    } else {
      // conditon path that returns data of imported file
      let fileExt = event.target.value;
      fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
      const isValidExtension = this.checkIsValidFileExtension(fileExt.toLowerCase());
      if (isValidExtension) {
        if (fileExt === '.xlsx' || fileExt === '.xls' || fileExt === '.csv') {
          this.loadExcelInPopup(event);
        } else if (fileExt === '.txt') {
          if (this.fromSearchFilter) {
            this.retriveDataFromUploadedSearchConditionFile(event);
          } else {
            this.loadTxtFileInPopup(event);
          }
        }
      } else {
        this.alertService.clearAll().error(this.resourceService.get(this.import_appropriate_type));
        return;
      }
    }
  }

  // function used check wether files imported is valid extension
  public checkIsValidFileExtension(fileExt) {
    if (this.supportedFileExtensions.indexOf(fileExt) < 0) {
      return false;
    } else {
      return true;
    }
  }

  // function used to convert Txt to JSON
  public loadTxtFileInPopup(event) {
    const dataFromTxtFile = [];
    // store the event of the file imported from file chooser
    const files = event.target.files;
    const file = files[0];
    try {
      const self = this;
      let reader;
      // creating an instance of file reader
      reader = new FileReader();
      // allow the reader to read file blob
      reader.readAsDataURL(file);
      // on the onloadend call back we are performing Excel to Json object function
      reader.onloadend = function (eve) {
        const contents = eve.target.result;
        // from the reader result blob we are converting to string: Base64 encoding of the file
        const lines = contents.split(',')[1];
        // decoding string: Base64 encoding of the file to text format
        const dedecodedFile = window.atob(lines);
        // since text file is multiple line spliting it line by line
        const splittext = dedecodedFile.split('\n');
        // single line consists of irregular space so spliting word by word
        splittext.forEach(element => {
          dataFromTxtFile.push(element.split(/(\s+)/).filter(function (e) { return e.trim().length > 0; }));
        });
        // check wether data exist and there are no merge cells in the imported file
        if (dataFromTxtFile && dataFromTxtFile.length > 1 && dataFromTxtFile[0].length === dataFromTxtFile[1].length) {
          // of the appropriate data exists then this function is used to populate in popup window
          try {
            self.fileImportService.activeImportId = self.importId;
            self.fileImportService.importedRecords = dataFromTxtFile;
            self.onFileImport.emit(self.importId);

            // self.populateInLookUp(dataFromTxtFile);

          } catch (e) {
            this.alertService.clearAll().error(self.resourceService.get(self.import_appropriate_type));
          }
        } else {
          self.alertService.clearAll().error(self.resourceService.get(self.import_appropriate_txt));
        }

      };
    } catch (e) {
      this.alertService.clearAll().error(this.resourceService.get(this.import_appropriate_type));
    }
  }

  // function used to convert excel to JSON
  public loadExcelInPopup(event) {
    let dataFromExcelFile;
    // store the event of the file imported from file chooser
    const files = event.target.files;
    const file = files[0];
    let base64;
    try {
      const self = this;
      let reader;
      // creating an instance of file reader
      reader = new FileReader();
      // allow the reader to read file blob
      reader.readAsDataURL(file);
      // on the onloadend call back we are performing Excel to Json object function
      reader.onloadend = function () {
        // from the reader result blob we are converting to string: Base64 encoding of the file
        base64 = reader.result.split(',')[1];
        // using XLSX read function as the generated base64 as input we are reading file and creating workbook
        const workbook = XLSX.read(base64, { type: 'base64' });
        // extracting the first sheets separately
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        // using XLSX sheettoJson function we are creating the Json object of Excel data
        dataFromExcelFile = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true });
        // check wether data exist and there are no merge cells in the imported file
        if (dataFromExcelFile && dataFromExcelFile.length > 1 && dataFromExcelFile[0].length === dataFromExcelFile[1].length) {
          // of the appropriate data exists then this function is used to populate in popup window
          try {
            self.fileImportService.activeImportId = self.importId;
            self.fileImportService.importedRecords = dataFromExcelFile;
            self.onFileImport.emit(self.importId);
            // self.populateInLookUp(dataFromExcelFile);

          } catch (e) {
            this.alertService.clearAll().error(self.resourceService.get(self.import_appropriate_type));
          }
        } else {
          self.alertService.clearAll().error(self.resourceService.get(self.import_appropriate_txt));
        }

      };
    } catch (e) {
      this.alertService.clearAll().error(this.resourceService.get(this.import_appropriate_type));
    }

  }

  /**
   * [LFWM-2137] - function to retrive data from uploaded file which has search conditions to load the same into search filter condition clause
   */
  public retriveDataFromUploadedSearchConditionFile(event: any) {
    const dataFromTxtFile = [];
    // store the event of the file imported from file chooser
    const files = event.target.files;
    const file = files[0];
    try {
      const self = this;
      let reader;
      // creating an instance of file reader
      reader = new FileReader();
      // allow the reader to read file blob
      reader.readAsDataURL(file);
      // on the onloadend call back we are performing Excel to Json object function
      reader.onloadend = function (eve) {
        const contents = eve.target.result;
        // from the reader result blob we are converting to string: Base64 encoding of the file
        const lines = contents.split(',')[1];
        // decoding string: Base64 encoding of the file to text format
        const dedecodedFile = window.atob(lines);
        // since text file is multiple line spliting it line by line
        const splittext = dedecodedFile.split('\n');
        // single line consists of irregular space so spliting word by word
        splittext.forEach(element => {
          dataFromTxtFile.push(element.split(/(\s+)/).filter(function (e) { return e.trim().length > 0; }));
        });
        // check wether data exist and there are no merge cells in the imported file
        if (dataFromTxtFile && dataFromTxtFile.length > 1 && dataFromTxtFile[0].length === dataFromTxtFile[1].length) {
          // of the appropriate data exists then the event is emitted to search filter clause
          const searchFilterevent = {
            'column': self.importId,
            'dataFromTxtFile': dataFromTxtFile
          };
          self.onFileImport.emit(searchFilterevent);
        } else {
          self.alertService.clearAll().error(self.resourceService.get(self.import_appropriate_txt));
        }
      };
    } catch (e) {
      this.alertService.clearAll().error(this.resourceService.get(this.import_appropriate_type));
    }
  }

  // Method to clear all on  another file import
  public clearAllImport(event) {
    event.target.value = '';
  }
}
