export const FETCH_DASHBOARD_DETAILS = {
    'RG': {code: 'RG', id: 2223, notificationId: '8', moduleId: 'Recipient Group', pageTitle: 'Receipient Group', tableId: 'recipgroupsearchtd'},
    'OD': {code: 'OD', id: 2225, notificationId: '8', moduleId: 'NotificationRecipient', pageTitle: 'mn_operation_notification', tableId: 'recipgroupsearchtd'}
};

export const INITIALBREADCRUMRG = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'setup', displayValue: 'Setup', route: undefined },
    { id: 'notification', displayValue: 'Notification', route: undefined },
    { id: 'recipient-group', displayValue: 'Recipient Group', route: '/home/notification/notification-recipient' }
]

export const INITIALBREADCRUMPOD = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'global_control', displayValue: 'Global Control', route: undefined },
    { id: 'perf_mgmt', displayValue: 'Performance recipient', route: undefined },
    { id: 'operation_notification', displayValue: 'Operation NotificationRecipient', route: '/home/global-control/operation-notification' }
]

export const metaDataGrpDetail = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
        {
            'id': '1000',
            'elementId': 'Group Id',
            'propertyName': 'GROUP_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'groupId',
        },
        {
            'id': '1001',
            'elementId': 'Group Name',
            'propertyName': 'GROUP_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'groupName',
            'moduleId': 'GroupNameTd'
        },
        {
            'id': '1002',
            'elementId': 'Country',
            'propertyName': 'COUNTRY_ID',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 60,
            'index': 4,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dropDownId':'',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'country'
        },
        {
            'id': '1003',
            'elementId': 'Description',
            'propertyName': 'DESCRIPTION',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 100,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'descriptionRg'
        },
        {
            'id': '1004',
            'elementId': 'Recipient',
            'propertyName': 'RECIPIENTS',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'recipient'
        },
       
    ]
    }

export const metaDataGrp = {
'elementId': 'RECIPIENT_GROUP_TB',
'columnList': [
    {
        'id': '1000',
        'elementId': 'Group Id',
        'propertyName': 'GROUP_ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 30,
        'index': 58,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'H',
        'dataType': 'String',
        'cellDataName': 'groupId',
    },
    {
        'id': '1001',
        'elementId': 'Group Name',
        'propertyName': 'GROUP_NAME',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 58,
        'priority': 'HIGH',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'groupName',
        'moduleId': 'GroupNameTd'
    },
    {
        'id': '1002',
        'elementId': 'Country',
        'propertyName': 'COUNTRY_NAME',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 60,
        'index': 4,
        'priority': 'HIGH',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'country'
    },
    {
        'id': '1003',
        'elementId': 'Description',
        'propertyName': 'DESCRIPTION',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 100,
        'index': 6,
        'priority': 'LOW',
        'toolTip': null,
        'columnGroup': 'Primary',
        'displayType': 'E',
        'dataType': 'String',
        'cellDataName': 'descriptionRg'
    },
    {
        'id': '1004',
        'elementId': 'Recipient',
        'propertyName': 'RECIPIENTS',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': false,
        'maxLength': 20,
        'index': 45,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'recipient'
    },
   
]
}

export const metaDataGrpTable = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
        {
            'id': '1000',
            'elementId': 'Group Id',
            'propertyName': 'GROUP_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'groupId',
        },
        {
            'id': '1001',
            'elementId': 'No',
            'propertyName': 'No',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'no',
        },
        {
            'id': '1002',
            'elementId': 'Type',
            'propertyName': 'USER_TYPE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 60,
            'index': 4,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'userType'
        },
        {
            'id': '1003',
            'elementId': 'Name',
            'propertyName': 'USER_NAME',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'userName'
        },
        {
            'id': '1004',
            'elementId': 'Email',
            'propertyName': 'EMAIL',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 45,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'email'
        },
        {
            'id': '1005',
            'elementId': 'Phone Number',
            'propertyName': 'PHONE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'phoneNumber',
        },
        {
            'id': '1006',
            'elementId': 'WhatsApp',
            'propertyName': 'WHATSAPP',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 60,
            'index': 4,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'whatsApp'
        },
        {
            'id': '1007',
            'elementId': 'WeChat',
            'propertyName': 'WECHAT',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'weChat'
        },
        {
            'id': '1008',
            'elementId': 'Company',
            'propertyName': 'COMPANY',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'company'
        },
        {
            'id': '1009',
            'elementId': 'userId',
            'propertyName': 'USER_ID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'H',
            'dataType': 'String',
            'cellDataName': 'userId'
        },
        {
            'id': '1010',
            'elementId': 'Line',
            'propertyName': 'LINE',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'line'
        },
        {
            'id': '1010',
            'elementId': 'Telegram',
            'propertyName': 'TELEGRAM',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 20,
            'index': 6,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'telegram'
        },
        // {
        //     'id': '1004',
        //     'elementId': 'Email',
        //     'propertyName': 'Email',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': false,
        //     'maxLength': 20,
        //     'index': 45,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'displayType': 'E',
        //     'dataType': 'String',
        //     'cellDataName': 'Email'
        // },
       
    ]
    }
