export class PaginationEvent {

    public static PAGE_CHANGE: number = 0;
    public static PAGE_SIZE_CHANGE: number = 1;

    public pageSize: number = -1;
    public pageNumber: number;

    constructor(public type: number) { }

}

